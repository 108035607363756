import { put, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import * as chatAction from '../chat/actions'
import { updateCustomerSocketAPI } from './api'
import { consoleLog } from 'helpers/utils'

export function* updateFriendName(action: any) {
  try {
    const name = action?.payload?.data?.first_name + ' ' + action?.payload?.data?.last_name
    const uuid = action?.payload?.data?.uuid || ''
    yield put(chatAction.updateFriendName({ name: name, uuid: uuid }))
    yield put(
      updateCustomerSocketAPI({
        username: name,
        uuid: uuid,
      }),
    )
  } catch (e) {
    consoleLog(e)
  }
}

function* userSaga() {
  yield takeEvery(getType(actions.updateCustomerAction.success), updateFriendName)
}

export default userSaga
