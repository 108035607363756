import withAPIAction from 'helpers/withAPIAction'
import { uploadAction } from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'

export const uploadAPI = (data: FormData) => {
  return withAPIAction<FormData>({
    method: 'POST',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.upload,
    data,
  })(uploadAction)
}
