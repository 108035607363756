import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import HomePageLayout from 'Pages/Layout/PageLayout'
import { connect } from 'react-redux'
import { RootState } from 'Store'

type Props = {} & RouteComponentProps & ReduxProps

const Example: React.FunctionComponent<Props> = () => {
  return (
    <>
      <HomePageLayout selected="home" title="ตัวอย่าง">
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p>
        <p>Hello React</p> <p>Hello React</p> <p>Hello React</p> <p>Hello React</p>
        <p>Hello React</p> <p>Hello React</p> <p>Hello React</p> <p>Hello React</p>
      </HomePageLayout>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {}
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(Example)
