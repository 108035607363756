import React from 'react'
import { Input } from 'antd'
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'

type Props = {
  name: string
  placeholder?: string
  onChange: (val: any, name: string) => void
  value?: any
}

const SearchInput = (props: Props): any => {
  const { name, onChange, placeholder = '', value } = props

  return (
    <Search
      placeholder={placeholder}
      prefix={<SearchOutlined />}
      allowClear
      value={value}
      onChange={(evt: any): void => onChange(name, evt.target.value)}
    />
  )
}

const Search = styled(Input)`
  border-radius: 6px;
  height: 45px;
`

export default SearchInput
