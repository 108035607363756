import { generate } from '@ant-design/colors'
import { message } from 'antd'
import { appConfig } from 'config'

const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52)

export const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`

export const generateColor = (color: string) => {
  return generate(color)
}

export const genColor = (index: number) => {
  const color20 = [
    '#f37720',
    '#FBCEB1',
    '#B07A6D',
    '#988F87',
    '#8EC3B9',
    '#F5F1E6',
    '#423268',
    '#4A548F',
    '#7E7FB2',
    '#8891B2',
    '#A5866C',
    '#210805',
    '#BCAA6C',
    '#989A72',
    '#ABCCD0',
    '#629AA2',
    '#33443D',
    '#94896A',
    '#C2A689',
  ]
  return color20[index] || randomRGB()
}

async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}

export const handleCopyClick = (copyText: string) => {
  copyTextToClipboard(copyText)
    .then(() => {
      message.success('คัดลอกสำเร็จ !')
    })
    .catch(err => {
      consoleLog(err)
    })
}

export const consoleLog = (val1?: any, val2?: any, val3?: any, val4?: any) => {
  if (appConfig?.debugMode) {
    console.log(val1 || '', val2 || '', val3 || '', val4 || '')
  }
}
