import React from 'react'
import styled from 'styled-components'
import { Col, Modal, Row } from 'antd'
import Title from 'Components/text/Title'
import Text from 'Components/text/Text'
import { MyTheme } from 'Components/common/Theme'
import { PrimaryButton } from 'Components/button'

interface Props {
  isShow: boolean
  handleOk?: () => void
  handleCancel?: () => void
  title?: string
  description?: string
  confirmBtnTxt?: string
  cancelBtnTxt?: string
  loading?: boolean
  hasCancel?: boolean
  hasConfirm?: boolean
}

const ConfirmDailog = (props: Props) => {
  const {
    isShow = false,
    handleOk = () => {},
    handleCancel = () => {},
    title = 'ยืนยันการลบ',
    description = 'คุณแน่ใจที่จะลบข้อมูลนี้หรือไม่?',
    confirmBtnTxt = 'ลบ',
    cancelBtnTxt = 'ยกเลิก',
    loading = false,
    hasCancel = true,
    hasConfirm = true,
  } = props

  const footer = (
    <FooterStyled>
      <Row justify="center" gutter={16}>
        {hasCancel && (
          <Col md={8} xs={10}>
            <PrimaryButton text={cancelBtnTxt} btnType="Cancel" onClick={handleCancel} />
          </Col>
        )}
        {hasConfirm && (
          <Col md={8} xs={10}>
            <PrimaryButton text={confirmBtnTxt} onClick={handleOk} loading={loading} />
          </Col>
        )}
      </Row>
    </FooterStyled>
  )

  return (
    <ModalStyled visible={isShow} onOk={handleOk} onCancel={handleCancel} footer={footer} centered>
      <ContentWrapper>
        <Title>{title}</Title>
        {description.trim() !== '' && <TextStyled color={'#000'}>{description}</TextStyled>}
      </ContentWrapper>
    </ModalStyled>
  )
}

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 6px;
  }
  .ant-modal-footer {
    border: none;
  }
`

const ContentWrapper = styled.div`
  text-align: center;
  padding: 10px 50px;
`

const TextStyled = styled(Text)`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.black};
`

const FooterStyled = styled.div`
  margin-bottom: 15px;
`

export default ConfirmDailog
