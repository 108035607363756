import React, { useState, useEffect } from 'react'
import {
  Button,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Label,
  Form,
} from 'reactstrap'
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import TextArea from 'rc-textarea'
import { isMobile } from 'react-device-detect'
import ImgCrop from 'antd-img-crop'
import { Upload, Row as Rowand, Col as Coland, Image, message, Space, Popover } from 'antd'
import { useDispatch } from 'react-redux'
import { uploadAPI } from 'Store/upload/api'
import { PrimaryButton } from 'Components/button'
import { CloseCircleOutlined, PaperClipOutlined, SendOutlined } from '@ant-design/icons'
import { appConfig } from 'config'
import { APIPath } from 'services/APIPath'
import Axios from 'axios'
import { LoadingView } from 'Components/modal'
import styled from 'styled-components'
import { Theme } from 'Components/common'
import TemeplateMessage from './TemeplateMessage'
import { consoleLog } from 'helpers/utils'

function ChatInput(props) {
  const { onTyping, activeUser, isAgent } = props
  const [isMessageTyping, setIsMessageTyping] = useState(false)
  const [textMessage, settextMessage] = useState('')
  const [isOpen, setisOpen] = useState(false)
  const [file, setfile] = useState('')
  const [fileImage, setfileImage] = useState('')
  const [image, setImage] = useState('')
  const [link, setLink] = useState('')
  const [question, setQuestion] = useState('')
  const [loading, setLoading] = useState('')
  const dispatch = useDispatch()

  const toggle = () => setisOpen(!isOpen)

  consoleLog('fileImage', fileImage)

  // useEffect(() => {
  //   setfileImage('')
  //   setfile({
  //     name: '',
  //     size: '',
  //   })
  // }, [activeUser])

  //function for text input value change
  const handleChange = e => {
    settextMessage(e.target.value)
    autoExpand(e.target)
  }
  const handleMultiline = e => {
    autoExpand(e.target)
  }
  const autoExpand = el => {
    setTimeout(() => {
      if (el) {
        el.style.cssText = 'height: auto'
        el.style.cssText = `height: ${7 + el.scrollHeight}px`
      }
    }, 0)
  }

  const handleKeyup = e => {
    if (e.target.value.length >= 1 && isMessageTyping == false) {
      setIsMessageTyping(true)
      onTyping && onTyping(true)
    }
  }

  const handleOnblur = e => {
    setIsMessageTyping(false)
    if (e.target.value.length == 0) {
      onTyping && onTyping(false)
    }
  }

  //function for add emojis
  const addEmoji = e => {
    const emoji = e.native
    settextMessage(textMessage + emoji)
  }

  //function for file input change
  const handleFileChange = e => {
    if (e.target.files.length !== 0) setfile(e.target.files[0])
  }

  //function for image input change
  const handleImageChange = e => {
    if (e.target.files.length !== 0) setfileImage(e.target.files[0])
    //consoleLog('data.file', data.file)
  }

  //function for send data to onaddMessage function(in userChat/index.js component)
  const onaddMessage = (e, textMessage, type) => {
    e?.preventDefault()
    //if text value is not emptry then call onaddMessage function
    if (type == 'text') {
      setIsMessageTyping(false)
      props.onaddMessage(textMessage, 'text')
      settextMessage('')
    }

    //if file input value is not empty then call onaddMessage function
    if (type == 'file') {
      props.onaddMessage(textMessage, 'file')
      setfile('')
    }

    //if image input value is not empty then call onaddMessage function
    if (type == 'image') {
      props.onaddMessage(textMessage, 'image')
      setfileImage('')
      setImage('')
    }

    if (type == 'link') {
      props.onaddMessage(
        {
          image: textMessage,
          link: link,
        },
        'link',
      )
      setfileImage('')
      setImage('')
      setLink('')
    }

    if (type == 'auto') {
      props.onaddMessage(
        {
          image: textMessage,
          question: question,
        },
        'auto',
      )
      setfileImage('')
      setImage('')
      setQuestion('')
    }

    autoExpand(document.querySelector('textarea.textarea-auto-expand'))
  }

  const onUpload = (file, type) => {
    setLoading(true)
    const fmData = new FormData()
    fmData.append(`file`, file, file.name)
    const endPoint = appConfig.apiEndpoint + APIPath.upload
    const header = {}
    Axios.post(endPoint, fmData, header)
      .then(res => {
        const reportData = res?.data
        if (reportData.data && reportData.data.length > 0) {
          onaddMessage(null, reportData.data[0], type)
          setLoading(false)
          onClearImage()
        }
      })
      .catch(error => {
        message.error({
          content: 'ไม่สามารถ Upload ได้',
          key: 'export-error',
        })
        setLoading(false)
      })
  }

  const renderUtillMessage = () => {
    return (
      <div className="chat-input-links ms-md-2">
        <ul className="list-inline mb-0 ms-0">
          <li className="list-inline-item">
            <ButtonDropdown className="emoji-dropdown" direction="up" isOpen={isOpen} toggle={toggle}>
              <DropdownToggle
                id="emoji"
                color="link"
                className="text-decoration-none font-size-16 btn-lg waves-effect"
              >
                <i className="ri-emotion-happy-line"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Picker onSelect={addEmoji} />
              </DropdownMenu>
            </ButtonDropdown>
            <UncontrolledTooltip target="emoji" placement="top">
              Emoji
            </UncontrolledTooltip>
          </li>
          {isAgent && textMessage === '' && (
            <li className="list-inline-item input-file">
              <Label
                id="templete"
                className="btn btn-link text-decoration-none font-size-16 btn-md waves-effect"
              >
                <Popover
                  content={
                    <TemeplateMessage
                      onSelect={(type, value, data) => {
                        if (type === 'text') {
                          settextMessage(value)
                          autoExpand(document.querySelector('textarea.textarea-auto-expand'))
                        }
                        if (type === 'image') {
                          setImage(value)
                        }

                        if (type === 'link') {
                          setImage(data?.image || '')
                          setLink(data?.link || '')
                        }

                        if (type === 'auto') {
                          setImage(data?.image || '')
                          setQuestion(data?.question || '')
                        }
                      }}
                    />
                  }
                  title=""
                  trigger={['click']}
                >
                  <i className="ri-message-3-line"></i>
                </Popover>
              </Label>
              <UncontrolledTooltip target="templete" placement="top">
                Template
              </UncontrolledTooltip>
            </li>
          )}
          {textMessage === '' && (
            <li className="list-inline-item input-file">
              <Label
                id="files"
                className="btn btn-link text-decoration-none font-size-16 btn-md waves-effect"
              >
                <i className="ri-attachment-line"></i>
                <Input
                  onChange={e => handleFileChange(e)}
                  type="file"
                  name="fileInput"
                  size="60"
                  accept="application/pdf"
                />
              </Label>
              <UncontrolledTooltip target="files" placement="top">
                Attached File
              </UncontrolledTooltip>
            </li>
          )}
          {textMessage === '' && (
            <li className="list-inline-item input-file">
              <Label
                id="images"
                className="me-1 btn btn-link text-decoration-none font-size-16 btn-md waves-effect"
              >
                <i className="ri-image-fill"></i>
                <Input
                  onChange={e => handleImageChange(e)}
                  accept="image/*"
                  type="file"
                  name="fileInput"
                  size="60"
                />
              </Label>
              <UncontrolledTooltip target="images" placement="top">
                Images
              </UncontrolledTooltip>
            </li>
          )}
          <li className="list-inline-item">
            <Button
              type="submit"
              color="primary"
              className="font-size-16 btn-md chat-send waves-effect waves-light"
            >
              <i className="ri-send-plane-2-fill"></i>
            </Button>
          </li>
        </ul>
      </div>
    )
  }

  const onClearImage = () => {
    setfileImage('')
    setImage('')
  }

  const onSendImage = () => {
    onUpload(fileImage, 'image')
  }

  const onSendImageLink = () => {}

  const onClearFile = () => {
    setfile('')
  }

  const onSendFile = () => {
    onUpload(file, 'file')
  }

  if (fileImage !== '' || image !== '') {
    return (
      <React.Fragment>
        <div className="p-3 p-lg-4 border-top chat-input-message-multiline">
          <Rowand justify="center" align="middle" gutter="8">
            <Coland>
              {fileImage !== '' ? (
                <Image
                  src={URL.createObjectURL(fileImage)}
                  className="center"
                  style={{ maxHeight: '44px', width: 'auto', maxWidth: '100px' }}
                />
              ) : (
                <Image
                  src={appConfig.assetCdnEndpoint + image}
                  className="center"
                  style={{ maxHeight: '44px', width: 'auto', maxWidth: '100px' }}
                />
              )}
            </Coland>
            <Coland>
              {fileImage !== '' ? (
                <PrimaryButton
                  text="ส่ง"
                  size="small"
                  icon={<SendOutlined />}
                  onClick={onSendImage}
                  loading={loading}
                />
              ) : (
                <PrimaryButton
                  text="ส่ง"
                  size="small"
                  icon={<SendOutlined />}
                  onClick={() => {
                    if (link !== '') {
                      onaddMessage(null, image, 'link')
                    } else if (question !== '') {
                      onaddMessage(null, image, 'auto')
                    } else {
                      onaddMessage(null, image, 'image')
                    }
                  }}
                  loading={loading}
                />
              )}
            </Coland>
            <Coland>
              <PrimaryButton
                text="ยกเลิก"
                size="small"
                btnType="Cancel"
                onClick={onClearImage}
                icon={<CloseCircleOutlined />}
                loading={loading}
              />
            </Coland>
          </Rowand>
        </div>
      </React.Fragment>
    )
  }

  if (file !== '') {
    return (
      <React.Fragment>
        <div className="p-3 p-lg-4 border-top chat-input-message-multiline">
          <Rowand justify="center" align="middle" gutter={[8, 4]}>
            <Coland span={24}>
              <FileName>
                <Space>
                  <PaperClipOutlined />
                  {file.name}
                </Space>
              </FileName>
            </Coland>
            <Coland>
              <PrimaryButton
                text="ส่ง"
                size="small"
                icon={<SendOutlined />}
                onClick={onSendFile}
                loading={loading}
              />
            </Coland>
            <Coland>
              <PrimaryButton
                text="ยกเลิก"
                size="small"
                btnType="Cancel"
                onClick={onClearFile}
                icon={<CloseCircleOutlined />}
                loading={loading}
              />
            </Coland>
          </Rowand>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="p-3 p-lg-4 border-top chat-input-message-multiline">
        <Form onSubmit={e => onaddMessage(e, textMessage, 'text')}>
          <Row className="g-0">
            <Col>
              <div>
                <TextArea
                  type="text"
                  value={textMessage}
                  onChange={handleChange}
                  className="form-control form-control-lg bg-light border-light textarea-auto-expand"
                  placeholder="พิมพ์ข้อความ.."
                  onKeyPress={e => {
                    const keyCode = e.which || e.keyCode

                    // 13 represents the Enter key
                    if (!isMobile && keyCode === 13 && !e.shiftKey) {
                      // Don't generate a new line
                      onaddMessage(e, e.target.value, 'text')
                      e.preventDefault()

                      // Do something else such as send the message to back-end
                      // ...
                    } else {
                      autoExpand(e.target)
                    }
                  }}
                  onKeyUp={handleKeyup}
                  onBlur={handleOnblur}
                  onPaste={handleMultiline}
                  //autoSize
                  rows={1}
                />
                {/* <TextArea placeholder="Autosize height based on content lines" autoSize /> */}
              </div>
            </Col>
            <Col xs={'auto'}>{renderUtillMessage()}</Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  )
}

const FileName = styled.div`
  font-size: ${Theme.typography.body4};
  color: ${Theme.colors.primary};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 20px;
`

export default ChatInput
