import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Col, Modal, Row } from 'antd'
import Title from 'Components/text/Title'
import { PrimaryButton } from 'Components/button'
import { AppTextArea } from 'Components/form'
import { MyTheme } from 'Components/common/Theme'
import Label from 'Components/text/Label'

interface Props {
  isShow: boolean
  handleOk?: (remark: string) => void
  handleCancel?: () => void
  title?: string
  label?: string
  placeholder?: string
  confirmBtnTxt?: string
  cancelBtnTxt?: string
  rows?: number
  isRequireRemark?: boolean
  loading?: boolean
  description?: string
  lastValue?: string
}

const ConfirmQuestion = (props: Props) => {
  const {
    isShow = false,
    handleOk = () => {},
    handleCancel = () => {},
    title = 'ยืนยันการลบ',
    placeholder = 'ระบุเหตุผล',
    confirmBtnTxt = 'ลบ',
    cancelBtnTxt = 'ยกเลิก',
    label = 'เหตุผลที่ไม่อนุมัติ',
    rows = 4,
    isRequireRemark = true,
    loading = false,
    description = '',
    lastValue = '',
  } = props

  const [remark, setRemark] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    setRemark('')
    setError(false)
    if (lastValue) {
      setRemark(lastValue)
    }
  }, [isShow])

  const validateRemark = () => {
    if (isRequireRemark && remark.trim() === '') {
      setError(true)
      return false
    } else {
      setError(false)
      return true
    }
  }

  const onSubmit = () => {
    if (!validateRemark()) return
    handleOk(remark)
  }

  const onUpdateRemark = (val: string) => {
    setRemark(val)
    if (error) {
      validateRemark()
    }
  }

  const footer = (
    <FooterStyled>
      <Row justify="center" gutter={16}>
        <Col md={8} xs={10}>
          <PrimaryButton text={cancelBtnTxt} btnType="Cancel" onClick={handleCancel} />
        </Col>
        <Col md={8} xs={10}>
          <PrimaryButton text={confirmBtnTxt} onClick={onSubmit} loading={loading} />
        </Col>
      </Row>
    </FooterStyled>
  )

  return (
    <ModalStyled visible={isShow} onCancel={handleCancel} footer={footer} centered>
      <ContentWrapper>
        <Title>{title}</Title>
        <AppTextArea
          label={label}
          isError={error}
          isRequired={isRequireRemark}
          name="remark"
          rows={rows}
          placeHolder={placeholder}
          value={remark}
          onChange={onUpdateRemark}
        />
        {description && (
          <Row justify="start">
            <TextStyled>{description}</TextStyled>
          </Row>
        )}
      </ContentWrapper>
    </ModalStyled>
  )
}

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 6px;
  }
  .ant-modal-footer {
    border: none;
  }
`

const ContentWrapper = styled.div`
  text-align: center;
`

const FooterStyled = styled.div`
  margin-bottom: 15px;
`

const TextStyled = styled(Label)`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  text-align: left;
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
`

export default ConfirmQuestion
