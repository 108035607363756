import React from 'react'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { Col, Row, Space } from 'antd'
import LogoImage from 'Components/logo/LogoImage'
import { FiCheckCircle } from 'react-icons/fi'

type Props = {}

const Success: React.FunctionComponent<Props> = props => {
  const {} = props

  return (
    <MyLayout>
      <Row justify="center">
        <Col>
          <Space direction="vertical" align="center" size="small">
            <LogoImage primaryColor={false} width={180} />
            <Title>การสมัครสมาชิกตัวแทน ไชโย</Title>
          </Space>
        </Col>
      </Row>
      <ContentWrapper>
        <Row justify="center">
          <Col>
            <Space direction="vertical" align="center" size="middle">
              <FiCheckCircle style={{ fontSize: '40px' }} />
              <TitleSecond>ดำเนินการสมัครตัวแทนสำเร็จ</TitleSecond>
              <Description>
                ขอบคุณที่ร่วมสมัครเป็นสมาชิกตัวแทนกับไชโย <br />
                ทางทีมงานจะรีบดำเนินการติดต่อกลับเพื่อยืนยันผลการลงทะเบียนภายใน 2 - 3 วันทำการ
              </Description>
            </Space>
          </Col>
        </Row>
      </ContentWrapper>
    </MyLayout>
  )
}

const MyLayout = styled.div``

const Title = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.h2sm};
  text-align: center;
`

const TitleSecond = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.h5};
  text-align: left;
`

const Description = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  text-align: center;
`

const ContentWrapper = styled.div`
  margin: 38px 0;
`

export default Success
