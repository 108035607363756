import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'Store'
import { selectUserProfile } from 'Store/user/selector'
import { Avatar, Button, Col, Row, Switch } from 'antd'
import styled from 'styled-components'
import theme, { MyTheme } from 'Components/common/Theme'
import ConfigItem from './config-item'
import { PlusCircleOutlined, UserOutlined } from '@ant-design/icons'
import { Data, Message } from 'Store/setting-message/types'
import { cloneDeep } from 'lodash'
import ImageList from 'Pages/Chat/UserChat/ImageList'
import { replaceChatText } from 'helpers/format'
import ImageLink from 'Pages/Chat/UserChat/ImageLink'
import ImageQuestion from 'Pages/Chat/UserChat/ImageQuestion'
import { consoleLog } from 'helpers/utils'

type Props = {
  data: Data
  updateData?: (data: Data) => void
  max?: number
  min?: number
} & ReduxProps

const ContentMessage: React.FunctionComponent<Props> = props => {
  const { data, updateData = () => {}, max = 5, min = 1 } = props

  const onClickAdd = () => {
    const newData = cloneDeep(data)
    newData.messages.push({
      type: 'text',
      value: '',
    })
    updateData(newData)
  }

  const onChangeStatus = (val: boolean) => {
    const newData = cloneDeep(data)
    newData.status = val ? 'active' : 'inactive'
    updateData(newData)
  }

  const updateMessage = (message: Message, index: number) => {
    const newData = cloneDeep(data)
    newData.messages[index] = message
    updateData(newData)
  }

  const onDelete = (index: number) => {
    const newData = cloneDeep(data)
    newData.messages.splice(index, 1)
    updateData(newData)
  }

  const getContentMessageSample = (index: number, item: Message) => {
    consoleLog('item', item)
    switch (item.type) {
      case 'text':
        return (
          <MessageItem
            key={index}
            dangerouslySetInnerHTML={{
              __html: replaceChatText(item.value || ''),
            }}
          >
            {/* {item.value} */}
          </MessageItem>
        )
      case 'image':
        return (
          <MessageItem key={index}>
            <ImageList
              key={item.value}
              images={[
                {
                  image: item.value,
                },
              ]}
            />
          </MessageItem>
        )

      case 'link':
        return (
          <MessageItem key={index}>
            <ImageLink
              link={item?.data?.link}
              key={item.data?.image}
              images={[
                {
                  image: item.data?.image || '',
                },
              ]}
            />
          </MessageItem>
        )
      case 'auto':
        return (
          <>
            <MessageItem key={index}>
              <ImageQuestion
                onClick={() => {}}
                key={item.data?.image}
                images={[
                  {
                    image: item.data?.image,
                  },
                ]}
              />
            </MessageItem>
            {item.data?.question && (
              <CustomerMessageItem
                key={item.data?.question}
                dangerouslySetInnerHTML={{
                  __html: replaceChatText(item.data?.question || ''),
                }}
              ></CustomerMessageItem>
            )}
            {item.data?.answer_type === 'image' ? (
              <MessageItem key={index}>
                <ImageList
                  key={item.data?.answer}
                  images={[
                    {
                      image: item.data?.answer,
                    },
                  ]}
                />
              </MessageItem>
            ) : (
              <MessageItem
                key={item.data?.answer}
                dangerouslySetInnerHTML={{
                  __html: replaceChatText(item.data?.answer || ''),
                }}
              >
                {/* {item.value} */}
              </MessageItem>
            )}
          </>
        )

      default:
        return (
          <MessageItem
            key={index}
            dangerouslySetInnerHTML={{
              __html: replaceChatText(item.value || ''),
            }}
          >
            {/* {item.value} */}
          </MessageItem>
        )
    }
  }

  return (
    <ContentWrapper>
      <Title>{data.name}</Title>
      <Row gutter={[16, 16]} justify="space-between">
        <Col md={12} xs={24}>
          <Description>{data.detail}</Description>
        </Col>
        <Col>
          สถานะ{' '}
          <Switch
            checked={data.status === 'active'}
            onChange={onChangeStatus}
            checkedChildren="เปิดใช้งาน"
            unCheckedChildren="ปิดใช้งาน"
          />
        </Col>
      </Row>
      <Row gutter={[32, 32]}>
        <Col md={16} xs={24}>
          {data &&
            data.messages.map((item, index) => {
              return (
                <ConfigItem
                  message={item}
                  no={index + 1}
                  key={index}
                  updateData={data => updateMessage(data, index)}
                  onDelete={() => onDelete(index)}
                  deleteable={data.messages.length > min}
                />
              )
            })}
          {data.messages.length < max && (
            <Button type="link" icon={<PlusCircleOutlined />} onClick={onClickAdd} size="small">
              เพิ่มข้อความ (เพิ่มได้สูงสุด {max} ข้อความ)
            </Button>
          )}
        </Col>
        <Col md={8} xs={24}>
          <Title>ตัวอย่าง</Title>
          <DemoWrapper>
            <Row gutter={[8, 8]}>
              <Col span={4}>
                <Avatar style={{ backgroundColor: theme.colors.primary }} icon={<UserOutlined />} />
              </Col>
              <Col span={20}>
                {data &&
                  data.messages.map((item, index) => {
                    return getContentMessageSample(index, item)
                  })}
              </Col>
            </Row>
          </DemoWrapper>
        </Col>
        {/* <Col span={24}>
          สถานะ{' '}
          <Switch
            checked={data.status === 'active'}
            onChange={onChangeStatus}
            checkedChildren="เปิดใช้งาน"
            unCheckedChildren="ปิดใช้งาน"
          />
        </Col> */}
      </Row>
    </ContentWrapper>
  )
}

const Description = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
`

const Title = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
`

const ContentWrapper = styled.div`
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  padding: 30px 20px;
  border-radius: 5px;
`

const DemoWrapper = styled.div`
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.whitebg};
  padding: 10px;
  border-radius: 5px;
  .message-img img {
    max-width: 150px;
  }
`

const MessageItem = styled.div`
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: fit-content;
  max-width: 70%;
`

const CustomerMessageItem = styled.div`
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: auto;
  max-width: 70%;
  margin-left: 30%;
  text-align: right;
`

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: selectUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(ContentMessage)
