import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import HomePageLayout from 'Pages/Layout/PageLayout'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import {
  currentDate,
  formatDateWithoutTime,
  getLast1Month,
  numberWithComma,
  toDateQuery,
} from 'helpers/format'
import { cloneDeep } from 'lodash'
import { Card, Col, Divider, Row, Space, Statistic, Tag } from 'antd'
import styled from 'styled-components'
import theme, { MyTheme } from 'Components/common/Theme'
import { CheckOutlined, CloseOutlined, PlusOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { RangeDateItem } from 'Components/form'
import SummaryTeam from './components/SummaryTeam'
import {
  getBookCurrentDashboardtAPI,
  getBookLogDashboardtAPI,
  getCaseDashboardtAPI,
} from 'Store/dashboard/api'
import { BookCurrentReq, BookLogReq, CaseDashbaordReq, CaseData } from 'Store/dashboard/types'
import {
  selectCaseDashboardEvent,
  selectCaseDashboardInfo,
  selectSocketDashboardCount,
  selectSocketDashboardEvent,
  selectTeamDashboardEvent,
} from 'Store/dashboard/selector'
import { LoadingView } from 'Components/modal'
import ChatChart from './components/ChatChart'
import { UserType } from 'helpers/constanst'

type Props = {} & RouteComponentProps & ReduxProps

const Dashboard: React.FunctionComponent<Props> = props => {
  const { userProfile, loginData, caseData, caseEvent, teamEvent, socketEvent, socketCount } = props
  const name = userProfile?.profile?.first_name + ' ' + userProfile?.profile?.last_name

  const initFilter = {
    start_date: getLast1Month(),
    end_date: currentDate(),
  }

  const [filter, setFilter] = useState(cloneDeep(initFilter))
  const [showDrawer, setShowDrawer] = useState(false)
  const dispatch = useDispatch()

  const onGetCaseDashboard = (): void => {
    const req: CaseDashbaordReq = {
      start_date: toDateQuery(filter.start_date),
      end_date: toDateQuery(filter.end_date),
    }
    dispatch(getCaseDashboardtAPI(req, loginData?.access_token || ''))
  }

  const onGetBookLogDashboard = (): void => {
    const req: BookLogReq = {
      agentID: userProfile?.uuid || '',
      startDt: toDateQuery(filter.start_date),
      endDt: toDateQuery(filter.end_date),
    }
    dispatch(getBookLogDashboardtAPI(req, loginData?.access_token || ''))
  }

  const onGetBookCurrentDashboard = (): void => {
    const req: BookCurrentReq = {
      agentID: userProfile?.uuid || '',
    }
    dispatch(getBookCurrentDashboardtAPI(req, loginData?.access_token || ''))
  }

  useEffect(() => {
    onGetCaseDashboard()
    onGetBookLogDashboard()
    onGetBookCurrentDashboard()
  }, [])

  useEffect(() => {
    if (loginData?.access_token) {
      onGetCaseDashboard()
      onGetBookLogDashboard()
    }
  }, [filter.end_date])

  const handleChangeDate = (statDate: string, endDate: string) => {
    setFilter({
      ...filter,
      start_date: statDate,
      end_date: endDate,
    })
  }

  const boxItem = (title: string, value: any, icon: any) => {
    return (
      <Space align="start" size="large">
        {icon}
        <Space direction="vertical" size="small">
          <Title>{title}</Title>
          <Value>{numberWithComma(value)} งาน</Value>
        </Space>
      </Space>
    )
  }

  const summaryBox = () => {
    return (
      <BoxWrapper>
        <Row gutter={[8, 8]} justify="space-around" align="middle">
          <Col md={6} xs={24}>
            <Center>
              จำนวนงานทั้งหมด
              {/* <ValueBig>{numberWithComma(10000)}</ValueBig> */}
              <ValueBig>{socketCount?.bookCurrentCount?.all || 0}</ValueBig>
            </Center>
          </Col>
          <Col md={1} xs={0}>
            <Divider type="vertical" style={{ height: '80px' }} />
          </Col>
          <Col md={6} xs={24}>
            {boxItem(
              'งานที่อยู่ในระหว่างมอบหมาย (ของฉัน)',
              socketCount?.bookCurrentCount?.self || 0,
              <UserOutlined style={{ fontSize: '30px', color: theme.colors.primary }} />,
            )}
          </Col>
          <Col md={1} xs={0}>
            <Divider type="vertical" style={{ height: '80px' }} />
          </Col>
          <Col md={6} xs={24}>
            {boxItem(
              'งานที่อยู่ในระหว่างมอบหมาย (ของทีม)',
              socketCount?.bookCurrentCount?.team || 0,
              <TeamOutlined style={{ fontSize: '30px', color: theme.colors.primary }} />,
            )}
          </Col>
        </Row>
      </BoxWrapper>
    )
  }

  const taskItem = (title: string, value: number, color: string = '#3f8600', icon: any) => {
    return (
      <Card>
        <Statistic
          title={title}
          value={value}
          precision={0}
          valueStyle={{ color: color }}
          prefix={icon}
          suffix={<Title style={{ color: color }}>งาน</Title>}
        />
      </Card>
    )
  }

  const summaryTask = (data: CaseData | undefined) => {
    return (
      <Row gutter={[8, 8]}>
        <Col md={8} xs={24}>
          {taskItem('เปิดเคส', data?.open || 0, 'blue', <PlusOutlined />)}
        </Col>
        <Col md={8} xs={24}>
          {taskItem('ปิดเคส', data?.close || 0, 'green', <CheckOutlined />)}
        </Col>
        <Col md={8} xs={24}>
          {taskItem('ยกเลิก', data?.cancel || 0, 'red', <CloseOutlined />)}
        </Col>
      </Row>
    )
  }

  const mySummaryBox = () => {
    return (
      <BoxWrapper>
        <Row align="middle" gutter={[8, 16]}>
          <Col span={24}> ภาพรวมรายการสนทนาของฉัน</Col>
          <Col md={6} xs={24}>
            <BoxCircle>
              <BoxAbs>
                <Title>รับงานสะสม</Title>
                <ValueBig>{numberWithComma(socketCount?.bookLog.count || 0)}</ValueBig>
              </BoxAbs>
            </BoxCircle>
          </Col>
          <Col md={18} xs={24}>
            {summaryTask(caseData?.me)}
          </Col>
        </Row>
      </BoxWrapper>
    )
  }

  const teamSummaryBox = () => {
    return (
      <BoxWrapper>
        <Row align="middle" gutter={[8, 16]}>
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                ภาพรวมรายการสนทนาของทีม :{' '}
                <Tag color={theme.colors.primary}>{userProfile?.agent_name || ''}</Tag>
              </Col>
              <Col>
                <LinkText onClick={() => setShowDrawer(true)}>แสดงทั้งหมด </LinkText>
              </Col>
            </Row>
          </Col>
          <Col md={24} xs={24}>
            {summaryTask(caseData?.team)}
          </Col>
        </Row>
      </BoxWrapper>
    )
  }

  const daySummaryBox = () => {
    return (
      <BoxWrapper>
        <Row align="middle" gutter={[8, 16]}>
          <Col span={24}> อัตราความสำเร็จในแต่ละวัน</Col>
          <Col md={24} xs={24}>
            <Unit>ปริมาณงาน</Unit>
            <Row justify="start" align="bottom">
              <Col span={22}>
                <ChatChart dataStat={caseData?.date} />
              </Col>
              <Col span={2}>
                <Unit style={{ marginBottom: '12px' }}>วัน</Unit>
              </Col>
            </Row>
          </Col>
        </Row>
      </BoxWrapper>
    )
  }

  const SummaryTeamDrawer = <SummaryTeam visible={showDrawer} onClose={() => setShowDrawer(false)} />

  if (userProfile?.user_type === UserType.ADMIN) {
    return <HomePageLayout selected="dashboard" title={`Dashboard`} label={'comming soon'}></HomePageLayout>
  }

  if (userProfile?.user_type === UserType.SALE) {
    return <HomePageLayout selected="dashboard" title={`Dashboard`} label={'comming soon'}></HomePageLayout>
  }

  return (
    <HomePageLayout selected="dashboard" title={`Dashboard`} label={'ภาพรวมรายการสนทนา'}>
      <LoadingView isShow={caseEvent.IsLoading || teamEvent.IsLoading || socketEvent.IsLoading} />
      {SummaryTeamDrawer}
      <Row gutter={[8, 16]}>
        <Col span={24}>
          <TitleBox>ภาพรวมรายการสนทนา ณ ปัจจุบัน</TitleBox>
          {summaryBox()}
        </Col>
        <Col span={24}>
          <TitleBox>
            <Row gutter={[16, 16]} justify="space-between" align="middle">
              <Col>
                <Space wrap={true}>
                  สรุปภาพรวมรายการสนทนา
                  <Title>{`(ประจำวันที่ ${formatDateWithoutTime(filter.start_date)} - ${formatDateWithoutTime(
                    filter.end_date,
                  )})`}</Title>
                </Space>
              </Col>
              <Col md={8} xs={24}>
                <RangeDateItem
                  placeHolder={['วันที่เริ่มต้น', 'วันที่สิ้นสุด']}
                  onChange={handleChangeDate}
                  startDate={filter.start_date}
                  endDate={filter.end_date}
                  allowClear={false}
                />
              </Col>
            </Row>
          </TitleBox>
          {mySummaryBox()}
          <MarginBtm />
          {teamSummaryBox()}
          <MarginBtm />
          {daySummaryBox()}
        </Col>
      </Row>
    </HomePageLayout>
  )
}

const BoxWrapper = styled.div`
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  padding: 20px 10px;
  border-radius: 5px;
`

const TitleBox = styled.div`
  margin: 10px 0;
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
`

const Title = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.drakGrey};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.primary};
`

const Value = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.title3};
`

const MarginBtm = styled.div`
  margin-bottom: 10px;
`

const Center = styled.div`
  text-align: center;
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.title1};
`

const ValueBig = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.h2};
  text-align: center;
`

const BoxCircle = styled.div`
  margin: 0 auto;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  text-align: center;
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.title1};
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary1};
`

const BoxAbs = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const LinkText = styled.div`
  text-decoration: underline;
  cursor: pointer;
`

const Unit = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body5};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.primary};
  padding-bottom: 10px;
`

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: selectUserProfile(state),
    loginData: selectLoginData(state),
    caseData: selectCaseDashboardInfo(state),
    caseEvent: selectCaseDashboardEvent(state),
    teamEvent: selectTeamDashboardEvent(state),
    socketEvent: selectSocketDashboardEvent(state),
    socketCount: selectSocketDashboardCount(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(Dashboard)
