import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'

export type RootState = ReturnType<typeof rootReducer>

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other
  // options if needed
})

const middleware = [sagaMiddleware]
// const storeEnhancer = composeEnhancer(applyMiddleware(sagaMiddleware))
// const composeEnhancer = composeWithDevTools(storeEnhancer)
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)))
sagaMiddleware.run(rootSaga)

export default store
