import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import {
  ActionRes,
  CaseDashbaordReq,
  CaseDashbaordRes,
  UpdateReq,
  TeamDashbaordReq,
  TeamDashbaordRes,
  BookCurrentReq,
  BookCurrentRes,
  BookLogReq,
  BookLogRes,
} from './types'

export const getCaseDetailAction = createAsyncAction(
  'DASHBOARD/GET_CASE_DASHBOARD_DETAIL_REQ',
  'DASHBOARD/GET_CASE_DASHBOARD_DETAIL_SUCCESS',
  'DASHBOARD/GET_CASE_DASHBOARD_DETAIL_FAILURE',
)<CaseDashbaordReq, CaseDashbaordRes, Error>()

export const updateAction = createAsyncAction(
  'DASHBOARD/UPDATE_DASHBOARD_REQ',
  'DASHBOARD/UPDATE_DASHBOARD_SUCCESS',
  'DASHBOARD/UPDATE_DASHBOARD_FAILURE',
)<UpdateReq, ActionRes, Error>()

export const getTeamCaseDetailAction = createAsyncAction(
  'DASHBOARD/GET_TEAM_CASE_DASHBOARD_DETAIL_REQ',
  'DASHBOARD/GE_TEAM_CASE_DASHBOARD_DETAIL_SUCCESS',
  'DASHBOARD/GET_TEAM_CASE_DASHBOARD_DETAIL_FAILURE',
)<TeamDashbaordReq, TeamDashbaordRes, Error>()

export const getBookLogAction = createAsyncAction(
  'DASHBOARD/GET_BOOK_LOG_DASHBOARD_REQ',
  'DASHBOARD/GET_BOOK_LOG_DASHBOARD_SUCCESS',
  'DASHBOARD/GET_BOOK_LOG_DASHBOARD_FAILURE',
)<BookLogReq, BookLogRes, Error>()

export const getBookCurrentAction = createAsyncAction(
  'DASHBOARD/GET_BOOK_CURRENT_DASHBOARD_REQ',
  'DASHBOARD/GET_BOOK_CURRENT_DASHBOARD_SUCCESS',
  'DASHBOARD/GET_BOOK_CURRENT_DASHBOARD_FAILURE',
)<BookCurrentReq, BookCurrentRes, Error>()

export const clearEventAction = createStandardAction('DASHBOARD/CLEAR_EVENT')<void>()
