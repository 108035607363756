import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import LoginLayout from 'Pages/Layout/LoginLayout'
import { RootState } from 'Store'
import { connect, useDispatch } from 'react-redux'
import { AppInput } from 'Components/form'
import { clearEventAction } from 'Store/register/actions'
import EventModal from 'Components/modal/EventModal'
import { Col, Row } from 'antd'
import SuccessResult from './SuccessResult'
import { validateEmail } from 'helpers/format'
import { resetPasswordAPI } from 'Store/register/api'
import { selectVerifyToken, selectActionEvent } from 'Store/register/selector'
import { ResetPasswordReq } from 'Store/register/types'

type Props = {} & RouteComponentProps<{
  token: string
}> &
  ReduxProps

const ResetPassword: React.FunctionComponent<Props> = props => {
  const { history, actionEvent } = props

  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setShowSuccess(false)
    setIsError(false)
  }, [])

  const onResetPassword = (): void => {
    if (validateInput()) return
    const req: ResetPasswordReq = {
      email: email,
    }
    dispatch(resetPasswordAPI(req))
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onSuccess = (): void => {
    clearEvent()
    setShowSuccess(true)
  }

  const gotoHomePage = (): void => {
    history.push('/')
  }

  const onUpdateEmail = (val: string): void => {
    setEmail(val)
    if (isError) {
      validateInput()
    }
  }

  const validateInput = (): boolean => {
    let error = false
    if (email.trim() === '') {
      error = true
    }

    if (!validateEmail(email)) {
      error = true
    }

    setIsError(error)

    return error
  }

  const formResetPassword = (
    <LoginLayout title={'รีเซ็ตรหัสผ่าน'} onSubmit={onResetPassword} btnText="ส่งอีเมล" isLogin={false}>
      <Row justify="start" gutter={[16, 1]}>
        <Col span={24}>
          <AppInput
            name="status"
            label="อีเมล"
            placeHolder="ระบุอีเมลที่ต้องการรับลิ้งค์ในการเปลี่ยนรหัสผ่านของคุณ"
            errorMsg="อีเมลให้ถูกต้อง"
            value={email}
            onChange={onUpdateEmail}
            isRequired={true}
            isError={isError}
          />
        </Col>
      </Row>
    </LoginLayout>
  )

  const successResult = <SuccessResult onClick={gotoHomePage} email={email} />

  if (showSuccess) {
    return successResult
  }

  return (
    <>
      <EventModal
        key="craete-new-password"
        event={[actionEvent]}
        enable={true}
        successCalback={onSuccess}
        errorCalback={clearEvent}
      />
      {formResetPassword}
    </>
  )
}

const mapStateToProps = (state: RootState): any => {
  return {
    //event: selectEvent(state),
    actionEvent: selectActionEvent(state),
    verifyToken: selectVerifyToken(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(ResetPassword)
