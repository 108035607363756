import React from 'react'
import styled from 'styled-components'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Layout, Row, Col } from 'antd'
import { MyTheme } from 'Components/common/Theme'
import { RoundButton } from 'Components/button'

type Props = {
  children: React.ReactNode
  btnText?: string
  onClick?: () => void
} & RouteComponentProps

const ErrorLayout: React.FunctionComponent<Props> = ({ children, btnText = 'กลับไปหน้าแรก', onClick }) => {
  return (
    <MyLayout>
      <MyRow align="middle" justify="center">
        <MyCol md={10} sm={22} xs={22}>
          <BtnAbsolute>
            <RoundButton text={btnText} onClick={onClick} />
          </BtnAbsolute>
          <Box>
            <ChildBox>{children}</ChildBox>
          </Box>
        </MyCol>
      </MyRow>
    </MyLayout>
  )
}

const MyLayout = styled(Layout)`
  background: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  height: 100vh;
`

const MyRow = styled(Row)`
  height: 100vh;
`

const MyCol = styled(Col)`
  text-align: center;
`

const Box = styled.div`
  width: 100%;
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  border-radius: 14px;
  padding: 10px 36px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
`

const BtnAbsolute = styled.div`
  position: absolute;
  left: 30%;
  right: 30%;
  bottom: -20px;
  @media only screen and (max-width: 720px) {
    left: 20%;
    right: 20%;
  }
`

const ChildBox = styled.div`
  margin-top: 30px;
`

export default withRouter(ErrorLayout)
