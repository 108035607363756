import React from 'react'
import { Form } from 'antd'

type Props = {
  children: React.ReactNode
  label?: string
  status?: any
  help?: string
  hasFeedback?: boolean
  error?: string
}

const FormItem = (props: Props) => {
  const { children, status = '', help, hasFeedback, error } = props
  let { label } = props

  if (label === undefined) label = ' '
  let text
  if (status) text = error

  return (
    <Form.Item
      colon={false}
      style={{ textAlign: 'left' }}
      label={label}
      validateStatus={status === true ? 'error' : status}
      help={text || help}
      hasFeedback={hasFeedback}
    >
      {children}
    </Form.Item>
  )
}

export default FormItem
