import withAPIAction from 'helpers/withAPIAction'
import { ListReq, DetailReq } from './types'
import { getListAction, getDetailAction } from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'
import { getRequestString } from 'helpers/format'

export const getChatReportListAPI = (data: ListReq, token: string) => {
  return withAPIAction<ListReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getConversationReport}?${getRequestString(data)}`,
    data,
    token,
  })(getListAction)
}
