import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'Store'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { DetailDrawer } from 'Components/common/MainLayout'
import Iframe from 'react-iframe'

type Props = ReduxProps & {
  visible: boolean
  onCloseDrawer: () => void
  onSuccess?: () => void
}

const FormDrawer: React.FunctionComponent<Props> = props => {
  const { onCloseDrawer, visible } = props

  const data = '<iframe src="https://www.w3schools.com/" width="100%" height="100%"></iframe>'
  const url = 'https://www.epos.uat.fwd.co.th/'
  //const url = 'http://www.youtube.com/embed/xDMP3i36naA'

  return (
    <DetailDrawer
      title={'กรอกข้อมูลประกัน'}
      onCloseDrawer={onCloseDrawer}
      visible={visible}
      footer={null}
      width="55%"
      maskClosable={true}
    >
      <WebIframe url={`${url}`} id="myId" className="myClassname" position="relative" />
    </DetailDrawer>
  )
}

const WebIframe = styled(Iframe)`
  width: 100%;
  height: 100%;
`

const CreatedDate = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
`

const mapStateToProps = (state: RootState) => {
  return {}
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(FormDrawer)
