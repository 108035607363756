import { Meta } from './common.types'

export const convertMetaData = (meta: any): Meta => {
  return {
    page: meta?.page || 0,
    pageCount: meta?.page_count || 0,
    perPage: meta?.per_page || 0,
    totalCount: meta?.total_count || 0,
  }
}

// export const mappingKeyToData = (data: any[]) => {
//   const result = data.map((item: any, index: number) => {
//     item.key = index
//     return item
//   })
//   return result
// }
