import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import theme, { MyTheme } from 'Components/common/Theme'
import { Col, Row, Space } from 'antd'
import { PrimaryButton } from 'Components/button'
import LogoImage from 'Components/logo/LogoImage'
import { AppDateItem, AppInput, AppSelect } from 'Components/form'
import {
  formValidation,
  getAddressFormSubdistrictID,
  isFormError,
  isFormRequired,
  mergeArray,
  validateEmail,
} from 'helpers/format'
import { FiUser, FiUsers } from 'react-icons/fi'
import { AgentType, AgentTypeText, menuPrefix, menuSex } from 'helpers/constanst'
import { AddressData, GetAddressReq, RegisterReq } from 'Store/register/types'
import { getAddressAPI } from 'Store/register/api'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import { selectAddressData } from 'Store/register/selector'
import AppAutocomplete from 'Components/form/AppAutocomplete'
import { OptionProps } from 'Components/form/InputAutocomplete'

type Props = {
  onUpdateData: (value: any, key: string) => void
  formData: RegisterReq
  onNext?: () => void
} & ReduxProps

const RegisterForm: React.FunctionComponent<Props> = props => {
  const { onNext = () => {}, formData, onUpdateData, addressList } = props
  const dispatch = useDispatch()
  const [formError, setFormError] = useState([] as string[])
  const labelColor = theme.colors.white
  const individualRequireFeild = [
    'prefix',
    'sex',
    'first_name',
    'last_name',
    'birthday',
    'mobile',
    'address',
    // 'district_id',
    // 'sub_district_id',
    // 'province_id',
    'postcode',
    'mobile',
    'email',
    'address_item',
  ]
  const coporateRequireFeild = ['corporate_no', 'corporate_name']
  const isCorporate = formData.agent_type === AgentType.COPORATE
  const requireFeild = isCorporate
    ? mergeArray([individualRequireFeild, coporateRequireFeild])
    : individualRequireFeild

  const onGetAddressList = (): void => {
    const req: GetAddressReq = {
      postcode: formData.postcode,
    }
    dispatch(getAddressAPI(req))
  }

  useEffect(() => {
    if (formData.postcode && formData.postcode.length === 5) {
      onGetAddressList()
    } else {
      onUpdateData(undefined, 'address_item')
    }
  }, [formData.postcode])

  const onClickNext = () => {
    if (!validateForm()) return
    onNext()
  }

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    if (formData.email) {
      if (!validateEmail(formData.email)) {
        error.push('email_format')
      }
    }
    setFormError([...error])
    return error.length > 0 ? false : true
  }

  const isRequired = (name: string) => {
    return isFormRequired(requireFeild, name)
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const onSelectAddress = (subdistricID: number) => {
    const addressItem = getAddressFormSubdistrictID(addressList, subdistricID)
    onUpdateData(addressItem, 'address_item')
  }

  const typesData = [
    {
      name: AgentTypeText.INDIVIDUAL,
      value: AgentType.INDIVIDUAL,
      logo: <FiUser style={{ fontSize: '40px' }} />,
    },
    {
      name: AgentTypeText.COPORATE,
      value: AgentType.COPORATE,
      logo: <FiUsers style={{ fontSize: '40px' }} />,
    },
  ]

  const renderAgentType = () => {
    const types = typesData.map((item, index) => {
      return (
        <Col md={12} xs={12} key={index}>
          <TypeItem
            onClick={() => onUpdateData(item.value, 'agent_type')}
            className={formData.agent_type === item.value ? 'active' : ''}
          >
            <Space direction="vertical">
              {item.logo}
              {item.name}
            </Space>
          </TypeItem>
        </Col>
      )
    })
    return <Row gutter={[16, 8]}>{types}</Row>
  }

  const getAddressOptions = (data: AddressData[]) => {
    const options = [] as OptionProps[]
    data &&
      data.forEach(item => {
        options.push({
          value: item.sub_district.id,
          //label: `${item.sub_district.name} / ${item.district.name} /  ${item.province.name} `,
          label: (
            <AddressWrapper>
              <Row gutter={[1, 1]}>
                <Col span={8}>{item.sub_district.name} </Col>
                <Col span={8}>{item.district.name} </Col>
                <Col span={8}>{item.province.name}</Col>
              </Row>
            </AddressWrapper>
          ),
        })
      })
    return options
  }

  return (
    <MyLayout>
      <Row justify="center">
        <Col>
          <Space direction="vertical" align="center" size="small">
            <LogoImage primaryColor={false} width={180} />
            <Title>การสมัครสมาชิกตัวแทน ไชโย</Title>
          </Space>
        </Col>
      </Row>
      <TypeWrapper>
        <Row justify="center">
          <Col md={10} xs={24}>
            {renderAgentType()}
          </Col>
        </Row>
      </TypeWrapper>
      <Row justify="center">
        <Col md={20} xs={24}>
          <FormWrapper>
            <Row gutter={[16, 1]}>
              {isCorporate && (
                <Col md={12} xs={24}>
                  <AppInput
                    labelColor={labelColor}
                    name="corporate_no"
                    label="เลขทะเบียนนิติบุคคล"
                    isRequired={isRequired('corporate_no')}
                    isError={isError('corporate_no')}
                    value={formData.corporate_no}
                    onChange={onUpdateData}
                  />
                </Col>
              )}
              {isCorporate && (
                <Col md={12} xs={24}>
                  <AppInput
                    labelColor={labelColor}
                    name="corporate_name"
                    label="ชื่อนิติบุคคล"
                    isRequired={isRequired('corporate_name')}
                    isError={isError('corporate_name')}
                    value={formData.corporate_name}
                    onChange={onUpdateData}
                  />
                </Col>
              )}
              <Col md={12} xs={24}>
                <AppSelect
                  labelColor={labelColor}
                  name="prefix"
                  label="คำนำหน้า"
                  isRequired={isRequired('prefix')}
                  isError={isError('prefix')}
                  value={formData.prefix}
                  onChange={onUpdateData}
                  menu={menuPrefix}
                />
              </Col>
              <Col md={12} xs={24}>
                <AppSelect
                  labelColor={labelColor}
                  name="sex"
                  label="เพศ"
                  isRequired={isRequired('sex')}
                  isError={isError('sex')}
                  value={formData.sex}
                  onChange={onUpdateData}
                  menu={menuSex}
                />
              </Col>
              <Col md={12} xs={24}>
                <AppInput
                  labelColor={labelColor}
                  name="first_name"
                  label="ชื่อ"
                  isRequired={isRequired('first_name')}
                  isError={isError('first_name')}
                  value={formData.first_name}
                  onChange={onUpdateData}
                />
              </Col>
              <Col md={12} xs={24}>
                <AppInput
                  labelColor={labelColor}
                  name="last_name"
                  label="นามสกุล"
                  isRequired={isRequired('last_name')}
                  isError={isError('last_name')}
                  value={formData.last_name}
                  onChange={onUpdateData}
                />
              </Col>
              <Col md={12} xs={24}>
                <AppDateItem
                  labelColor={labelColor}
                  label="วันเดือนปีเกิด"
                  placeHolder="วันเดือนปีเกิด"
                  isRequired={isRequired('birthday')}
                  isError={isError('birthday')}
                  onChange={(date: string) => onUpdateData(date, 'birthday')}
                  date={formData.birthday}
                  allowClear={false}
                  picker="date"
                />
              </Col>
              <Col md={12} xs={24}>
                <AppInput
                  labelColor={labelColor}
                  name="mobile"
                  label="เบอร์โทรศัพท์"
                  placeHolder="ตัวอย่าง 0888888888"
                  isRequired={isRequired('mobile')}
                  isError={isError('mobile')}
                  value={formData.mobile}
                  onChange={onUpdateData}
                />
              </Col>
              <Col md={12} xs={24}>
                <AppInput
                  labelColor={labelColor}
                  name="email"
                  label="อีเมล"
                  errorMsg={isError('email_format') ? 'รูปแบบอีเมลให้ถูกต้อง' : 'อีเมล'}
                  isRequired={isRequired('email')}
                  isError={isError('email') || isError('email_format')}
                  value={formData.email}
                  onChange={onUpdateData}
                />
              </Col>
              <Col md={24} xs={24}>
                <AppInput
                  labelColor={labelColor}
                  name="address"
                  label="ที่อยู่"
                  isRequired={isRequired('address')}
                  isError={isError('address')}
                  value={formData.address}
                  onChange={onUpdateData}
                />
              </Col>
              <Col md={12} xs={24}>
                <AppAutocomplete
                  labelColor={labelColor}
                  name="postcode"
                  label="รหัสไปรษณีย์"
                  placeHolder="ระบุรหัสไปรษณีย์ 5 หลัก"
                  isRequired={isRequired('postcode')}
                  isError={isError('postcode')}
                  value={formData.postcode}
                  onChange={val => onSelectAddress(val)}
                  onSearch={val => onUpdateData(val, 'postcode')}
                  options={formData.postcode.length === 5 ? getAddressOptions(addressList) : []}
                />
              </Col>
              <Col md={12} xs={24}>
                <AppInput
                  labelColor={labelColor}
                  name="sub_district_id"
                  label="แขวง/ตำบล"
                  isRequired={isRequired('address_item')}
                  isError={isError('address_item')}
                  value={formData.address_item?.sub_district.name}
                  onChange={onUpdateData}
                  resProps={{ disabled: true }}
                />
              </Col>
              <Col md={12} xs={24}>
                <AppInput
                  labelColor={labelColor}
                  name="district_id"
                  label="เขต/อำเภอ"
                  isRequired={isRequired('address_item')}
                  isError={isError('address_item')}
                  value={formData.address_item?.district.name}
                  onChange={onUpdateData}
                  resProps={{ disabled: true }}
                />
              </Col>
              <Col md={12} xs={24}>
                <AppInput
                  labelColor={labelColor}
                  name="province_id"
                  label="จังหวัด"
                  isRequired={isRequired('address_item')}
                  isError={isError('address_item')}
                  value={formData.address_item?.province.name}
                  onChange={onUpdateData}
                  resProps={{ disabled: true }}
                />
              </Col>
            </Row>
          </FormWrapper>
        </Col>
      </Row>
      <BtnWrapper>
        <Row justify="center">
          <Col lg={6} xs={20}>
            <PrimaryButton text="ถัดไป" btnType="Primary2" onClick={onClickNext} disabled={false} />
          </Col>
        </Row>
      </BtnWrapper>
    </MyLayout>
  )
}

const MyLayout = styled.div``

const Title = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.h2sm};
  text-align: center;
`

const TypeWrapper = styled.div`
  margin: 38px 0;
`

const FormWrapper = styled.div`
  margin: 38px 0;
`

const TypeItem = styled.div`
  background: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  border: 1px solid ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  border-radius: 4px;
  padding: 20px 0;
  width: 100%;
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  text-align: center;
  cursor: pointer;
  &.active {
    background: ${(props: { theme: MyTheme }): string => props.theme.colors.primary1};
    border: 1px solid ${(props: { theme: MyTheme }): string => props.theme.colors.primary1};
  }
  &:hover {
    border: 1px solid ${(props: { theme: MyTheme }): string => props.theme.colors.primary1};
  }
`

const BtnWrapper = styled.div`
  margin-top: 40px;
`

const AddressWrapper = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
  @media only screen and (max-width: 720px) {
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    addressList: selectAddressData(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(RegisterForm)
