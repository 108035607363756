import React from 'react'
import LogoFullWhite from '../../assets/img/logo.png'
import LogoShortWhite from '../../assets/img/logo.png'
import LogoFullPrimary from '../../assets/img/logo.png'
import LogoShortPrimary from '../../assets/img/logo.png'

type Props = {
  primaryColor?: boolean
  shortLogo?: boolean
  width?: number
  height?: number
}

const LogoImage = (props: Props) => {
  const { primaryColor = true, width = '60%', shortLogo = false } = props

  if (primaryColor) {
    return <img width={width} src={shortLogo ? LogoShortPrimary : LogoFullPrimary} />
  }
  return <img width={width} src={shortLogo ? LogoShortWhite : LogoFullWhite}></img>
}

export default LogoImage
