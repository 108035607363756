import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import HomePageLayout from 'Pages/Layout/PageLayout'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import { PrimaryButton } from 'Components/button'
import { Col, Row, Tabs } from 'antd'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import ContentMessage from './components/content'
import { Data, DetailReq, FormData, UpdateReq } from 'Store/setting-message/types'
import { getGeetingMessagetDetailAPI, updateGeetingMessageTaskAPI } from 'Store/setting-message/api'
import {
  selectGeetingMessageEvent,
  selectGeetingMessageInfo,
  selectActionEvent,
} from 'Store/setting-message/selector'
import { EventModal, LoadingView } from 'Components/modal'
import { cloneDeep } from 'lodash'
import { clearEventAction } from 'Store/setting-message/actions'
import { getGeetingTabName } from 'helpers/format'
const { TabPane } = Tabs

type Props = {} & RouteComponentProps & ReduxProps

const SettingMessage: React.FunctionComponent<Props> = props => {
  const { userProfile, loginData, data, event, actionEvent } = props
  const [activeTab, setActiveTab] = useState('geeting')
  const [formData, setFormData] = useState([] as Data[])
  const dispatch = useDispatch()

  const onGetDetail = (): void => {
    const req: DetailReq = {}
    dispatch(getGeetingMessagetDetailAPI(req, loginData?.access_token || ''))
  }

  useEffect(() => {
    onGetDetail()
  }, [])

  useEffect(() => {
    setFormData(data)
  }, [data])

  const formatReq = (data: Data[]) => {
    const req = [] as FormData[]
    data &&
      data.forEach(item => {
        req.push({
          id: item.id,
          datas: item.messages,
          status: item.status || 'active',
        })
      })
    return req
  }

  const onUpdate = (): void => {
    const req: UpdateReq = {
      groups: formatReq(formData),
    }
    dispatch(updateGeetingMessageTaskAPI(req, loginData?.access_token || ''))
  }

  const updateData = (data: Data, id: number) => {
    let newData = cloneDeep(formData)
    newData = newData.map(item => {
      if (item.id === id) {
        return data
      }
      return item
    })
    setFormData([...newData])
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onSuccess = (): void => {
    clearEvent()
    onGetDetail()
  }

  return (
    <HomePageLayout
      selected="setting-message"
      title={`ตั้งค่าข้อความ`}
      label={''}
      // rightWidget={<PrimaryButton text="บันทึกข้อมูล" onClick={onUpdate} />}
    >
      <LoadingView isShow={event.IsLoading} />
      <EventModal event={[actionEvent]} enable={true} successCalback={onSuccess} errorCalback={clearEvent} />
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Tabs onChange={setActiveTab} activeKey={activeTab}>
            {formData &&
              formData.map(item => {
                const max = item.key === 'template' ? 30 : 5
                return (
                  <TabPane tab={getGeetingTabName(item.key)} key={item.key}>
                    <ContentMessage data={item} updateData={data => updateData(data, item.id)} max={max} />
                  </TabPane>
                )
              })}
          </Tabs>
        </Col>
        <Col>
          <PrimaryButton text="บันทึกข้อมูล" onClick={onUpdate} />
        </Col>
      </Row>
    </HomePageLayout>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: selectUserProfile(state),
    loginData: selectLoginData(state),
    data: selectGeetingMessageInfo(state),
    event: selectGeetingMessageEvent(state),
    actionEvent: selectActionEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(SettingMessage)
