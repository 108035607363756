import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Col, message, Row, Space } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { ExportReq, ListReq } from 'Store/chat-report/types'
import { selectList, selectListEvent } from 'Store/chat-report/selector'
import {
  currentDate,
  currentDateTimeThaiformat,
  getLast1Month,
  getRequestString,
  isFilter,
  toDateQuery,
} from 'helpers/format'
import { TableListPage } from 'Components/common/Table'
import { PrimaryButton } from 'Components/button'
import HomePageLayout from 'Pages/Layout/PageLayout'
import { Pagination } from 'Components/Pagination'
import { RangeDateItem, SearchInput } from 'Components/form'
import LinkText from 'Components/text/LinkText'
import { Theme } from 'Components/common'
import { cloneDeep } from 'lodash'
import { selectLoginData } from 'Store/user/selector'
import { getChatReportListAPI } from 'Store/chat-report/api'
import { appConfig } from 'config'
import { APIPath } from 'services/APIPath'
import Axios from 'axios'
import downloadCSVFile from 'helpers/export'
import { LoadingView } from 'Components/modal'

type Props = {} & RouteComponentProps & ReduxProps

const Report: React.FunctionComponent<Props> = props => {
  const { listEvent, dataList, loginData } = props
  const dispatch = useDispatch()
  const [resetFilter, setResetFilter] = useState(false)
  const [exporting, setExporting] = useState(false)

  const initFilter = {
    start_date: getLast1Month(),
    end_date: currentDate(),
    merchant_id: 0,
    keyword: '',
    status: '',
  }
  const [filter, setFilter] = useState(cloneDeep(initFilter))
  const [pagination, setPagination] = useState({
    Page: 1,
    RowPerPage: 10,
  })
  const meta = dataList.meta
  const datas = dataList.data

  const onGetDataList = (): void => {
    const req: ListReq = {
      page: pagination.Page,
      per_page: pagination.RowPerPage,
      start_date: toDateQuery(filter.start_date),
      end_date: toDateQuery(filter.end_date),
      name: filter.keyword,
    }
    dispatch(getChatReportListAPI(req, loginData?.access_token || ''))
  }

  useEffect(() => {
    if (loginData?.access_token) {
      onGetDataList()
    }
  }, [pagination])

  useEffect(() => {
    if (resetFilter) {
      onGetDataList()
      setResetFilter(false)
    }
  }, [resetFilter])

  const onClickSearch = () => {
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  useEffect(() => {
    if (pagination.Page === 1) return
    pagination.Page = 1
    pagination.RowPerPage = 10
    setPagination({ ...pagination })
  }, [])

  const onExport = (): void => {
    setExporting(true)
    const req: ExportReq = {
      start_date: toDateQuery(filter.start_date),
      end_date: toDateQuery(filter.end_date),
      name: filter.keyword,
    }

    const endPoint = `${appConfig.apiEndpoint}${APIPath.exportConversationReport}?${getRequestString(req)}`
    const header = {
      headers: {
        Authorization: `Bearer ${loginData?.access_token || ''}`,
      },
    }
    Axios.get(endPoint, header)
      .then((res: any) => {
        const reportData = res?.data
        const fileName = `รายงานการสนทนา ประจำวันที่ ${currentDateTimeThaiformat()}.csv`
        downloadCSVFile(reportData, fileName)
        setExporting(false)
      })
      .catch(error => {
        setExporting(false)
        message.error({
          content: error?.response?.data?.error || 'เกิดข้อผิดพลาด ไม่สามารถ Export ข้อมูลได้',
          key: 'export-error',
        })
      })
  }

  const onFilter = (key: string, val: string) => {
    setFilter({
      ...filter,
      [key]: val,
    })
  }

  const onResetFilter = () => {
    setFilter(cloneDeep({ ...initFilter }))
    setResetFilter(true)
  }

  const handleChangeDate = (statDate: string, endDate: string) => {
    setFilter({
      ...filter,
      start_date: statDate,
      end_date: endDate,
    })
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: '60px',
      render: (value: any, record: any, index: number) => {
        return index + 1 + (pagination.Page - 1) * pagination.RowPerPage
      },
    },
    {
      title: 'ชื่อ Agent',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'วันที่',
      dataIndex: 'date',
      key: 'date',
      render: (val: string) => toDateQuery(val),
    },
    {
      title: 'เปิดเคส',
      dataIndex: 'count_open',
      key: 'count_open',
    },
    {
      title: 'ปิดเคส',
      dataIndex: 'count_close',
      key: 'count_close',
    },
    {
      title: 'ยกเลิก',
      dataIndex: 'count_cancel',
      key: 'count_cancel',
    },
    {
      title: 'รวมเคส',
      dataIndex: 'count_all',
      key: 'count_all',
    },
  ]

  const FilterContent = (
    <FilterWrapper>
      <Row justify="start" align="bottom" gutter={[16, 16]}>
        <Col xs={24} md={6}>
          <SearchInput
            name="keyword"
            placeholder="ค้นหาชื่อตัวแทน"
            value={filter.keyword}
            onChange={onFilter}
          />
        </Col>
        <Col xs={24} md={6}>
          <RangeDateItem
            placeHolder={['เลือกวันที่เริ่มต้น', 'วันที่สิ้นสุด']}
            onChange={handleChangeDate}
            startDate={filter.start_date}
            endDate={filter.end_date}
            showTime={false}
            maxDate={false}
            allowClear={false}
          />
        </Col>
        <Col>
          <Space size="middle" align="center">
            <PrimaryButton text="ค้นหา" btnType="Cancel" onClick={onClickSearch} key="search" />
            <PrimaryButton text="Export" btnType="Primary" onClick={onExport} key="export" />
            {isFilter(filter) && (
              <LinkText color={Theme.colors.primary} onClick={onResetFilter}>
                รีเซ็ตการค้นหา
              </LinkText>
            )}
          </Space>
        </Col>
      </Row>
    </FilterWrapper>
  )

  const PaginationContent = (
    <Pagination
      currentPage={pagination.Page}
      totalRows={meta?.totalCount || 0}
      perPage={pagination.RowPerPage}
      onClickPage={(page: number) => {
        pagination.Page = page
        setPagination({ ...pagination })
      }}
      onChangeNumber={(number: number) => {
        pagination.RowPerPage = number
        setPagination({ ...pagination })
      }}
    />
  )

  return (
    <>
      <HomePageLayout selected="report-chat" title="รายงานการสนทนา" label="">
        <LoadingView isShow={exporting} />
        {FilterContent}
        <TableListPage
          columns={columns}
          datas={datas}
          isLoading={listEvent.IsLoading}
          paginationContent={null}
        />
        {PaginationContent}
      </HomePageLayout>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectList(state),
    listEvent: selectListEvent(state),
    loginData: selectLoginData(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(Report)

const FilterWrapper = styled.div`
  padding: 10px 0 5px 0;
`
