import React from 'react'
import { Input as InputAntd } from 'antd'
import { MyTheme } from 'Components/common/Theme'
import styled from 'styled-components'
const { TextArea: TextAreaAntd } = InputAntd

type Props = {
  name?: string
  placeholder?: string
  readonly?: boolean
  clear?: boolean
  disabled?: boolean
  value?: string
  prefix?: any
  onChange?: (val: string, name: string) => void
  rows?: number
  resProps?: object
  showCount?: boolean
  maxLength?: number
}

const TextArea = (props: Props) => {
  const {
    placeholder,
    readonly = false,
    clear = true,
    disabled = false,
    value = '',
    prefix = null,
    onChange,
    resProps = {},
    rows = 4,
  } = props

  const onChangeEvent = (evt: any): void => {
    if (onChange) {
      onChange(evt.target.value, props.name || '')
    }
  }

  const css = { width: '100%', borderRadius: '5px' } as any
  if (readonly) {
    css.backgroundColor = '#DADAD9'
  } else {
    css.backgroundColor = '#FFF'
  }

  return (
    <StyledTextField
      rows={rows}
      size="large"
      placeholder={placeholder}
      readOnly={readonly}
      disabled={disabled}
      style={css}
      value={value}
      onChange={onChangeEvent}
      allowClear={clear}
      prefix={prefix}
      {...resProps}
    />
  )
}

const StyledTextField = styled(TextAreaAntd)`
  margin-top: 2px;
  height: 45px;
  width: 100%;
  border-radius: 6px;
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.regular};
  color: #000;
  //padding: 10px 26px 10px 16px;

  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .ant-input {
    height: 45px;
    width: 100%;
    border-radius: 6px;
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
    font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.regular};
    color: #000;

    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    textarea:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
`

export default TextArea
