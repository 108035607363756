import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'Store'
import { selectUserProfile } from 'Store/user/selector'
import { Button, Col, Divider, Row } from 'antd'
import styled from 'styled-components'
import { AppSelect, AppTextArea, SelectItem, UploadForm } from 'Components/form'
import { Message } from 'Store/setting-message/types'
import { menuAnswerType, menuGeetingMsgType } from 'helpers/constanst'
import { cloneDeep } from 'lodash'
import { DeleteOutlined } from '@ant-design/icons'
import { consoleLog } from 'helpers/utils'

type Props = {
  no: number
  message: Message
  updateData?: (data: Message) => void
  onDelete?: () => void
  deleteable?: boolean
} & ReduxProps

const ConfigItem: React.FunctionComponent<Props> = props => {
  const { message, no, updateData = () => {}, onDelete = () => {}, deleteable = true } = props

  const onUpdateData = (val: string, key: string) => {
    consoleLog('val', val)
    const newData = cloneDeep(message)
    newData[key] = val
    updateData({ ...newData })
  }

  const onUpdateDataObject = (val: string, key: string) => {
    const newData = cloneDeep(message)
    if (newData.data) {
      newData.data[key] = val
      updateData({ ...newData })
    }
  }

  const onUpdateType = (val: string) => {
    const newData = {
      type: val,
    } as Message
    if (val === 'text' || val === 'image') {
      updateData({
        ...newData,
        value: '',
      })
    }
    if (val === 'link') {
      updateData({
        ...newData,
        data: {
          image: '',
          link: '',
        },
      })
    }
    if (val === 'auto') {
      updateData({
        ...newData,
        data: {
          image: '',
          question: '',
          answer: '',
          answer_type: 'text',
        },
      })
    }
  }

  const getContentType = (type: 'text' | 'image' | 'link' | 'auto') => {
    switch (type) {
      case 'text':
        return (
          <Col span={24}>
            <AppTextArea
              label=""
              placeHolder="ระบุข้อความ"
              isRequired={false}
              isError={false}
              name="value"
              rows={4}
              value={message.value}
              onChange={onUpdateData}
              resProps={{
                showCount: true,
                maxLength: 500,
              }}
            />
          </Col>
        )

      case 'image':
        return (
          <Col span={24}>
            <UploadForm
              token={''}
              onUpdateData={onUpdateData}
              currentFile={message.value || ''}
              folder="merchant"
              listType="picture-card"
              isPrivate={true}
              name="value"
              description={''}
              isRequired={false}
              isError={false}
              readOnly={false}
              acceptType={'image/png,image/jpeg'}
            />
          </Col>
        )

      case 'link':
        return (
          <Col span={24}>
            <Row align="top" gutter={[8, 8]}>
              <Col>
                <UploadForm
                  token={''}
                  onUpdateData={onUpdateDataObject}
                  currentFile={message.data?.image || ''}
                  folder="merchant"
                  listType="picture-card"
                  isPrivate={true}
                  name="image"
                  description={''}
                  isRequired={true}
                  isError={false}
                  readOnly={false}
                  acceptType={'image/png,image/jpeg'}
                />
              </Col>
              <Col md={24} xs={24}>
                <AppTextArea
                  label="ลิ้งค์ที่เกี่ยวข้อง"
                  placeHolder="ระบุ URL ของลิ้งค์ที่เกี่ยวข้อง"
                  isRequired={false}
                  isError={false}
                  name="link"
                  rows={2}
                  value={message.data?.link}
                  onChange={onUpdateDataObject}
                  resProps={{
                    showCount: true,
                    maxLength: 200,
                  }}
                />
              </Col>
            </Row>
          </Col>
        )
      case 'auto':
        return (
          <Col span={24}>
            <Row align="top" gutter={[8, 8]}>
              <Col span={24}>
                <UploadForm
                  token={''}
                  onUpdateData={onUpdateDataObject}
                  currentFile={message.data?.image || ''}
                  folder="merchant"
                  listType="picture-card"
                  isPrivate={true}
                  name="image"
                  description={''}
                  isRequired={false}
                  isError={false}
                  readOnly={false}
                  acceptType={'image/png,image/jpeg'}
                />
              </Col>
              <Col md={24} xs={24}>
                <AppTextArea
                  label="คำถามอัตโนมัติเมือลูกค้าคลิกที่รูปภาพ"
                  placeHolder="คำถาม"
                  isRequired={false}
                  isError={false}
                  name="question"
                  rows={4}
                  value={message.data?.question}
                  onChange={onUpdateDataObject}
                  resProps={{
                    showCount: true,
                    maxLength: 20,
                  }}
                />
              </Col>
              <Col md={24} xs={24}>
                <AppSelect
                  name={'answer_type'}
                  label={'เลือกประเภทคำตอบ'}
                  isRequired={false}
                  value={message.data?.answer_type}
                  menu={menuAnswerType}
                  placeHolder={'เลือกประเภทคำตอบ'}
                  onChange={onUpdateDataObject}
                />
              </Col>
              {message.data?.answer_type === 'image' ? (
                <Col md={24} xs={24}>
                  <UploadForm
                    label="คำตอบอัตโนมัติ"
                    token={''}
                    onUpdateData={onUpdateDataObject}
                    currentFile={message.data?.answer || ''}
                    folder="geeting"
                    listType="picture-card"
                    isPrivate={true}
                    name="answer"
                    description={''}
                    isRequired={false}
                    isError={false}
                    readOnly={false}
                    acceptType={'image/png,image/jpeg'}
                  />
                </Col>
              ) : (
                <Col md={24} xs={24}>
                  <AppTextArea
                    label="คำตอบอัตโนมัติ"
                    placeHolder="คำตอบ"
                    isRequired={false}
                    isError={false}
                    name="answer"
                    rows={4}
                    value={message.data?.answer}
                    onChange={onUpdateDataObject}
                    resProps={{
                      showCount: true,
                      maxLength: 500,
                    }}
                  />
                </Col>
              )}
            </Row>
          </Col>
        )

      default:
        return (
          <Col span={24}>
            <AppTextArea
              label=""
              placeHolder="ระบุข้อความ"
              isRequired={false}
              isError={false}
              name="value"
              rows={4}
              value={message.value}
              onChange={onUpdateData}
              resProps={{
                showCount: true,
                maxLength: 500,
              }}
            />
          </Col>
        )
    }
  }

  return (
    <ContentWrapper>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <span>ข้อความที่ {no}</span>
            </Col>
            {deleteable && (
              <Col>
                <Button type="text" shape="circle" icon={<DeleteOutlined />} danger onClick={onDelete} />
              </Col>
            )}
          </Row>
        </Col>
        <Col span={12}>
          <SelectItem
            name={'type'}
            label={''}
            isRequired={true}
            value={message.type}
            menu={menuGeetingMsgType}
            placeHolder={'เลือกประเภทเนื้อหา'}
            onChange={onUpdateType}
          />
        </Col>
        {getContentType(message.type)}
      </Row>
      <Divider />
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  margin-top: 10px;
`

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: selectUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(ConfigItem)
