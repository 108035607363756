import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { LoginAction, UserState } from './types'
import { initEvent } from 'Store/common/common.reducer'
import parseErrorMessage from 'helpers/parseErrorMessage'
import {
  saveLoginData,
  removeLoginData,
  saveUserProfile,
  removeUserProfile,
  saveStaffPermission,
  removeStaffPermission,
} from 'helpers/account'
import { consoleLog } from 'helpers/utils'

const initialState: UserState = {
  loginData: undefined,
  event: cloneDeep(initEvent),
  profile: undefined,
  staffPermissionEvent: cloneDeep(initEvent),
  agentProfile: undefined,
}

export default function(state: UserState = initialState, action: LoginAction) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.loginAction.request):
        consoleLog('action', action)
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.loginData = undefined
        return draft
      case getType(actions.loginAction.success):
        response = action.payload.data
        draft.event.IsLoading = false
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'เข้าสู่ระบบสำเร็จ'
        draft.loginData = response
        saveLoginData(response)
        return draft

      case getType(actions.getUserProfileAction.request):
        return draft
      case getType(actions.getUserProfileAction.success):
        response = action.payload
        draft.profile = response.data
        saveUserProfile({ ...response.data })
        return draft
      case getType(actions.loginAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getStaffPermissionAction.request):
        draft.staffPermissionEvent = cloneDeep(initEvent)
        draft.staffPermissionEvent.IsLoading = true
        removeStaffPermission()
        return draft
      case getType(actions.getStaffPermissionAction.success):
        response = action.payload
        draft.staffPermissionEvent.IsLoading = false
        draft.staffPermissionEvent.SubmitSuccess = true
        saveStaffPermission(response.data)
        return draft
      case getType(actions.getStaffPermissionAction.failure):
        draft.staffPermissionEvent.IsLoading = false
        draft.staffPermissionEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.clearEventAction):
        draft.event = cloneDeep(initEvent)
        draft.staffPermissionEvent = cloneDeep(initEvent)
        return draft
      case getType(actions.updateLoginDataAction):
        if (!draft.loginData) {
          draft.loginData = action.payload.data
        }
        return draft
      case getType(actions.updateUserProfileAction):
        if (!draft.profile) {
          draft.profile = action.payload.data
        }
        return draft

      case getType(actions.getAgentProfileAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.agentProfile = undefined
        return draft
      case getType(actions.getAgentProfileAction.success):
        response = action.payload.data
        if (action.payload.status.code === 200) {
          draft.event.IsLoading = false
          draft.event.SubmitSuccess = true
          draft.event.MessageSuccess = 'null'
          draft.agentProfile = response
        } else {
          draft.event.IsLoading = false
          draft.event.SubmitError = 'null'
        }
        return draft
      case getType(actions.getAgentProfileAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = ''
        return draft
      case getType(actions.logoutAction):
        draft.profile = undefined
        removeLoginData()
        removeUserProfile()
        removeStaffPermission()
        return draft
      default:
        return state
    }
  })
}
