import React from 'react'
import { Input as InputAntd } from 'antd'
import { MyTheme } from 'Components/common/Theme'
import styled from 'styled-components'

type Props = {
  name?: string
  type?: string
  placeholder?: string
  readonly?: boolean
  clear?: boolean
  disabled?: boolean
  value?: string
  prefix?: any
  onChange?: (val: string, name: string) => void
  resProps?: object
}

const InputPassword = (props: Props) => {
  const {
    type = 'text',
    placeholder,
    readonly = false,
    clear = true,
    disabled = false,
    value = '',
    prefix = null,
    onChange,
    resProps = {},
  } = props

  const onChangeEvent = (evt: any): void => {
    if (onChange) {
      onChange(evt.target.value, props.name || '')
    }
  }

  const css = { width: '100%', borderRadius: '5px' } as any
  if (readonly) {
    css.backgroundColor = '#DADAD9'
  } else {
    css.backgroundColor = '#FFF'
  }

  return (
    <InputStyle>
      <StyledTextField
        type={type}
        size="large"
        placeholder={placeholder}
        readOnly={readonly}
        disabled={disabled}
        style={css}
        value={value}
        onChange={onChangeEvent}
        allowClear={clear}
        prefix={prefix}
        {...resProps}
        min={0}
      />
    </InputStyle>
  )
}

const StyledTextField = styled(InputAntd.Password)`
  height: 45px;
  width: 100%;
  .ant-input {
    border-radius: 6px;
    //height: 40px;
    border: 2px solid rgba(197, 207, 213, 0.4);
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
    font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.regular};
    color: #000;
    padding: 10px 26px 10px 16px;
    border-width: 2px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`

const InputStyle = styled.div`
  .ant-input-affix-wrapper-disabled {
    background-color: #f5f5f5 !important;
  }
  left: 0 !important;
  top: 0px !important;
  right: 0 !important;
`

export default InputPassword
