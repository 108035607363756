import produce from 'immer'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action } from './types'

const initialState: State = {
  activeTab: 'chat',
  userSidebar: false,
  conversationName: 'Doris Brown',
}

export default function(state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.setActiveTabAction):
        return {
          ...state,
          activeTab: action.payload.tabId,
        }
      // case getType(actions.openUserSidebarAction):
      //   return {
      //     ...state,
      //     userSidebar: true,
      //   }
      // case getType(actions.closeUserSidebarAction):
      //   return {
      //     ...state,
      //     userSidebar: false,
      //   }
      case getType(actions.setconversationNameInOpenChatAction):
        return {
          ...state,
          conversationName: action.payload.conversationName,
        }
      default:
        return state
    }
  })
}
