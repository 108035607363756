import React from 'react'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { Col, Row } from 'antd'

type Props = {
  title: string
  value: any
  span?: number
}

const InfoItem: React.FunctionComponent<Props> = props => {
  const { title, value, span = 15 } = props

  return (
    <Row justify="space-between">
      <Col span={8}>
        <InfoTitle>{title}</InfoTitle>
      </Col>
      <Col span={span} offset={1}>
        <InfoValue>{value || '-'}</InfoValue>
      </Col>
    </Row>
  )
}

const InfoTitle = styled.span`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
`

const InfoValue = styled.span`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.black};
`

export default InfoItem
