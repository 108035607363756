import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action } from './types'
import { initEvent, initMeta } from 'Store/common/common.reducer'
import parseErrorMessage from 'helpers/parseErrorMessage'
import { convertMetaData } from 'Store/common/common.function'

const initialState: State = {
  customerInfo: {
    event: cloneDeep(initEvent),
    data: undefined,
  },
  customerList: {
    data: {
      data: [],
      meta: cloneDeep(initMeta),
    },
    event: cloneDeep(initEvent),
  },
  actionEvent: cloneDeep(initEvent),
  customerDetail: {
    detail: undefined,
    event: cloneDeep(initEvent),
  },
  lastCreateTask: undefined,
  countData: {
    all: 0,
    open: 0,
    close: 0,
    cancel: 0,
  },
  taskEvent: cloneDeep(initEvent),
  saleStatus: [],
  saleTaskStatus: []
}

export default function(state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.updateCustomerAction.request):
        draft.actionEvent = cloneDeep(initEvent)
        draft.actionEvent.IsLoading = true
        draft.lastCreateTask = undefined
        return draft
      case getType(actions.updateCustomerAction.success):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = undefined
        draft.actionEvent.SubmitSuccess = true
        draft.actionEvent.MessageSuccess = 'บันทึกข้อมูลสำเร็จ'
        draft.lastCreateTask = action.payload.data.task_latest
        return draft
      case getType(actions.updateCustomerAction.failure):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.getCustomerInfoAction.request):
        draft.customerInfo.event = cloneDeep(initEvent)
        draft.customerInfo.event.IsLoading = true
        return draft
      case getType(actions.getCustomerInfoAction.success):
        response = action.payload
        draft.customerInfo.event.IsLoading = false
        draft.customerInfo.event.SubmitSuccess = true
        draft.customerInfo.data = response.data
        return draft
      case getType(actions.getCustomerInfoAction.failure):
        draft.customerInfo.event.IsLoading = false
        draft.customerInfo.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getListAction.request):
        draft.customerList.event = cloneDeep(initEvent)
        draft.customerList.event.IsLoading = true
        draft.customerList.data = {
          data: [],
          meta: cloneDeep(initMeta),
        }
        return draft
      case getType(actions.getListAction.success):
        response = action.payload
        draft.customerList.event.IsLoading = false
        draft.customerList.event.SubmitSuccess = true
        draft.customerList.data.data = response.data
        if (response.meta) {
          draft.customerList.data.meta = convertMetaData(response.meta)
        }
        return draft
      case getType(actions.getListAction.failure):
        draft.customerList.event.IsLoading = false
        draft.customerList.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getDetailAction.request):
        draft.customerDetail.event = cloneDeep(initEvent)
        draft.customerDetail.event.IsLoading = true
        draft.customerDetail.detail = undefined
        return draft
      case getType(actions.getDetailAction.success):
        response = action.payload
        draft.customerDetail.event.IsLoading = false
        draft.customerDetail.event.SubmitSuccess = true
        draft.customerDetail.detail = response.data
        return draft
      case getType(actions.getDetailAction.failure):
        draft.customerDetail.event.IsLoading = false
        draft.customerDetail.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.updateCustomerStatusAction.request):
      case getType(actions.updateAction.request):
        draft.taskEvent = cloneDeep(initEvent)
        draft.taskEvent.IsLoading = true
        return draft
      case getType(actions.updateCustomerStatusAction.success):
      case getType(actions.updateAction.success):
        draft.taskEvent.IsLoading = false
        draft.taskEvent.SubmitError = undefined
        draft.taskEvent.SubmitSuccess = true
        draft.taskEvent.MessageSuccess = 'บันทึกข้อมูลสำเร็จ'
        return draft
      case getType(actions.updateCustomerStatusAction.failure):
      case getType(actions.updateAction.failure):
        draft.taskEvent.IsLoading = false
        draft.taskEvent.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.countCustomerTabAction.request):
        return draft
      case getType(actions.countCustomerTabAction.success):
        draft.countData = action.payload.data
        return draft
      case getType(actions.countCustomerTabAction.failure):
        return draft

      case getType(actions.getSaleStatusAction.request):
        draft.saleStatus = []
        return draft
      case getType(actions.getSaleStatusAction.success):
        response = action.payload
        draft.saleStatus = response?.data || []
        return draft
      
      case getType(actions.getSaleTaskStatusAction.request):
        draft.saleTaskStatus = []
        return draft
      case getType(actions.getSaleTaskStatusAction.success):
        response = action.payload
        draft.saleTaskStatus = response?.data || []
        return draft

      case getType(actions.clearEventAction):
        draft.customerInfo.event = cloneDeep(initEvent)
        draft.actionEvent = cloneDeep(initEvent)
        draft.taskEvent = cloneDeep(initEvent)
        return draft

      default:
        return state
    }
  })
}
