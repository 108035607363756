import React from 'react'
import FormItem from './FormItem'
import LabelItem from './LabelItem'
import Label from 'Components/text/Label'
import { Uploader } from 'Components/upload'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'

type Props = {
  name: string
  token: string
  currentFile: string
  label?: string
  description?: string
  onUpdateData: (val: string, name: string) => void
  isError?: boolean
  isRequired?: boolean
  folder?: string
  listType?: any
  acceptType?: string
  withCropImage?: boolean
  aspect?: number
  isPrivate?: boolean
  readOnly?: boolean
  labelColor?: string
  maxFileSize?: number //KB
  shape?: 'rect' | 'round'
}

const UploadForm = (props: Props): any => {
  const {
    label = '',
    name,
    isError = false,
    onUpdateData,
    isRequired = false,
    token,
    currentFile = '',
    folder = 'common',
    listType,
    withCropImage,
    acceptType,
    aspect,
    description = '',
    isPrivate = false,
    readOnly = false,
    labelColor,
    maxFileSize,
    shape,
  } = props

  return (
    <FormItem label="" status={isRequired && isError ? 'error' : ''} error={`กรุณาระบุ${label}`}>
      {label && <LabelItem label={isRequired ? `${label}*` : label} color={labelColor} />}
      {description && (
        <>
          <br /> <StyledLabel color={labelColor}>{description}</StyledLabel>{' '}
        </>
      )}
      {(label || description) && <br />}
      <Uploader
        token={token}
        onUpdateFile={(file: string) => onUpdateData(file, name)}
        currentFile={currentFile}
        folder={folder}
        listType={listType}
        acceptType={acceptType}
        withCropImage={withCropImage}
        aspect={aspect}
        isPrivate={isPrivate}
        readOnly={readOnly}
        maxFileSize={maxFileSize}
        shape={shape}
      />
    </FormItem>
  )
}

const StyledLabel = styled(Label)`
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.regular};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.caption};
  opacity: 0.8;
`

export default UploadForm
