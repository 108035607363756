/** base-font-size: 16px **/
/** Thus,  1rem = 16px **/

type Pixel60 = '3.75rem'
type Pixel35 = '2.1875rem'
type Pixel28 = '1.75rem'
type Pixel26 = '1.625rem'
type Pixel24 = '1.5rem'
type Pixel22 = '1.375rem'
type Pixel20 = '1.25rem'
type Pixel18 = '1.125rem'
type Pixel16 = '1.00rem'
type Pixel14 = '0.875rem'
type Pixel11 = '0.75rem'

const h1: Pixel60 = '3.75rem' // 60px
const h2: Pixel35 = '2.1875rem' // 35px
const h2sm: Pixel28 = '1.75rem' // 28px
const h3: Pixel26 = '1.625rem' // 26px
const h4: Pixel24 = '1.5rem' // 24px
const header1: Pixel22 = '1.375rem' // 22px
const h5: Pixel20 = '1.25rem' // 20px

const body1: Pixel20 = '1.25rem' // 20px
const body2: Pixel18 = '1.125rem' // 18px
const body3: Pixel16 = '1.00rem' // 16px
const defaultSize: Pixel16 = '1.00rem' // 16px
const body4: Pixel14 = '0.875rem' // 14px
const body5: Pixel11 = '0.75rem' // 12px

const title1: Pixel22 = '1.375rem' // 22px
const title2: Pixel16 = '1.00rem' // 16px
const title3: Pixel20 = '1.25rem' // 20px

const caption: Pixel11 = '0.75rem' // 12px
const button: Pixel16 = '1.00rem' // 20px

const typography = {
  h1, // 60px
  h2, // 35px
  h2sm, // 28px
  h3, // 26px
  h4, // 24px
  h5, // 20px
  title1, // 22px
  title2, // 16px
  title3, // 20px
  body1, // 20px
  body2, // 18px
  body3, // 16px
  body4, // 14px
  body5, // 12px
  header1, // 22px
  caption, // 11px
  defaultSize, // 16px
  button,
}

export default typography
