import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import ClientLayout from 'Pages/Layout/ClientLayout'
import { selectChatActiveUser, selectChatUsers } from 'Store/chat/selector'
// import { LoadingView } from 'Components/modal'
import { Col, Image, Row, Space } from 'antd'
import { appConfig } from 'config'
import { io } from 'socket.io-client'
import UserChat from 'Pages/Chat/UserChat'
import { getLineLoginData, getSelfUser, saveAgentInfo, saveSelfUser } from 'helpers/account'
import { ReciveMessageSocket, SelfeMessageSocket, User, UserSocket } from 'Store/chat/types'
import {
  appendFriendAction,
  consumerHistoryChatAction,
  disconnectedFriendAction,
  friendListAction,
  onChangeAgent,
  onTypedAction,
  onTypingAction,
  recieveChatFromAction,
  selfChatFromAction,
} from 'Store/chat/actions'
import { findUserById } from 'Store/chat/function'
import { getAgentProfileAPI } from 'Store/user/api'
import { UserProfileByUUIDReq } from 'Store/user/types'
import { selectAgentProfile, selectEvent } from 'Store/user/selector'
import { AgentType } from 'helpers/constanst'
import ErrorModal from 'Components/modal/ErrorModal'
import { EventModal, LoadingView } from 'Components/modal'
import { clearEventAction } from 'Store/user/actions'
import { PrimaryButton } from 'Components/button'
import { MyTheme } from 'Components/common/Theme'
import styled from 'styled-components'
import { DisconnectOutlined } from '@ant-design/icons'
import { setTimeout } from 'timers'
import ReactGA from 'react-ga'
import { TempleteReq } from 'Store/setting-message/types'
import { getTemplateMessagetAPI } from 'Store/setting-message/api'
import { selectTemplateMessageInfo } from 'Store/setting-message/selector'
import { consoleLog } from 'helpers/utils'

type Props = {} & RouteComponentProps & ReduxProps
const ChatConsumer: React.FunctionComponent<Props> = props => {
  const { recentChatList, active_user, location, agentProfile, event, history, template } = props
  const selfUser = getSelfUser()
  const lineLogin = getLineLoginData()
  const dispatch = useDispatch()
  const [emitSocket, setEmitSocket] = useState(null as any)
  const [errorModal, setErrorModal] = useState(false as boolean)
  const [socketErrorModal, setSocketErrorModal] = useState(false as boolean)
  const [socketErrorMsg, setSocketErrorMsg] = useState('' as string)
  const [endModal, setEndModal] = useState(false as boolean)
  const [loading, setLoading] = useState(false as boolean)
  //init socket
  let socket = null as any
  const serverURL = appConfig.socketEndpoint
  socket = io(serverURL, {
    autoConnect: false,
    withCredentials: true,
    transports: ['websocket', 'polling'],
  }) as any
  const pathName = window.location.pathname
  const isOpd = window.location.hostname == appConfig.chatHostname
  consoleLog('isOpd', window.location.hostname, appConfig.chatHostname, isOpd)
  const chatType = agentProfile?.agent_type === AgentType.COPORATE ? 'broadcast' : 'broadcast'
  const defaultUUID = isOpd ? appConfig.defaultUUID : appConfig.chaiyoDefaultUUID
  let uuid = pathName.split('/')[1]
  const userId = pathName.split('/')[2]
  if (!uuid) {
    uuid = defaultUUID
  }
  const clsChaiyoOrOPD = isOpd ? 'chat-consumer-opd' : 'chat-consumer'

  const endMessage =
    template && template.length > 0
      ? template[0]
      : {
          type: 'text',
          value: isOpd
            ? 'ขอบคุณที่ให้ความสนใจในผลิตภัณฑ์ประกันของ ธ.ไทยพาณิชย์ นะคะ'
            : 'ขอบพระคุณเป็นอย่างสูง ที่ไว้วางใจในเงินไชโยได้ดูแลนะคะ',
        }

  const onGetAgentProfileInfo = (uuid: string): void => {
    const req: UserProfileByUUIDReq = {
      uuid: uuid,
    }
    dispatch(getAgentProfileAPI(req))
  }

  const onGetTemplate = (uuid: string): void => {
    const req: TempleteReq = {
      uuid: uuid,
    }
    dispatch(getTemplateMessagetAPI(req, true))
  }

  useEffect(() => {
    if (!agentProfile) return
    saveAgentInfo({ uuid })
    const suffix = isOpd ? '' : '-chaiyo'
    const lineUserID = (lineLogin?.userId || '') + suffix
    const consumerInfo = {
      userType: 'consumer',
      lastUserID: userId === 'guest' ? selfUser?.userID || null : lineUserID || selfUser?.userID,
      //lastUserID: '974284dfe15142bc834c0d848758589',
      agentID: uuid,
      chatType: chatType,
      username: lineLogin?.displayName,
      userImage: lineLogin?.pictureUrl,
      email: lineLogin?.email,
    }
    connectUser(consumerInfo)
    return () => socket.close()
  }, [window.location.pathname, agentProfile])

  useEffect(() => {
    if (uuid) {
      onGetAgentProfileInfo(uuid)
      onGetTemplate(uuid)
    }
  }, [window.location.pathname])

  useEffect(() => {
    if (userId === 'guest') return

    if (userId && !lineLogin?.userId) {
      history.push(`/${uuid}`)
    }
  }, [userId, lineLogin])

  useEffect(() => {
    setErrorModal(false)
    setSocketErrorModal(false)
    setSocketErrorMsg('')
  }, [])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  useEffect(() => {
    if (selfUser?.assignID === 'end-case') {
      setEndModal(true)
    }
  }, [selfUser])

  useEffect(() => {
    if (socket) {
      setEmitSocket(socket)
    }
  }, [window.location.pathname, agentProfile])

  const connectUser = async (user: any) => {
    setLoading(true)
    try {
      socket.auth = user
      await socket.connect()
      consoleLog('consumer connect socket', socket)
    } catch (error) {
      consoleLog('error ', error)
    }
  }

  socket.on('self profile', (user: UserSocket) => {
    saveSelfUser(user)
  })

  socket.on('friend lists', (users: UserSocket[]) => {
    consoleLog('friend lists', users)
    setLoading(false)
    dispatch(friendListAction({ users: users, uuid: '', myAssignID: selfUser?.assignID || '' }))
  })

  socket.on('user connected', (user: UserSocket) => {
    consoleLog('user connected', user)
    dispatch(appendFriendAction({ user: user }))
  })

  socket.on('user disconnected', (user: UserSocket) => {
    consoleLog('user disconnected', user)
    dispatch(disconnectedFriendAction({ user: user }))
  })

  socket.on('private message', (message: ReciveMessageSocket) => {
    dispatch(recieveChatFromAction({ recieveMessage: message, uuid: selfUser?.uuid || '' }))
  })

  socket.on('self message', (message: SelfeMessageSocket) => {
    dispatch(selfChatFromAction({ recieveMessage: message }))
  })

  socket.on('typing', (fromUser: User) => {
    dispatch(onTypingAction({ user: fromUser }))
  })

  socket.on('typed', (fromUser: User) => {
    dispatch(onTypedAction({ user: fromUser }))
  })

  socket.on('end conversation', () => {
    setEndModal(true)
  })

  const onTyping = (typing: boolean) => {
    const activeUser = findUserById(recentChatList, active_user)
    const user = activeUser && activeUser.userSocket
    if (typing) {
      emitSocket && emitSocket.emit('typing', { fromUser: selfUser, toUser: user })
    } else {
      emitSocket && emitSocket.emit('typed', { fromUser: selfUser, toUser: user })
    }
  }

  socket.on('connect_error', (err: any) => {
    setLoading(false)
    consoleLog('err', err)
    if (err?.data) {
      const { err_code, err_text } = err.data
      consoleLog(`connect_error : ${err.message}, ${err_code}, ${err_text}`)
      if (err_code == 'NOT_RECONNECT') {
        consoleLog('client not reconnect')
        socket.disconnect()
        //alert(`connect_error : ${err.message}, ${err_code}, ${err_text}`)
      }
      setSocketErrorModal(true)
      setSocketErrorMsg(err_text)
    } else {
      consoleLog(`connect_error : ${err?.message}`)
      setSocketErrorModal(true)
      setSocketErrorMsg(err?.message || 'ไม่สามารถเชื่อมต่อได้')
    }
  })

  socket.on('history message', (history: ReciveMessageSocket[]) => {
    consoleLog('history message', history)
    dispatch(consumerHistoryChatAction({ messages: history }))
  })

  socket.on('change agent', (id: string) => {
    dispatch(onChangeAgent({ id: id }))
  })

  const addMessage = (message: any, type: any) => {
    const activeUser = findUserById(recentChatList, active_user)
    const toUser = activeUser && activeUser.userSocket
    if (!toUser) return
    const content = {
      cBody: message,
      cType: type,
    }
    emitSocket.emit('private message', { toUser, content })
    onTyping(false)
  }

  const onRefreshChat = () => {
    emitSocket.emit('self reset', { consumer: selfUser })
    setTimeout(() => {
      //refresh()
      // removeSelfUser()
      // removeLineLoginData()
      history.push(`/${uuid}/?logout=true`)
    }, 200)
  }

  const onGetHistory = () => {
    emitSocket && emitSocket.emit('selected consumer', selfUser)
  }

  useEffect(() => {
    if (active_user) {
      onGetHistory()
    }
  }, [active_user])

  const myProps = {
    addMessage: addMessage,
    onTyping: onTyping,
  }

  const refresh = () => {
    window.location.reload()
    setEndModal(false)
  }

  const ErrorModalContent = (
    <ErrorModal
      isShow={errorModal}
      child={
        <div>
          <Text>ไม่พบข้อมูลตัวแทน</Text>
          <Text>กรุณาตรวจสอบ URL ของท่านให้ถูกต้อง</Text>
        </div>
      }
    />
  )

  const ErrorConnectSocket = (
    <ErrorModal
      isShow={socketErrorModal}
      child={
        <div>
          <Space direction="vertical" size={'large'}>
            <Text>{appConfig.chatErrorMsg}</Text>
            <PrimaryButton text={appConfig.refreshConsumerChat} onClick={refresh} loading={loading} />
          </Space>
        </div>
      }
    />
  )

  const EndModalContent = (
    <ErrorModal
      isShow={endModal}
      child={
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text>
              <DisconnectOutlined style={{ fontSize: '30px' }} />
            </Text>
            <Text>การสนทนาสิ้นสุดแล้ว</Text>
            {endMessage?.type === 'text' && <Text>{endMessage?.value || ''}</Text>}
            {endMessage?.type === 'image' && (
              <Image src={appConfig.assetCdnEndpoint + endMessage?.value || ''} />
            )}
            {endMessage?.type === 'link' && (
              <a href={endMessage?.data?.link} target="_blank" rel="noopener noreferrer">
                <Image src={appConfig.assetCdnEndpoint + endMessage?.data?.image || ''} preview={false} />
              </a>
            )}
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[16, 16]}>
              <Col>
                <PrimaryButton text={'เริ่มแชทใหม่อีกครั้ง'} btnType="Primary" onClick={onRefreshChat} />
              </Col>
            </Row>
          </Col>
        </Row>
      }
    />
  )

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onEventError = (): void => {
    setErrorModal(true)
    clearEvent()
  }
  const onEventSuccess = (): void => {
    setErrorModal(false)
    clearEvent()
  }

  return (
    <ClientLayout>
      <Row>
        {/* <Col md={12} xs={12}>
          <Chats recentChatList={recentChatList} />
        </Col> */}
        <Col md={24} xs={24} className={clsChaiyoOrOPD}>
          <UserChat {...myProps} {...props} />
        </Col>
      </Row>
      {ErrorModalContent}
      {EndModalContent}
      {ErrorConnectSocket}
      <LoadingView isShow={loading} />
      <EventModal event={[event]} enable={true} successCalback={onEventSuccess} errorCalback={onEventError} />
    </ClientLayout>
  )
}

const Text = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body2};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.primary};
  //color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  white-space: pre-line;
`

const mapStateToProps = (state: RootState): any => {
  return {
    recentChatList: selectChatUsers(state),
    active_user: selectChatActiveUser(state),
    agentProfile: selectAgentProfile(state),
    event: selectEvent(state),
    template: selectTemplateMessageInfo(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(ChatConsumer)
