import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import {
  RegisterReq,
  ActionRes,
  GetAddressReq,
  GetAddressRes,
  VerifyTokenReq,
  VerifyTokenRes,
  UpdatePasswordReq,
  UpdatePasswordRes,
  ResetPasswordReq,
  ResetPasswordRes,
} from './types'

export const registerAction = createAsyncAction(
  'REGISTER/REGISTER_REQ',
  'REGISTER/REGISTER_SUCCESS',
  'REGISTER/REGISTER_FAILURE',
)<RegisterReq, ActionRes, Error>()

export const getAddressAction = createAsyncAction(
  'REGISTER/GET_ADDRESS_REQ',
  'REGISTER/GET_ADDRESS_SUCCESS',
  'REGISTER/GET_ADDRESS_FAILURE',
)<GetAddressReq, GetAddressRes, Error>()

export const getVerifyTokenAction = createAsyncAction(
  'REGISTER/GET_TOKEN_REQ',
  'REGISTER/GET_TOKEN_SUCCESS',
  'REGISTER/GET_TOKEN_FAILURE',
)<VerifyTokenReq, VerifyTokenRes, Error>()

export const updatePasswordAction = createAsyncAction(
  'REGISTER/UPDATE_PASSWORD_REQ',
  'REGISTER/UPDATE_PASSWORD_SUCCESS',
  'REGISTER/UPDATE_PASSWORD_FAILURE',
)<UpdatePasswordReq, UpdatePasswordRes, Error>()

export const resetPasswordAction = createAsyncAction(
  'REGISTER/RESET_PASSWORD_REQ',
  'REGISTER/RESET_PASSWORD_SUCCESS',
  'REGISTER/RESET_PASSWORD_FAILURE',
)<ResetPasswordReq, ResetPasswordRes, Error>()

export const clearEventAction = createStandardAction('REGISTER/CLEAR_EVENT')<void>()
