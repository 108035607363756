const parseErrorMessage = (payload: any): string => {
  if (typeof payload === 'object') {
    if (payload.response?.data?.message) {
      const error = payload.response.data?.message
      return error
    }
    if (payload.response?.data?.error_debug) {
      const error = payload.response.data?.error_debug
      return error
    }

    if (payload?.response?.data?.status?.code) {
      const responseCode = payload.response.data.status.code
      const data = payload.response.data.message
      if (responseCode !== 200) {
        return data
      }
    }

    if (payload?.response?.data?.code !== 200) {
      return payload?.response?.data?.message
    }
  }

  if (typeof payload === 'object') {
    if (payload.response && payload.response.data && payload.response.data.ResponseData) {
      return payload.response.data.ResponseData
    }
  }

  if (payload && payload.message && typeof payload.message === 'string') {
    return payload.message
  }

  return 'Unhandled error'
}

export default parseErrorMessage
