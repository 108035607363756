import React from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import { Col, Layout, Menu, Row, Dropdown, Badge, Space, Input, Divider } from 'antd'
import { BellFilled, LogoutOutlined, MessageFilled, MoreOutlined, SearchOutlined } from '@ant-design/icons'
import { logoutAction } from 'Store/user/actions'
import { connect, useDispatch } from 'react-redux'
import theme, { MyTheme } from 'Components/common/Theme'
import RootState from 'typings/RootState'
import { selectUserProfile } from 'Store/user/selector'
import ProfileAvatar from 'Components/avatar/ProfileAvatar'
import { ActionButton } from 'Components/button'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { upperFirst } from 'lodash'
import { UserAgentType, UserType } from 'helpers/constanst'
import { selectChatUsers } from 'Store/chat/selector'
import { countUnreadMessage } from 'Store/chat/function'

const { Header } = Layout

type Props = ReduxProps & {
  withSerch?: boolean
}

const NavBar: React.FunctionComponent<Props> = props => {
  const { withSerch = false, userProfile, users } = props
  const isAgent = userProfile?.user_type === UserType.AGENT
  const breakpoint = useBreakpoint()
  const small = breakpoint.xs
  const unreadMessage = countUnreadMessage(users)
  const hideChatMenu = userProfile?.profile?.partner_type === UserAgentType.NOCHAT

  const dispatch = useDispatch()

  const onLogout = (): void => {
    dispatch(logoutAction())
  }

  const liveChatButton = !hideChatMenu ? (
    <Badge size="small" offset={[-5, 5]} count={unreadMessage}>
      <Link to="/live-chat">
        <ActionButton text="" icon={<MessageFilled />} label="Live chat" onClick={() => {}} />
      </Link>
    </Badge>
  ) : null

  const notiButton = (
    <Badge dot count={5} size="small" offset={[-5, 55]}>
      <ActionButton text="" icon={<BellFilled />} label="Notification" onClick={() => {}} />
    </Badge>
  )

  const logoutButton = <ActionButton text="" icon={<LogoutOutlined />} label="" onClick={() => {}} />

  const menu = (
    <MyMenu>
      <Menu.Item onClick={onLogout}>ออกจากระบบ</Menu.Item>
    </MyMenu>
  )

  const dropdownMobileMenu = (
    <MyMenu>
      {isAgent && !hideChatMenu && (
        <Menu.Item onClick={() => {}} icon={liveChatButton}>
          &nbsp;&nbsp;Live chat
        </Menu.Item>
      )}
      <Menu.Item onClick={onLogout} icon={logoutButton}>
        &nbsp;&nbsp;ออกจากระบบ
      </Menu.Item>
    </MyMenu>
  )

  const renderUserProfile = (menu: any) => {
    return (
      <Space>
        <OnlineBadge dot offset={[-5, 55]} color={theme.colors.success}>
          <ProfileAvatar size={45} name={userName} />
        </OnlineBadge>
        <UserInfo>
          <Space direction="vertical" align="start" size="small">
            <Name>{upperFirst(userName)}</Name>
            <Module>{upperFirst(userProfile?.user_type || '')}</Module>
          </Space>
        </UserInfo>
        <Dropdown overlay={menu} trigger={['click']}>
          <MenuDropdown onClick={e => e.preventDefault()}>
            <MoreOutlined />
          </MenuDropdown>
        </Dropdown>
      </Space>
    )
  }

  const desktopMenu = () => {
    return (
      <Col md={12} sm={4}>
        <Row justify="end" align="middle" gutter={24}>
          <Col>
            <Divider type="vertical" style={{ height: '40px' }} />
          </Col>
          {isAgent && (
            <Col>
              <Space size="large">{liveChatButton}</Space>
            </Col>
          )}
          <Col>{renderUserProfile(menu)}</Col>
        </Row>
      </Col>
    )
  }

  const mobileMenu = () => {
    return <Col sm={4}>{renderUserProfile(dropdownMobileMenu)}</Col>
  }

  const userName = userProfile?.profile?.name || userProfile?.email
  return (
    <HeaderArea className="header">
      <Row justify="space-between" align="middle" gutter={[8, 8]}>
        <Col sm={20} md={12}>
          {withSerch && (
            <Input bordered={false} placeholder="ค้นหา เลขทะเบียน หรือ ชื่อลูกค้า" prefix={<SearchIcon />} />
          )}
        </Col>
        {small ? mobileMenu() : desktopMenu()}
      </Row>
    </HeaderArea>
  )
}

const HeaderArea = styled(Header)`
  height: 80px;
  line-height: 80px;
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  margin-bottom: 2px;
  padding: 0 0 0 30px;
  .ant-menu-horizontal {
    border: none;
    background-color: #f37b30;
  }
  .ant-menu-item {
    margin: 0;
  }
  @media only screen and (max-width: 720px) {
    padding: 0 10px;
  }
`

const MenuDropdown = styled.div`
  padding: 0 20px;
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.drakGrey};
  cursor: pointer;
  border-left: 1px solid #ffffff6b;
`

const MyMenu = styled(Menu)`
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: 12px;
  }
`

const SearchIcon = styled(SearchOutlined)`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  margin-right: 19px;
  font-size: 20px;
  line-height: 1;
`

const Name = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.black};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.bold};
  line-height: 1.5 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`

const Module = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  line-height: 0 !important;
`

const UserInfo = styled.div`
  line-height: 0 !important;
`

const OnlineBadge = styled(Badge)`
  .ant-badge-dot {
    width: 10px;
    height: 10px;
  }
`

const mapStateToProps = (state: RootState): any => {
  return {
    userProfile: selectUserProfile(state),
    users: selectChatUsers(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps)(withRouter(NavBar))
