import { fork, all } from 'redux-saga/effects'
import commonSaga from 'Store/common/common.saga'
import userSaga from './user/saga'
import customerSaga from './customer/saga'
import chaiyoCustomerSaga from './chaiyo-customer/saga'

function* rootSaga() {
  yield all([fork(commonSaga), fork(userSaga), fork(customerSaga), fork(chaiyoCustomerSaga)])
}

export default rootSaga
