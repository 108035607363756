import React from 'react'
import FormItem from './FormItem'
import LabelItem from './LabelItem'
import TextArea from './TextArea'

type Props = {
  name: string
  label?: string
  placeHolder?: string
  onChange: (val: string, name: string) => void
  isError?: boolean
  value?: any
  isRequired?: boolean
  resProps?: object
  rows?: number
  labelColor?: string
}

const AppTextArea = (props: Props): any => {
  const {
    label = '',
    name,
    isError = false,
    onChange,
    placeHolder = '',
    value,
    isRequired = false,
    resProps = {},
    rows,
    labelColor,
  } = props

  return (
    <FormItem label="" status={isRequired && isError ? 'error' : ''} error={`กรุณาระบุ ${label}`}>
      {label && <LabelItem label={isRequired ? `${label}*` : label} color={labelColor} />}
      <TextArea
        rows={rows}
        placeholder={placeHolder || label}
        name={name}
        value={value}
        onChange={(val: string): void => onChange(val, name)}
        resProps={resProps}
      />
    </FormItem>
  )
}

export default AppTextArea
