import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import { AddressData, VerifyTokenData } from './types'

export const selectActionEvent = (state: RootState): Event => state.registerReducer.actionEvent

export const selectAddressData = (state: RootState): AddressData[] => state.registerReducer.addressList.data

export const selectAddressEvent = (state: RootState): Event => state.registerReducer.addressList.event

export const selectVerifyToken = (
  state: RootState,
): {
  data?: VerifyTokenData
  event: Event
} => state.registerReducer.verifyToken
