import React from 'react'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { Col, Row } from 'antd'

type Props = {}

const TermAndCond: React.FunctionComponent<Props> = props => {
  const {} = props

  return (
    <MyLayout>
      <Row justify="center">
        <Col>
          <Title>ข้อกำหนดและนโยบายการให้บริการ</Title>
        </Col>
      </Row>
      <TernAndConWrapper>
        ข้อก ำหนดและเงื่อนไขกำรใช้บริกำรอิเล็กทรอนิกส์ของธนาคาร
        ผู้ขอใช้บริการตกลงใช้บริการอิเล็กทรอนิกส์ของธนาคารตามที่ได้รับอนุมัติจากธนาคาร
        และตามรายละเอียดการให้บริการที่ธนาคารก าหนด โดยผู้ขอใช้บริการ ตกลงผูกพันตามข้อก าหนดและเงื่อนไข
        ดังต่อไปนี ้ <br />
        1. ค ำนิยำม <br />
        1.1 “เครื่องมือเพื่อกำรท ำรำยกำร” หมายความถึง เครื่องคอมพิวเตอร์ และ/หรือ โทรศัพท์มือถือ และ/หรือ
        แท็บเล็ต และ/หรือ เครื่องมืออื่น ตามที่ธนาคารก าหนดเพื่อการท ารายการ <br />
        1.2 “ธนำคำร” หมายความถึง ธนาคารไทยพาณิชย์ จ ากัด (มหาชน) และให้หมายความรวมถึงบุคคลที่ธนาคารมอบหมายด้วย{' '}
        <br />
        1.3 “บริกำรอิเล็กทรอนิกส์” หมายความถึง SCB Easy Net และ/หรือ SCB Easy Applicationและ/หรือ SCB Mobile
        Banking รวมถึงบริการ ทางอิเล็กทรอนิกส์ประเภทอื่นที่ธนาคารจะเปิ
        ดให้บริการแก่ผู้ขอใช้บริการเพิ่มเติมในภายหน้า
        <br /> 1.4 “บัญชีเงินฝำกของนิติบุคคล” หมายความถึง บัญชีเงินฝากออมทรัพย์
        และ/หรือบัญชีเดินสะพัดของนิติบุคคลที่มีสถานะปกติและเปิ ดไว้ กับธนาคาร และนิติบุคคลได้น ามาก
        าหนดใช้ในบริการ SCB Easy Application
        <br /> 1.5 “บัญชีผู้ขอใช้บริกำร” หมายความถึง บัญชีเงินฝากของผู้ขอใช้บริการที่มีอยู่กับธนาคาร และ/หรือ
        สถาบันการเงินอื่น และให้หมายความ รวมถึง บัตรเครดิต บัตรอิเล็กทรอนิกส์ วงเงินสินเชื่อ Speedy Cash บัญชี
        หรือรหัสใด ๆ ที่ธนาคารออกให้แก่ผู้ขอใช้บริการซึ่งสามารถใช้บริการได้ใน ลักษณะท านองเดียวกับบัญชีเงินฝาก
        และผู้ขอใช้บริการได้ก าหนดใช้บัญชีเงินฝาก บัตรเครดิต บัตรอิเล็กทรอนิกส์ วงเงินสินเชื่อ Speedy Cash
        บัญชี และ/หรือ รหัส ดังกล่าวในบริการอิเล็กทรอนิกส์ตามที่ธนาคารเปิ ดให้บริการด้วย <br />
        1.6 “บัตรเงินสด” หมายความถึง บัตรอิเล็กทรอนิกส์ประเภทบัตรบริการเงินด่วน (บัตรเอทีเอ็ม) และ/หรือ
        บัตรอิเล็กทรอนิกส์ประเภทบัตรเดบิต (บัตรเดบิต) และ/หรือ บัตรอิเล็กทรอนิกส์อื่นตามที่ธนาคารจะก
        าหนดเพิ่มเติมในภายหน้า
        <br /> 1.7 “รหัสประจ ำตัว” หมายความถึง Login Name หรือ Username, รหัสผ่าน (Password), PIN – Person
        Identification Number, ลายนิ ้วมือ, ใบหน้า, เทคโนโลยีทางชีวภาพ (Biometric),
        รหัสผ่านเพื่อใช้งานอุปกรณ์เพื่อการท ารายการ, รหัส OTP – One Time Password รวมถึง
        รหัสอื่นที่เกี่ยวข้องกับการเข้าใช้บริการอิเล็กทรอนิกส์ไม่ว่าจะเรียกด้วยชื่อใด ๆ และไม่ว่าจะเป็
        นรหัสที่ธนาคารเป็ นผู้ก าหนดให้หรือเป็ นรหัสที่ผู้ขอใช้ บริการเป็ นผู้ก าหนดเองก็ตาม
        <br /> 1.8 “รำยกำรช ำระค่ำสินค้ำ / บริกำร” หมายความถึง ค าสั่ง หรือรายการ หรือธุรกรรม ที่กระท
        าผ่านระบบบริการอิเล็กทรอนิกส์ เพื่อให้ ธนาคารหักเงินจากบัญชีผู้ขอใช้บริการ
        และโอนเข้าบัญชีเงินฝากของบุคคลใด ๆ เพื่อช าระค่าสินค้าหรือบริการตามรายการเรียกเก็บเงิน หรือใบแจ้งหนี ้
        ของผู้ขอใช้บริการและ/หรือบุคคลอื่น ไม่ว่ารายการเรียกเก็บเงิน หรือใบแจ้งหนี ้นั ้นจะได้จัดท
        าในรูปแบบใดก็ตาม
        <br /> 1.9 “รำยกำรโอนเงิน” หมายความถึง ค าสั่ง หรือรายการ หรือธุรกรรม ที่กระท
        าผ่านระบบบริการอิเล็กทรอนิกส์ เพื่อให้ธนาคารหักเงินจาก บัญชีผู้ขอใช้บริการและโอนเข้าบัญชีเงินฝากใด ๆ
      </TernAndConWrapper>
    </MyLayout>
  )
}

const MyLayout = styled.div``

const Title = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.h3};
  text-align: center;
`

const TernAndConWrapper = styled.div`
  max-height: calc(100vh - 300px);
  margin: 20px 0;
  border-radius: 2px;
  padding: 30px;
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.black};
  overflow-y: scroll;
  text-align: left;
`

export default TermAndCond
