import React from 'react'
import { AutoComplete, Input as InputAntd } from 'antd'
import { MyTheme } from 'Components/common/Theme'
import styled from 'styled-components'

export type OptionProps = {
  value: any
  label: any
}

type Props = {
  name?: string
  placeholder?: string
  clear?: boolean
  disabled?: boolean
  value?: string
  onSearch?: (text: string) => void
  onChange?: (val: string, name: string) => void
  resProps?: object
  options?: OptionProps[]
}

const InputAutocomplete = (props: Props) => {
  const {
    placeholder,
    clear = true,
    disabled = false,
    value = '',
    onSearch,
    onChange,
    resProps = {},
    options = [],
  } = props

  const onSearchEvent = (data: any): void => {
    if (onSearch) {
      onSearch(data)
    }
  }

  const onChangeEvent = (data: any): void => {
    if (onChange) {
      onChange(data, props.name || '')
    }
  }

  const css = { width: '100%', borderRadius: '5px' } as any
  if (disabled) {
    css.backgroundColor = '#DADAD9'
  } else {
    css.backgroundColor = '#FFF'
  }

  return (
    <InputStyle>
      <StyledTextField
        size="large"
        placeholder={placeholder}
        disabled={disabled}
        style={css}
        value={value}
        onSearch={onSearchEvent}
        onChange={onChangeEvent}
        allowClear={clear}
        {...resProps}
        options={options}
      />
    </InputStyle>
  )
}

const StyledTextField = styled(AutoComplete)`
  height: 45px;
  width: 100%;
  .ant-input {
    border-radius: 6px;
    //height: 40px;
    border: 2px solid rgba(197, 207, 213, 0.4);
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
    font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.regular};
    color: #000;
    padding: 10px 26px 10px 16px;
    border-width: 2px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`

const InputStyle = styled.div`
  .ant-input-affix-wrapper-disabled {
    background-color: #f5f5f5 !important;
  }
  left: 0 !important;
  top: 0px !important;
  right: 0 !important;

  .ant-select .ant-select-selector {
    background-color: transparent !important;
    border: 1px solide rgb(197 207 213) !important;
    height: 45px;
    border-radius: 5px;
  }
  .ant-select-single.ant-select-lg .ant-select-selector,
  .ant-select-selection-search-input {
    height: 45px !important;
  }
  .ant-select-selection-placeholder {
    line-height: 45px !important;
  }
`

export default InputAutocomplete
