import { put, select, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import * as chatAction from '../chat/actions'
import { getUserProfileAPI } from './api'
import { selectLoginData } from './selector'
import { consoleLog } from 'helpers/utils'

export function* getUserProfile() {
  try {
    const loginData = yield select(selectLoginData)
    yield put(getUserProfileAPI({}, loginData?.access_token || ''))
  } catch (e) {
    consoleLog(e)
  }
}

function* userSaga() {
  yield takeEvery(getType(actions.loginAction.success), getUserProfile)
}

export default userSaga
