import withAPIAction from 'helpers/withAPIAction'
import { LoginReq, StaffPermissionReq, UserProfileReq, UserProfileByUUIDReq } from './types'
import { loginAction, getUserProfileAction, getStaffPermissionAction, getAgentProfileAction } from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'

export const loginAPI = (data: LoginReq) => {
  return withAPIAction<LoginReq>({
    method: 'POST',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.login,
    data,
  })(loginAction)
}

export const staffPermissionAPI = (data: StaffPermissionReq, token: string, xmid: any) => {
  return withAPIAction<StaffPermissionReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.staffPermission,
    data,
    token,
    xmid,
  })(getStaffPermissionAction)
}

export const getUserProfileAPI = (data: UserProfileReq, token: string) => {
  return withAPIAction<UserProfileReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.getUserProfile,
    data,
    token,
  })(getUserProfileAction)
}

export const getAgentProfileAPI = (data: UserProfileByUUIDReq) => {
  const endPoint = APIPath.getAgentProfile.replace(':uuid', data.uuid.toString())
  return withAPIAction<UserProfileByUUIDReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
  })(getAgentProfileAction)
}
