import React from 'react'
import { Result } from 'antd'
import ErrorLayout from 'Pages/Layout/ErrorLayout'

type Props = {
  onClick?: () => void
}

const ErrorResult: React.FunctionComponent<Props> = ({ onClick }) => {
  const resultError = (): React.ReactNode => {
    return (
      <Result
        status="500"
        title="เกิดข้อผิดพลาด"
        subTitle="เนื่องจาก ลิงค์สำหรับสร้างรหัสผ่านของคุณไม่ถูกต้องหรือมีการใช้งานไปแล้ว"
      />
    )
  }

  return (
    <ErrorLayout btnText="กลับไปหน้าแรก" onClick={onClick}>
      {resultError()}
    </ErrorLayout>
  )
}

export default ErrorResult
