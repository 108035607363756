import Axios, { AxiosRequestConfig } from 'axios'
import { APIMethod } from './APIRequestSpec'
import { APIPath } from './APIPath'

// Maybe we will need this.
const formHeader = {
  headers: {
    'Content-Type': 'application/json',
  },
}

export function configWithToken(token?: string, xmid?: string): AxiosRequestConfig {
  const header = {
    'Content-Type': 'application/json',
    // Authorization: token != null ? 'Bearer ' + token : '',
  } as any

  if (token) {
    header['Authorization'] = token != null ? 'Bearer ' + token : ''
  }

  if (xmid) {
    header['X-Mid'] = xmid
  }

  return {
    headers: { ...header },
  }
}

const handleResp = (resp: any) => {
  return resp.data
}

const APIManager = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  call: <T = any, D = any>(
    baseUrl: string,
    path: APIPath,
    method: APIMethod,
    data?: D,
    config?: AxiosRequestConfig,
  ): Promise<T> => {
    const url = `${baseUrl}${path}`

    switch (method) {
      case 'GET':
        return Axios.get(url, { ...formHeader, ...config }).then(handleResp)

      case 'POST':
        return Axios.post(url, data, { ...formHeader, ...config }).then(handleResp)

      case 'PUT':
        return Axios.put(url, data, { ...formHeader, ...config }).then(handleResp)

      case 'DELETE':
        return Axios.delete(url).then(handleResp)

      case 'PATCH':
        return Axios.patch(url, data).then(handleResp)

      default:
        break
    }
    throw new Error('Method not found')
  },
}

export default APIManager
