import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import { UserProfile, LoginData } from './types'

export const selectEvent = (state: RootState): Event => state.userReducer.event

export const selectUserProfile = (state: RootState): UserProfile | undefined => state.userReducer.profile

export const selectLoginData = (state: RootState): LoginData | undefined => state.userReducer.loginData

export const selectStaffPermissionEvent = (state: RootState): Event => state.userReducer.staffPermissionEvent

export const selectAgentProfile = (state: RootState): UserProfile | undefined =>
  state.userReducer.agentProfile
