import { UserSocket } from 'Store/chat/types'
import { LineLoginData, LoginData, LoginRes, UserProfile } from 'Store/user/types'

export const saveLoginData = (data: LoginData) => {
  const key = 'login'
  const item = {
    ...data,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export const removeLoginData = () => {
  localStorage.removeItem('login')
}

export const getLoginData = () => {
  const data = localStorage.getItem('login') || undefined
  const result = (data && JSON.parse(data)) || undefined
  return {
    ...result,
  }
}

export const saveUserProfile = (userinfo: UserProfile) => {
  const key = 'userprofile'
  const item = {
    ...userinfo,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export const removeUserProfile = () => {
  localStorage.removeItem('userprofile')
}

export const getUserProfile = (): UserProfile => {
  const userinfo = localStorage.getItem('userprofile') || undefined
  const result = (userinfo && JSON.parse(userinfo)) || undefined
  return {
    ...result,
  }
}

export const clearStorage = () => {
  localStorage.clear()
}

export const saveStaffPermission = (permission: string[]) => {
  const key = 'staff-permission'
  const item = permission
  localStorage.setItem(key, JSON.stringify(item))
}

export const removeStaffPermission = () => {
  localStorage.removeItem('staff-permission')
}

export const getStaffPermission = (): string[] => {
  const userinfo = localStorage.getItem('staff-permission') || undefined
  const result = (userinfo && JSON.parse(userinfo)) || undefined
  return result
}

export const saveSelfUser = (userinfo: UserSocket) => {
  const key = 'selfuserV1'
  const item = {
    ...userinfo,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export const removeSelfUser = () => {
  localStorage.removeItem('selfuserV1')
}

export const getSelfUser = () => {
  const userinfo = localStorage.getItem('selfuserV1') || undefined
  const result = (userinfo && JSON.parse(userinfo)) || undefined
  return {
    ...result,
  }
}

export const saveLineLoginData = (data: LineLoginData) => {
  const key = 'line-login'
  const item = {
    ...data,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export const removeLineLoginData = () => {
  localStorage.removeItem('line-login')
}

export const getLineLoginData = (): LineLoginData | undefined => {
  const data = localStorage.getItem('line-login') || undefined
  const result = (data && JSON.parse(data)) || undefined
  return {
    ...result,
  }
}

export const saveCookieData = (accept: boolean) => {
  const key = 'accept-cookie'
  const item = accept
  localStorage.setItem(key, JSON.stringify(item))
}

export const removeCookieData = () => {
  localStorage.removeItem('accept-cookie')
}

export const getCookieData = (): boolean | undefined => {
  const data = localStorage.getItem('accept-cookie') || undefined
  const result = !!data || undefined
  return result
}

export const saveAgentInfo = (info: any) => {
  const key = 'agent_info_v1'
  const item = {
    ...info,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export const removeAgentInfo = () => {
  localStorage.removeItem('agent_info_v1')
}

export const getAgentInfo = () => {
  const userinfo = localStorage.getItem('agent_info_v1') || undefined
  const result = (userinfo && JSON.parse(userinfo)) || undefined
  return {
    ...result,
  }
}

export default {
  saveLoginData,
  removeLoginData,
  getLoginData,
  saveUserProfile,
  removeUserProfile,
  getUserProfile,
  clearStorage,
  saveStaffPermission,
  removeStaffPermission,
  getStaffPermission,

  saveSelfUser,
  removeSelfUser,
  getSelfUser,

  saveLineLoginData,
  removeLineLoginData,
  getLineLoginData,

  saveCookieData,
  removeCookieData,
  getCookieData,

  saveAgentInfo,
  removeAgentInfo,
  getAgentInfo,
}
