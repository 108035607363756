import React from 'react'
import { Select } from 'antd'
import { MyTheme } from 'Components/common/Theme'
import styled from 'styled-components'

const { Option } = Select

interface MenuProps {
  name: string
  value: string | number
}

type Props = {
  name: string
  label?: string
  placeHolder?: string
  onChange: (val: string, name: string) => void
  isError?: boolean
  value?: any
  isRequired?: boolean
  menu?: MenuProps[]
  allowClear?: boolean
  disabled?: boolean
}

const SelectItem = (props: Props) => {
  const {
    label = '',
    name,
    onChange,
    placeHolder = '',
    value,
    menu = [],
    allowClear = true,
    disabled = false,
  } = props

  return (
    <StyledSelect
      showSearch
      placeholder={placeHolder || `เลือก${label}`}
      optionFilterProp="children"
      onChange={(val: any): any => onChange(val, name)}
      filterOption={(input: any, option: any): any =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      value={value || undefined}
      allowClear={allowClear}
      disabled={disabled}
    >
      {menu.map((item: MenuProps, index: number) => (
        <Option value={item.value} key={index}>
          {item.name}
        </Option>
      ))}
    </StyledSelect>
  )
}

const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    width: 100%;
    height: 45px !important;
    border: 1px solid #c5cfd5 !important;
    border-radius: 6px !important;
    padding-top: 5px !important;
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
    font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.regular};
  }

  .ant-select-selection-search {
    padding-top: 5px;
  }
`

export default SelectItem
