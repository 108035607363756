import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import { ListData, Data } from './types'

export const selectListEvent = (state: RootState): Event => state.chatReportReducer.listEvent

export const selectList = (state: RootState): ListData => state.chatReportReducer.list

export const selectActionEvent = (state: RootState): Event => state.chatReportReducer.actionEvent

export const selectDetail = (state: RootState): Data | undefined => state.chatReportReducer.detail

export const selectDetailEvent = (state: RootState): Event => state.chatReportReducer.detailEvent
