import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import LoginLayout from 'Pages/Layout/LoginLayout'
import { RootState } from 'Store'
import { connect, useDispatch } from 'react-redux'
import { FormItem, AppInput } from 'Components/form'
import { clearEventAction } from 'Store/register/actions'
import EventModal from 'Components/modal/EventModal'
import { Checkbox, Col, Row, Space } from 'antd'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import ErrorResult from './ErrorResult'
import SuccessResult from './SuccessResult'
import InputPassword from 'Components/form/InputPassword'
import { passwordFormatConfig } from 'helpers/constanst'
import { isFormError, validatePasswordFormat } from 'helpers/format'
import { getVerifyTokenAPI, updatePasswordAPI } from 'Store/register/api'
import { selectVerifyToken, selectActionEvent } from 'Store/register/selector'
import { UpdatePasswordReq, VerifyTokenReq } from 'Store/register/types'

type Props = {} & RouteComponentProps<{
  token: string
}> &
  ReduxProps

const SetupPassword: React.FunctionComponent<Props> = props => {
  const { match, verifyToken, history, actionEvent } = props
  const { params } = match
  const { token } = params

  const verifyData = verifyToken?.data
  const email = verifyData?.email || ''

  const [password, setPassword] = useState<string>('')
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [requiredError, setRequiredError] = useState<string[]>([])
  const [passwordValidKey, setPasswordValidKey] = useState<string[]>([])

  const dispatch = useDispatch()

  const onVerifyToken = (): void => {
    const req: VerifyTokenReq = {
      token,
    }
    dispatch(getVerifyTokenAPI(req))
  }

  useEffect(() => {
    setShowSuccess(false)
  }, [])

  useEffect(() => {
    onVerifyToken()
  }, [token])

  useEffect(() => {
    const validKey = validatePasswordFormat(password)
    setPasswordValidKey(validKey)
  }, [password])

  useEffect(() => {
    if (requiredError.length > 0) {
      validateForm()
    }
  }, [password, confirmPassword])

  const validateForm = () => {
    const error = []

    if (passwordValidKey.length < 5) {
      error.push('password')
    }

    if (confirmPassword !== password) {
      error.push('confirmPassword')
    }
    setRequiredError([...error])
    return error.length > 0 ? false : true
  }

  const onUpdateNewPassword = (): void => {
    if (!validateForm()) return
    const req: UpdatePasswordReq = {
      token,
      password: password,
    }
    dispatch(updatePasswordAPI(req))
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onSuccess = (): void => {
    clearEvent()
    setShowSuccess(true)
  }

  const gotoHomePage = (): void => {
    history.push('/')
  }

  const validateInput = (isSubmit: boolean = false): void => {
    let errorInput = [] as string[]
    errorInput = []
    if (password.length < 6) {
      errorInput.push('password')
    }

    if (confirmPassword !== password) {
      errorInput.push('confirmPassword')
    }
    setRequiredError(errorInput)

    if (errorInput.length <= 0 && isSubmit) {
      onUpdateNewPassword()
    }
  }

  const isError = (name: string) => {
    return isFormError(requiredError, name)
  }

  const onUpdatePassword = (val: string): void => {
    setPassword(val)
    if (requiredError.length > 0) {
      validateInput()
    }
  }

  const onUpdateConfirmPassword = (val: string): void => {
    setConfirmPassword(val)
    if (requiredError.length > 0) {
      validateInput()
    }
  }

  const formResetPassword = (
    <LoginLayout
      title={'กรุณาตั้งรหัสผ่านใหม่ เพื่อเข้าใช้งาน '}
      onSubmit={onUpdateNewPassword}
      btnText="ยืนยัน"
    >
      <Row justify="start" gutter={[16, 1]}>
        <Col span={24}>
          <AppInput
            name="email"
            label="อีเมล"
            value={email}
            onChange={() => {}}
            resProps={{ disabled: true }}
          />
        </Col>
        <Col span={24}>
          <BoxTitle>รหัสผ่าน</BoxTitle>
          <FormItem label="" status={isError('password')} error="รูปแบบของรหัสผ่านไม่ถูกต้อง">
            <InputPassword
              type="password"
              value={password}
              placeholder="รหัสผ่าน"
              onChange={onUpdatePassword}
            />
          </FormItem>
        </Col>
        <Col span={24}>
          <BoxTitle>ยืนยันรหัสผ่าน</BoxTitle>
          <FormItem label="" status={isError('confirmPassword')} error="รหัสผ่านไม่ตรงกัน">
            <InputPassword
              type="password"
              value={confirmPassword}
              placeholder="ยืนยันรหัสผ่าน"
              onChange={onUpdateConfirmPassword}
            />
          </FormItem>
        </Col>
        <Col span={24}>
          <Description>
            <Space direction="vertical" size={'small'} align="start">
              รหัสผ่านต้องประกอบไปด้วย*
              {passwordFormatConfig.map((item, index) => {
                return (
                  <CircleCheckBox checked={passwordValidKey.includes(item.value) ? true : false} key={index}>
                    {item.name}
                  </CircleCheckBox>
                )
              })}
            </Space>
          </Description>
        </Col>
      </Row>
    </LoginLayout>
  )

  const errorResult = <ErrorResult onClick={gotoHomePage} />
  const successResult = <SuccessResult onClick={gotoHomePage} />

  if (showSuccess) {
    return successResult
  }

  return (
    <>
      <EventModal
        key="craete-new-password"
        event={[actionEvent]}
        enable={true}
        successCalback={onSuccess}
        errorCalback={clearEvent}
      />
      {verifyToken?.event.IsLoading === false
        ? email === ''
          ? errorResult
          : formResetPassword
        : formResetPassword}
    </>
  )
}

const BoxTitle = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.primary};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.black};
  text-align: left;
`

const Description = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.primary};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  margin-bottom: 10px;
  span {
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body5};
    color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  }
  text-align: left;
`

const CircleCheckBox = styled(Checkbox)`
  cursor: default;
  input {
    cursor: default;
  }
  span,
  label,
  .ant-checkbox-checked::after {
    border-radius: 10px;
  }
`

const Text = styled.p`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.regular};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
`

const mapStateToProps = (state: RootState): any => {
  return {
    //event: selectEvent(state),
    actionEvent: selectActionEvent(state),
    verifyToken: selectVerifyToken(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(SetupPassword)
