import React from 'react'
import { Drawer, Space } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { CloseOutlined } from '@ant-design/icons'

type Props = {
  children: React.ReactNode
  title?: React.ReactNode
  onCloseDrawer?: () => void
  visible: boolean
  footer?: React.ReactNode
  width?: string
  maskClosable?: boolean
}

const DetailDrawer: React.FunctionComponent<Props> = props => {
  const {
    children,
    title = '',
    onCloseDrawer = () => {},
    footer = null,
    visible,
    width = '50%',
    maskClosable = false,
  } = props
  const breakpoint = useBreakpoint()
  const xl = breakpoint.xl || false
  return (
    <Drawer
      title={title}
      width={xl ? width : '100%'}
      onClose={onCloseDrawer}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={footer}
      maskClosable={maskClosable}
      closable={false}
      extra={
        <Space>
          <CloseOutlined onClick={onCloseDrawer} />
        </Space>
      }
    >
      {children}
    </Drawer>
  )
}

export default DetailDrawer
