import withAPIAction from 'helpers/withAPIAction'
import { DetailReq, UpdateReq, TempleteReq } from './types'
import { getDetailAction, updateAction, getTemplateAction } from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'

export const getGeetingMessagetDetailAPI = (data: DetailReq, token: string) => {
  return withAPIAction<DetailReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.getGeetingMessage,
    data,
    token,
  })(getDetailAction)
}

export const updateGeetingMessageTaskAPI = (data: UpdateReq, token: string) => {
  return withAPIAction<UpdateReq>({
    method: 'POST',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.updateGeetingMessage,
    data,
    token,
  })(updateAction)
}

export const getTemplateMessagetAPI = (data: TempleteReq, isEndMessage: boolean = false) => {
  const path = isEndMessage ? APIPath.getEndMessage : APIPath.getTemplateMessage
  const endPoint = path.replace(':uuid', data.uuid.toString())
  return withAPIAction<TempleteReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
  })(getTemplateAction)
}
