import React from 'react'
import { Button, Space } from 'antd'
import { QRCode } from 'react-qrcode-logo'
import styled from 'styled-components'
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons'
import { consoleLog, handleCopyClick } from 'helpers/utils'

type Props = {
  uuid: string
  domain: string
  agentType: string
  title: string
  width?: number
  userGroup: string
  agentCode: string
}

const AgentQrCode = (props: Props) => {
  const { title = '', width = 259, uuid = '', domain, userGroup, agentCode } = props
  const downloadWithAgentCode = userGroup === 'chaiyo' && agentCode !== ''

  consoleLog('userGroup', userGroup)
  consoleLog('agentCode', agentCode)
  const qrCodeVal = `${domain}/${uuid}`

  const downloadQR = () => {
    const canvas = document.getElementById('react-qrcode-logo') as HTMLCanvasElement
    const img = canvas && canvas.toDataURL('image/png')
    const link = document.createElement('a')
    link.download = `${title}-QRcode.png`
    link.href = img
    link.click()
  }

  const downloadAgentQR = () => {
    const canvas = document.getElementById('myCanvas') as HTMLCanvasElement
    const context = canvas.getContext('2d')
    const qr = document.getElementById('react-qrcode-logo') as HTMLCanvasElement
    if (context) {
      context.fillStyle = '#fff'
      context.fillRect(0, 0, canvas.width, canvas.height)
      context.drawImage(qr, 0, 0, 500, 500)
      context.fillStyle = '#000'
      context.font = '45px Arial'
      context.textAlign = 'center'
      context.fillText(agentCode, 250, 560)
    }
    const img = canvas && canvas.toDataURL('image/png')
    const link = document.createElement('a')
    link.download = `${title}-QRcode.png`
    link.href = img
    link.click()
  }

  return (
    <QrWrapper>
      <HiddenQr className="qr-download">
        <canvas id="myCanvas" width="500" height="600" style={{ backgroundColor: '#fff' }}></canvas>
        <QRCode size={500} value={qrCodeVal} ecLevel="H" />
      </HiddenQr>
      <QRCode size={width} value={qrCodeVal} ecLevel="H" />
      <DownloadTxt>
        <Space>
          <Button
            type="dashed"
            onClick={() => handleCopyClick(qrCodeVal)}
            icon={<CopyOutlined />}
            size={'middle'}
          >
            Copy URL
          </Button>

          <Button
            type="dashed"
            onClick={downloadWithAgentCode ? downloadAgentQR : downloadQR}
            icon={<DownloadOutlined />}
            size={'middle'}
          >
            ดาวน์โหลด
          </Button>
        </Space>
      </DownloadTxt>
    </QrWrapper>
  )
}

const QrWrapper = styled.div`
  text-align: center;
`

const HiddenQr = styled.div`
  display: none;
`

const DownloadTxt = styled.div`
  margin-left: 10px;
  .ant-btn {
    font-size: 12px;
  }
`

export default AgentQrCode
