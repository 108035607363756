import { getType } from 'typesafe-actions'
import * as actions from './common.actions'
import { CommonAction, CommonState, Event, Meta } from './common.types'

export const initEvent = {
  IsLoading: false,
  SubmitError: '',
  SubmitSuccess: false,
  SubmitType: '',
  MessageSuccess: '',
} as Event

export const initMeta = {
  page: 0,
  pageCount: 0,
  perPage: 0,
  totalCount: 0,
} as Meta

const initialState: CommonState = {
  token: undefined,
}

export default function(state: CommonState = initialState, action: CommonAction): CommonState {
  switch (action.type) {
    case getType(actions.changeToken):
      return {
        ...state,
        token: action.payload,
      }

    default:
      return state
  }
}
