import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import {
  GetCustomerReq,
  ActionRes,
  GetCustomerRes,
  UpdateCustomerReq,
  UpdateCustomerRes,
  UpdateCustomerSocketReq,
  ListReq,
  ListRes,
  DetailReq,
  DetailRes,
  UpdateReq,
  UpdateStatusReq,
  CountReq,
  CountRes,
  BrandReq,
  BrandRes,
  BrandSlugReq,
  BrandSlugRes,
  LogReq,
  LogRes,
  SendToBranchReq,
  ChatTaskReq,
  ChatTaskRes,
  ChatTaskReplyReq,
  ChatTaskReplyRes,
} from './types'

export const getCustomerInfoAction = createAsyncAction(
  'CUSTOMER/GET_CUS_INFO_REQ',
  'CUSTOMER/GET_CUS_INFO_SUCCESS',
  'CUSTOMER/GET_CUS_INFO_FAILURE',
)<GetCustomerReq, GetCustomerRes, Error>()

export const updateCustomerAction = createAsyncAction(
  'CUSTOMER/UPDATE_CUS_INFO_REQ',
  'CUSTOMER/UPDATE_CUS_INFO_SUCCESS',
  'CUSTOMER/UPDATE_CUS_INFO_FAILURE',
)<UpdateCustomerReq, UpdateCustomerRes, Error>()

export const updateCustomerSocketAction = createAsyncAction(
  'CUSTOMER/UPDATE_CUS_SOCKET_REQ',
  'CUSTOMER/UPDATE_CUS_SOCKET_SUCCESS',
  'CUSTOMER/UPDATE_CUS_SOCKET_FAILURE',
)<UpdateCustomerSocketReq, ActionRes, Error>()

export const updateToBranchAction = createAsyncAction(
  'CUSTOMER/UPDATE_TO_BRANCH_REQ',
  'CUSTOMER/UPDATE_TO_BRANCH_SUCCESS',
  'CUSTOMER/UPDATE_TO_BRANCH_FAILURE',
)<SendToBranchReq, UpdateCustomerRes, Error>()

export const getListAction = createAsyncAction(
  'CUSTOMER/GET_CUSTOMER_LIST_REQ',
  'CUSTOMER/GET_CUSTOMER_LIST_SUCCESS',
  'CUSTOMER/GET_CUSTOMER_LIST_FAILURE',
)<ListReq, ListRes, Error>()

export const getDetailAction = createAsyncAction(
  'CUSTOMER/GET_CUSTOMER_DETAIL_REQ',
  'CUSTOMER/GET_CUSTOMER_DETAIL_SUCCESS',
  'CUSTOMER/GET_CUSTOMER_DETAIL_FAILURE',
)<DetailReq, DetailRes, Error>()

export const updateAction = createAsyncAction(
  'CUSTOMER/UPDATE_CUSTOMER_REQ',
  'CUSTOMER/UPDATE_CUSTOMER_SUCCESS',
  'CUSTOMER/UPDATE_CUSTOMER_FAILURE',
)<UpdateReq, ActionRes, Error>()

export const updateCustomerStatusAction = createAsyncAction(
  'CUSTOMER/UPDATE_CUSTOMER_STATUS_REQ',
  'CUSTOMER/UPDATE_CUSTOMER_STATUS_SUCCESS',
  'CUSTOMER/UPDATE_CUSTOMER_STATUS_FAILURE',
)<UpdateStatusReq, ActionRes, Error>()

export const countCustomerTabAction = createAsyncAction(
  'CUSTOMER/COUNT_CUSTOMER_TAB_REQ',
  'CUSTOMER/COUNT_CUSTOMER_TAB_SUCCESS',
  'CUSTOMER/COUNT_CUSTOMER_TAB_FAILURE',
)<CountReq, CountRes, Error>()

export const getBrandListAction = createAsyncAction(
  'CUSTOMER/GET_BRAND_LIST_REQ',
  'CUSTOMER/GET_BRAND_LIST_SUCCESS',
  'CUSTOMER/GET_BRAND_LIST_FAILURE',
)<BrandReq, BrandRes, Error>()

export const getBrandBySlugListAction = createAsyncAction(
  'CUSTOMER/GET_BRAND_BY_SLUG_LIST_REQ',
  'CUSTOMER/GET_BRAND_BY_SLUG_LIST_SUCCESS',
  'CUSTOMER/GET_BRAND_BY_SLUG_LIST_FAILURE',
)<BrandSlugReq, BrandSlugRes, Error>()

export const getTaskLogsAction = createAsyncAction(
  'CUSTOMER/GET_TASK_LOGS_REQ',
  'CUSTOMER/GET_TASK_LOGS_SUCCESS',
  'CUSTOMER/GET_TASK_LOGS_FAILURE',
)<LogReq, LogRes, Error>()

export const clearEventAction = createStandardAction('CUSTOMER/CLEAR_EVENT')<void>()

export const resetBrandDataAction = createStandardAction('CUSTOMER/RESET_BRAND_DATA')<void>()

export const getYearByModelDataAction = createStandardAction('CUSTOMER/GET_YEAR_DATA')<{
  model: string
}>()

export const updateChatTaskAction = createAsyncAction(
  'CUSTOMER/UPDATE_CHAT_TASK_REQ',
  'CUSTOMER/UPDATE_CHAT_TASK_SUCCESS',
  'CUSTOMER/UPDATE_CHAT_TASK_FAILURE',
)<ChatTaskReq, ChatTaskRes, Error>()

export const updateChatTaskReplyAction = createAsyncAction(
  'CUSTOMER/UPDATE_CHAT_TASK_REPLY_REQ',
  'CUSTOMER/UPDATE_CHAT_TASK_REPLY_SUCCESS',
  'CUSTOMER/UPDATE_CHAT_TASK_REPLY_FAILURE',
)<ChatTaskReplyReq, ChatTaskReplyRes, Error>()
