import React from 'react'
import { Cascader, Divider, Button } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'

type Props = {
  data: any
  handleChange?: (value: any, selectedOptions: any) => void
  onOpenEditCategory?: (open: boolean) => void
  readonly?: boolean
  categories: any[]
  canAddCategory?: boolean
  hasLastchild?: boolean
}

const ProductCategory = (props: Props) => {
  const {
    data,
    handleChange = () => {},
    readonly = false,
    categories = [],
    onOpenEditCategory = () => {},
    canAddCategory = false,
    hasLastchild = true,
  } = props

  const options = [] as any[]

  const renderChildren = (item: any) => {
    const options = [] as any[]
    if (item.child) {
      item.child.forEach((child: any) => {
        options.push({
          value: child.id,
          label: child.name,
          children: hasLastchild && child.child ? renderChildren(child) : [],
        })
      })
    }

    return options
  }

  categories.forEach((item: any) => {
    options.push({
      value: item.id,
      label: item.name,
      children: item.child ? renderChildren(item) : [],
    })
  })

  function filter(inputValue: any, path: any) {
    return path.some((option: any) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  //const val = data.group_id ? [data.group_id, data.category_id, data.sub_category_id] : undefined

  const dropdownRender = (menus: any, onOpen: any) => {
    return (
      <div>
        {menus}
        <Divider style={{ margin: 0 }} />
        {canAddCategory && (
          <div className="d-flex justify-content-center align-items-center pt-3 pb-3">
            <Button icon={<SettingOutlined />} className="ml-2" onClick={onOpen}>
              จัดการหมวดหมู่
            </Button>
          </div>
        )}
      </div>
    )
  }

  return (
    <StyledCascader>
      <Cascader
        value={data}
        style={{ width: '100%' }}
        options={options}
        dropdownRender={(menu: any) => dropdownRender(menu, () => onOpenEditCategory(true))}
        onChange={handleChange}
        placeholder="เลือก ประเภท / หมวดหมู่สินค้า"
        showSearch={{ filter }}
        changeOnSelect
        disabled={readonly}
      />
    </StyledCascader>
  )
}

const StyledCascader = styled.div`
  height: 45px;
  width: 100%;
  .ant-input {
    border-radius: 6px;
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
    font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.regular};
    color: #000;
    padding: 10px 26px 10px 16px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`

export default ProductCategory
