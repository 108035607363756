import React from 'react'
import { Result } from 'antd'
import ErrorLayout from 'Pages/Layout/ErrorLayout'

type Props = {
  onClick?: () => void
  email?: string
}

const SuccessResult: React.FunctionComponent<Props> = ({ onClick, email }) => {
  const resultError = (): React.ReactNode => {
    return (
      <Result
        status="success"
        title="ส่งอีเมลสำเร็จ"
        subTitle={`ระบบได้ส่งลิ้งค์สำหรับตั้งรหัสผ่านใหม่ไปที่อีเมลของคุณ ${email}`}
      />
    )
  }

  return (
    <ErrorLayout btnText="ไปหน้าเข้าสู่ระบบ" onClick={onClick}>
      {resultError()}
    </ErrorLayout>
  )
}

export default SuccessResult
