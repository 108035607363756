import withAPIAction from 'helpers/withAPIAction'
import {
  CaseDashbaordRes,
  CaseDashbaordReq,
  TeamDashbaordReq,
  BookCurrentReq,
  BookLogReq,
  BookCurrentRes,
} from './types'
import {
  getCaseDetailAction,
  updateAction,
  getTeamCaseDetailAction,
  getBookCurrentAction,
  getBookLogAction,
} from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'
import { getRequestString } from 'helpers/format'

export const getCaseDashboardtAPI = (data: CaseDashbaordReq, token: string) => {
  return withAPIAction<CaseDashbaordReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getCaseDashboard}?${getRequestString(data)}`,
    data,
    token,
  })(getCaseDetailAction)
}

export const getTeamCaseDashboardtAPI = (data: TeamDashbaordReq, token: string) => {
  return withAPIAction<TeamDashbaordReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getTeamCaseDashboard}?${getRequestString(data)}`,
    data,
    token,
  })(getTeamCaseDetailAction)
}

export const getBookLogDashboardtAPI = (data: BookLogReq, token: string) => {
  return withAPIAction<BookLogReq>({
    method: 'GET',
    baseUrl: appConfig.socketEndpoint,
    path: `${APIPath.getBookLog}?${getRequestString(data)}`,
    data,
    token,
  })(getBookLogAction)
}

export const getBookCurrentDashboardtAPI = (data: BookCurrentReq, token: string) => {
  return withAPIAction<BookCurrentReq>({
    method: 'GET',
    baseUrl: appConfig.socketEndpoint,
    path: `${APIPath.getBookCurrent}?${getRequestString(data)}`,
    data,
    token,
  })(getBookCurrentAction)
}
