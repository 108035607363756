import React from 'react'
import { Row, Col } from 'antd'

import PageNumber from './PageNumber'
import PageIndex from './PageIndex'
import styled from 'styled-components'

type Props = {
  totalRows?: number
  currentPage?: number
  onClickPage?: (page: number) => void
  onChangeNumber?: (number: number) => void
  perPage?: number
  changePerPage?: boolean
}

const Pagination: React.FunctionComponent<Props> = props => {
  const {
    currentPage = 1,
    totalRows = 0,
    perPage = 20,
    onChangeNumber = () => {},
    onClickPage = () => {},
    changePerPage = true,
  } = props
  return (
    <Row className="content-footer" gutter={[16, 16]} justify="space-between">
      <PaginationWrapper md={12} xs={24}>
        <PageNumber
          changePerPage={changePerPage}
          totalRows={totalRows}
          defaultValue={perPage}
          onChange={number => {
            onChangeNumber(number)
            onClickPage(1)
          }}
        />
      </PaginationWrapper>
      <PaginationWrapper md={12} xs={24}>
        <PageIndex
          currentPage={currentPage}
          totalRows={totalRows}
          perPage={perPage}
          onClick={page => onClickPage(page)}
        />
      </PaginationWrapper>
    </Row>
  )
}

export default Pagination

const PaginationWrapper = styled(Col)`
  margin-top: 10px;
`
