import React from 'react'
import styled from 'styled-components'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { Layout, Row, Col } from 'antd'
import { MyTheme } from 'Components/common/Theme'
import Title from 'Components/text/Title'
import Text from 'Components/text/Text'
import colors from 'Components/common/Theme/colors'
import { RoundButton } from 'Components/button'
import LogoImage from 'Components/logo/LogoImage'
import LinkText from 'Components/text/LinkText'
import { appConfig } from 'config'

type Props = {
  title?: string
  children: React.ReactNode
  btnText?: string
  onSubmit?: () => void
  isLogin?: boolean
} & RouteComponentProps

const MerchantsLayout: React.FunctionComponent<Props> = ({
  title = '',
  children,
  btnText = 'เข้าสู่ระบบ',
  onSubmit,
  isLogin = true,
}) => {
  return (
    <MyLayout>
      <MyRow align="middle" justify="center">
        <MyCol md={9} sm={22} xs={22}>
          <BtnAbsolute>
            <RoundButton text={btnText} onClick={onSubmit} />
          </BtnAbsolute>
          <MyTitle color={colors.white}>Chaiyo</MyTitle>
          <Text>{title}</Text>
          <Box>
            <LoginTitle>{appConfig?.loginTitle || 'AMP - ประกัน'}</LoginTitle>
            <ChildBox>{children}</ChildBox>
          </Box>
          <ForgotPassword>
            {isLogin ? (
              <Link to="/reset-password">
                <LinkText color={colors.white}>ลืมรหัสผ่าน</LinkText>
              </Link>
            ) : (
              <Link to="/">
                <LinkText color={colors.white}>กลับไปหน้าเข้าสู่ระบบ</LinkText>
              </Link>
            )}
          </ForgotPassword>
        </MyCol>
      </MyRow>
    </MyLayout>
  )
}

const MyLayout = styled(Layout)`
  background: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  position: fixed;
  overflow-y: scroll;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 20px;
  padding-bottom: 100px;
`

const MyRow = styled(Row)`
  height: 100vh;
`

const MyCol = styled(Col)`
  text-align: center;
`

const MyTitle = styled(Title)`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.white} !important;
`

const Box = styled.div`
  width: 100%;
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  border-radius: 14px;
  padding: 50px 36px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
`

const BtnAbsolute = styled.div`
  position: absolute;
  left: 30%;
  right: 30%;
  bottom: 50px;
`

const ChildBox = styled.div`
  margin-top: 30px;
`

const ForgotPassword = styled.div`
  margin-top: 50px;
`

const LoginTitle = styled.p`
  font-size: 30px;
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.bold};
`

export default withRouter(MerchantsLayout)
