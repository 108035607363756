if (typeof window === 'undefined') global.window = { location: { host: 'testhost' } }

const maps = {
  '{{url-prod}}': 'production',
  '{{url-dev}}': 'develop',
}

const host = window.location.host
const found = maps[host]

const env = found ? found : 'localhost'

export const config = window._env_ ? window._env_ : require(`./config.${env}`)
export const appConfig = config.appConfig
