import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { selectChatActiveUser, selectChatUsers } from 'Store/chat/selector'
import HomePageLayout from '../Layout/PageLayout'
import { Col, Row } from 'antd'
import UserChat from './UserChat'
import Chats from './Tabs/Chats'
import { Tabs } from 'antd'
import { getAssignUser, getUnAssignUser } from 'Store/chat/function'
import { getSelfUser } from 'helpers/account'
import FormDrawer from './FormDrawer'
import { activeUserAction, closeUserSidebarAction, openUserSidebarAction } from 'Store/chat/actions'
import { isMobileOnly } from 'react-device-detect'
import { appConfig } from 'config'
const { TabPane } = Tabs

const Chat = props => {
  const { users, addMessage, onTyping, onBookFriend, active_user } = props
  const selfUser = getSelfUser()

  const [showDrawer, setShowDrawer] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const assignUser = getAssignUser(users, selfUser)
  const unAssignUser = getUnAssignUser(users, selfUser)
  const dispatch = useDispatch()

  useEffect(() => {
    const firstAssignID = assignUser && assignUser.length > 0 ? assignUser[0].id : 0
    dispatch(activeUserAction({ userId: firstAssignID }))
  }, [])

  useEffect(() => {
    if (!isMobileOnly) {
      dispatch(openUserSidebarAction())
    }
  }, [active_user])

  useEffect(() => {
    if (isMobileOnly) {
      dispatch(closeUserSidebarAction())
    }
  }, [active_user])

  // useEffect(() => {
  //   const activeUser = findUserById(users, active_user)
  //   consoleLog('activeUser****', activeUser)
  //   if (activeUser?.userSocket?.assignID === '') {
  //     setActiveTab('2')
  //   } else {
  //     setActiveTab('1')
  //   }
  // }, [active_user, users])

  return (
    <HomePageLayout selected="live-chat" title="Live chat" label={``}>
      <Row>
        <Col lg={5} xs={24} sm={24}>
          <Tabs onChange={setActiveTab} activeKey={activeTab}>
            <TabPane tab={`มอบหมายให้ฉัน (${assignUser.length})`} key="1">
              <Chats recentChatList={assignUser} />
            </TabPane>
            <TabPane tab={`ทั้งหมด (${unAssignUser.length || 0})`} key="2">
              <Chats recentChatList={unAssignUser} />
            </TabPane>
            {/* <TabPane tab={`All (${users.length || 0})`} key="3">
              <Chats recentChatList={users} />
            </TabPane> */}
          </Tabs>
        </Col>
        <Col lg={19} xs={24} sm={24}>
          {active_user > 0 && (
            <UserChat
              recentChatList={users}
              addMessage={addMessage}
              isAgent={true}
              onTyping={onTyping}
              onBookFriend={onBookFriend}
              limitBook={assignUser.length >= appConfig.maxTask}
            />
          )}
        </Col>
      </Row>
      <FormDrawer visible={showDrawer} onCloseDrawer={() => setShowDrawer(false)} />
    </HomePageLayout>
  )
}

const mapStateToProps = state => {
  return {
    users: selectChatUsers(state),
    active_user: selectChatActiveUser(state),
  }
}

export default connect(mapStateToProps)(Chat)
