import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action } from './types'
import { initEvent, initMeta } from 'Store/common/common.reducer'
import parseErrorMessage from 'helpers/parseErrorMessage'
import { convertMetaData } from 'Store/common/common.function'

const initialState: State = {
  geetingMessageInfo: {
    event: cloneDeep(initEvent),
    data: [],
  },
  template: {
    event: cloneDeep(initEvent),
    data: [],
  },
  actionEvent: cloneDeep(initEvent),
}

export default function(state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.updateAction.request):
        draft.actionEvent = cloneDeep(initEvent)
        draft.actionEvent.IsLoading = true
        return draft
      case getType(actions.updateAction.success):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = undefined
        draft.actionEvent.SubmitSuccess = true
        draft.actionEvent.MessageSuccess = 'บันทึกข้อมูลสำเร็จ'
        return draft
      case getType(actions.updateAction.failure):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getDetailAction.request):
        draft.geetingMessageInfo.event = cloneDeep(initEvent)
        draft.geetingMessageInfo.event.IsLoading = true
        draft.geetingMessageInfo.data = []
        return draft
      case getType(actions.getDetailAction.success):
        response = action.payload
        draft.geetingMessageInfo.event.IsLoading = false
        draft.geetingMessageInfo.event.SubmitSuccess = true
        draft.geetingMessageInfo.data = response.data
        return draft
      case getType(actions.getDetailAction.failure):
        draft.geetingMessageInfo.event.IsLoading = false
        draft.geetingMessageInfo.event.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.getTemplateAction.request):
        draft.template.event = cloneDeep(initEvent)
        draft.template.event.IsLoading = true
        draft.template.data = []
        return draft
      case getType(actions.getTemplateAction.success):
        response = action.payload
        draft.template.event.IsLoading = false
        draft.template.event.SubmitSuccess = true
        draft.template.data = response.data
        return draft
      case getType(actions.getTemplateAction.failure):
        draft.template.event.IsLoading = false
        draft.template.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.clearEventAction):
        draft.geetingMessageInfo.event = cloneDeep(initEvent)
        draft.actionEvent = cloneDeep(initEvent)
        draft.template.event = cloneDeep(initEvent)
        return draft

      default:
        return state
    }
  })
}
