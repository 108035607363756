import React from 'react'
import styled from 'styled-components'
import theme, { MyTheme } from 'Components/common/Theme'

const getFontSize = (size?: string): string => {
  if (size === 'small') {
    return theme.typography.body4
  }

  if (size === 'medium') {
    return theme.typography.defaultSize
  }

  if (size === 'large') {
    return theme.typography.body2
  }

  return theme.typography.body4
}

type LabelSize = 'small' | 'medium' | 'large'
type FontType = 'Kanit' | 'KanitMedium'
type Props = {
  color?: string
  type?: FontType
  size?: LabelSize
  opacity?: string
}

const Label: React.FunctionComponent<Props> = ({
  color,
  size,
  type = 'Kanit',
  opacity = '0.6',
  children,
  ...props
}) => {
  const fontSize = getFontSize(size)

  if (color) {
    return (
      <ColoredText color={color} fontSize={fontSize} opacity={opacity} type={type} {...props}>
        {children}
      </ColoredText>
    )
  }

  return (
    <Text fontSize={fontSize} opacity={opacity} {...props} type={type}>
      {children}
    </Text>
  )
}

const Text = styled.span<{ fontSize: string; opacity: string; type: string }>`
  font-family: ${(props: { theme: MyTheme; type: string }): string =>
    props.type ? props.type : props.theme.fontFamily.regular};
  font-size: ${(props: { fontSize: string }): string => props.fontSize};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  opacity: ${(props: { opacity: string }): string => (props.opacity ? props.opacity : '0.6')};
  @media only screen and (min-width: 720px) {
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.defaultSize};
  }
`

const ColoredText = styled(Text)<{ color: string; fontSize: string }>`
  color: ${(props: { color: string }): string => props.color};
  font-size: ${(props: { fontSize: string }): string => props.fontSize};
  opacity: 1;
`

export default Label
