import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import HomePageLayout from 'Pages/Layout/PageLayout'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { Col, Row, Space } from 'antd'
import BoxWrapper from 'Components/common/Layout/BoxWrapper'
import styled from 'styled-components'
import { UploadForm } from 'Components/form'
import { InfoItem } from 'Components/text'
import { MyTheme } from 'Components/common/Theme'
import AgentQrCode from 'Components/utilities/AgentQrCode'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import { upperFirst } from 'lodash'
import { updateAgentImageAPI } from 'Store/agent/api'
import { UpdateAgentImageReq } from 'Store/agent/types'
import { getUserProfileAPI } from 'Store/user/api'
import { UserProfileReq } from 'Store/user/types'
import { clearEventAction } from 'Store/agent/actions'
import { EventModal } from 'Components/modal'
import { selectActionEvent } from 'Store/agent/selector'
import { appConfig } from 'config'
import { menuChaiyoUserAgentType } from 'helpers/constanst'
import { getMenuValue } from 'helpers/format'
// import { LoadingView } from 'Components/modal'

type Props = {} & RouteComponentProps & ReduxProps

const Profile: React.FunctionComponent<Props> = props => {
  const { userProfile, loginData, actionEvent } = props
  const uuid = userProfile?.uuid || ''
  const [logo, setLogo] = useState<string>('')
  const dispatch = useDispatch()

  const onUpdateAgentImage = (file: string): void => {
    const req: UpdateAgentImageReq = {
      image: file,
    }
    dispatch(updateAgentImageAPI(req, loginData?.access_token || ''))
  }

  const onGetProfileInfo = (): void => {
    const req: UserProfileReq = {}
    dispatch(getUserProfileAPI(req, loginData?.access_token || ''))
  }

  const onUpdateImage = (file: string) => {
    setLogo(file)
    if (file) {
      onUpdateAgentImage(file)
    }
  }

  useEffect(() => {
    setLogo('')
    onGetProfileInfo()
  }, [])

  useEffect(() => {
    if (userProfile?.agent_image) {
      setLogo(userProfile?.agent_image)
    }
  }, [userProfile])

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onSuccess = (): void => {
    clearEvent()
    onGetProfileInfo()
  }

  const renderInfo = (title: string, value: any, span: number = 15) => {
    return <InfoItem title={title} value={value} span={span} />
  }

  // useEffect(() => {
  //   setLogo(userProfile?.agent_image || '')
  // }, [userProfile])
  return (
    <HomePageLayout selected="profile" title="บัญชีของฉัน" label={''}>
      <EventModal
        key="profile"
        event={[actionEvent]}
        enable={true}
        successCalback={onSuccess}
        errorCalback={clearEvent}
      />
      <Row gutter={[16, 16]}>
        <Col md={12} xs={24}>
          <BoxWrapper title="ข้อมูล Agent ">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row justify="center" align="middle" gutter={[16, 16]}>
                  <Col>
                    <AvartarWrapper>
                      <Center>
                        <UploadForm
                          token={loginData?.access_token || ''}
                          onUpdateData={onUpdateImage}
                          currentFile={logo}
                          folder="agent-logo"
                          listType="picture-card"
                          acceptType="image/png,image/jpeg"
                          isPrivate={true}
                          name="logo"
                          description=""
                          isRequired={false}
                          isError={false}
                          label={''}
                          withCropImage={true}
                          aspect={1}
                          //maxFileSize={100}
                          readOnly={userProfile?.level != 0}
                          shape="round"
                        />
                      </Center>
                      <Space direction="vertical" size="small">
                        <Title>{userProfile?.agent_name || '-'} </Title>
                        <InfoTitle>ประเภท {upperFirst(userProfile?.user_type || '')} </InfoTitle>
                      </Space>
                    </AvartarWrapper>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                {renderInfo('ชื่อแสดงผลหน้าสนทนากับลูกค้า', `${userProfile?.agent_name || '-'}`)}
                {renderInfo('Agent Level', `${userProfile?.level}`)}
                {userProfile?.profile?.agent_code &&
                  renderInfo('รหัสตัวแทน', `${userProfile?.profile?.agent_code}`)}
                {userProfile?.profile?.partner_type &&
                  renderInfo(
                    'ประเภทตัวแทน',
                    getMenuValue(menuChaiyoUserAgentType, userProfile?.profile?.partner_type),
                  )}
                {userProfile?.profile?.employee_code &&
                  renderInfo('รหัสพนักงาน', `${userProfile?.profile?.employee_code}`)}

                {renderInfo(
                  'ชื่อ',
                  `${userProfile?.profile?.first_name} ${userProfile?.profile?.last_name} `,
                )}
                {renderInfo('เบอร์โทรศัพท์', userProfile?.profile?.mobile)}
                {renderInfo('อีเมล', userProfile?.email)}
              </Col>
            </Row>
          </BoxWrapper>
        </Col>
        <Col md={12} xs={24}>
          <BoxWrapper title="QR Code ของฉัน">
            <Center>
              <AgentQrCode
                title={uuid}
                uuid={uuid}
                agentType={userProfile?.parent?.agent_type || ''}
                width={200}
                domain={userProfile?.domain || appConfig.chatPublicEndpint}
                userGroup={userProfile?.user_group || ''}
                agentCode={userProfile?.profile?.agent_code || ''}
              />
            </Center>
          </BoxWrapper>
        </Col>
      </Row>
    </HomePageLayout>
  )
}

const AvartarWrapper = styled.div`
  text-align: center;
  .ant-upload.ant-upload-select-picture-card,
  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    border-radius: 50%;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-btn-primary,
  .ant-btn {
    border-radius: 10px;
    box-shadow: none;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: cover;
  }
`

const Center = styled.div`
  width: max-content;
  margin: 0 auto;
`

const Title = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.primary};
  //color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
`

const InfoTitle = styled.span`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
`

const mapStateToProps = (state: RootState) => {
  return {
    actionEvent: selectActionEvent(state),
    userProfile: selectUserProfile(state),
    loginData: selectLoginData(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(Profile)
