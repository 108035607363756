import { LoadingOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import { MyTheme } from 'Components/common/Theme'
import { appConfig } from 'config'
import { upperFirst } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import Logo from '../../assets/img/logo.png'

type Props = {
  size?: number
  logo?: string
  name?: string
  isFullName?: boolean
}

const ProfileAvatar = (props: Props) => {
  const { size = 25, logo = '', name = '', isFullName = false } = props
  const isLineProfile = logo.includes('profile.line-scdn')
  let src = appConfig.assetCdnEndpoint + logo
  if (isLineProfile) {
    src = logo
  }

  if (logo) {
    return <StyleAvatar size={size} src={src}></StyleAvatar>
  }

  if (!name && !logo) {
    return <StyleAvatar size={size} icon={<LoadingOutlined />}></StyleAvatar>
  }

  return (
    <StyleAvatar size={size}>
      {name && isFullName ? upperFirst(name) : upperFirst(name.charAt(0) || '')}
    </StyleAvatar>
  )
}

const StyleAvatar = styled(Avatar)`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
`

export default ProfileAvatar
