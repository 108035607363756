import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { HelloResponse, HelloRequest } from './example.types'

export const sampleExampleAction = createStandardAction('exampleAction')<void>()

export const sampleAsyncAction = createAsyncAction('HELLO_REQUEST', 'HELLO_SUCCESS', 'HELLO_FAILURE')<
  HelloRequest,
  HelloResponse,
  Error
>()
