import React, { Component } from 'react'
import { Input, InputGroup } from 'reactstrap'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'

//simplebar
import SimpleBar from 'simplebar-react'

//actions
import { activeUserAction } from 'Store/chat/actions'

import { setconversationNameInOpenChatAction } from 'Store/layout/actions'

//components
import OnlineUsers from './OnlineUsers'
import { selectChatActiveUser, selectChatContact } from 'Store/chat/selector'
import { ProfileAvatar } from 'Components/avatar'
import { formatDateTimeTh, formatDateWithoutTime, formatTimeTh, isToday } from 'helpers/format'

class Chats extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchChat: '',
      recentChatList: this.props.recentChatList,
    }
    this.handleChange = this.handleChange.bind(this)
    this.openUserChat = this.openUserChat.bind(this)
  }

  componentDidMount() {
    // const li = document.getElementById('conversation' + this.props.active_user)
    // if (li) {
    //   li.classList.add('active')
    // }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        recentChatList: this.props.recentChatList,
      })
    }
  }

  componentDidUpdate(nextProps) {
    if (this.props.recentChatList !== nextProps.recentChatList) {
      this.setState({
        recentChatList: nextProps.recentChatList,
      })
    }
  }

  handleChange(e) {
    this.setState({ searchChat: e.target.value })
    const search = e.target.value
    const conversation = this.state.recentChatList
    const filteredArray = []

    //find conversation name from array
    for (let i = 0; i < conversation.length; i++) {
      if (
        conversation[i].name.toLowerCase().includes(search) ||
        conversation[i].name.toUpperCase().includes(search)
      )
        filteredArray.push(conversation[i])
    }

    //set filtered items to state
    this.setState({ recentChatList: filteredArray })

    //if input value is blanck then assign whole recent chatlist to array
    if (search === '') this.setState({ recentChatList: this.props.recentChatList })
  }

  openUserChat(e, chat) {
    e.preventDefault()

    //find index of current chat in array
    const id = chat.id
    //const index = this.props.recentChatList.indexOf(chat)

    // set activeUser
    this.props.activeUserAction({ userId: id })

    let chatList = document.getElementById('chat-list')
    let clickedItem = e.target
    let currentli = null

    if (chatList) {
      let li = chatList.getElementsByTagName('li')
      //remove coversation user
      for (let i = 0; i < li.length; ++i) {
        if (li[i].classList.contains('active')) {
          li[i].classList.remove('active')
        }
      }
      //find clicked coversation user
      for (let k = 0; k < li.length; ++k) {
        if (li[k].contains(clickedItem)) {
          currentli = li[k]
          break
        }
      }
    }

    //activation of clicked coversation user
    if (currentli) {
      currentli.classList.add('active')
    }

    const userChat = document.getElementsByClassName('user-chat')
    if (userChat) {
      userChat[0]?.classList.add('user-chat-show')
    }

    //removes unread badge if user clicks
    const unread = document.getElementById('unRead' + chat.id)
    // if (unread) {
    //   unread.style.display = 'none'
    // }
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div className="px-4 pt-4">
            {/* <h4 className="mb-4">Chats</h4> */}
            {/* <div className="search-box chat-search-box">
              <InputGroup size="lg" className="mb-3 rounded-lg">
                <span className="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
                  <i className="ri-search-line search-icon font-size-18"></i>
                </span>
                <Input
                  type="text"
                  value={this.state.searchChat}
                  onChange={e => this.handleChange(e)}
                  className="form-control bg-light"
                  placeholder="Search messages or users"
                />
              </InputGroup>
            </div> */}
            {/* Search Box */}
          </div>

          {/* online users */}
          {/* <OnlineUsers /> */}

          {/* Start chat-message-list  */}
          <div className="pe-2">
            <h5 className="mb-3 px-0 font-size-16">Recent</h5>
            <SimpleBar style={{ maxHeight: '100%' }} className="chat-message-list">
              <ul className="list-unstyled chat-list chat-user-list" id="chat-list">
                {this.props.recentChatList.map((chat, key) => {
                  const today = isToday(chat.messages[chat.messages.length - 1]?.time)
                  const time = today
                    ? formatTimeTh(chat.messages[chat.messages.length - 1]?.time)
                    : formatDateWithoutTime(chat.messages[chat.messages.length - 1]?.time)
                  
                  return (
                    <li
                      key={key}
                      id={'conversation' + key}
                      className={
                        chat.unRead
                          ? 'unread'
                          : chat.isTyping
                          ? 'typing'
                          : chat.id === this.props.active_user
                          ? 'active'
                          : ''
                      }
                    >
                      <Link to="#" onClick={e => this.openUserChat(e, chat)}>
                        <div className="d-flex">
                          {chat.profilePicture === 'Null' ? (
                            <div className={'chat-user-img ' + chat.status + ' align-self-center me-3 ms-0'}>
                              <div className="avatar-xs">
                                <span className="avatar-title rounded-circle bg-soft-primary text-primary">
                                  {chat.name.charAt(0)}
                                </span>
                              </div>
                              {chat.status && <span className="user-status"></span>}
                            </div>
                          ) : (
                            <div className={'chat-user-img ' + chat.status + ' align-self-center me-3 ms-0'}>
                              {/* <img
                      src={chat.profilePicture}
                      className="rounded-circle avatar-xs"
                      alt="chatvia"
                    /> */}
                              <ProfileAvatar
                                size={45}
                                name={chat.name}
                                isFullName={false}
                                logo={chat?.profilePicture}
                              />
                              {chat.status && <span className="user-status"></span>}
                            </div>
                          )}

                          <div className="flex-1 overflow-hidden">
                            <h5 className="text-truncate font-size-15 mb-1">{chat.name}</h5>
                            <p className="chat-user-message text-truncate mb-0">
                              {chat.isTyping ? (
                                <>
                                  typing
                                  <span className="animate-typing">
                                    <span className="dot ms-1"></span>
                                    <span className="dot ms-1"></span>
                                    <span className="dot ms-1"></span>
                                  </span>
                                </>
                              ) : (
                                <>
                                  {chat.messages &&
                                  chat.messages.length > 0 &&
                                  chat.messages[chat.messages.length - 1].isImageMessage === true ? (
                                    <i className="ri-image-fill align-middle me-1"></i>
                                  ) : null}
                                  {chat.messages &&
                                  chat.messages.length > 0 &&
                                  chat.messages[chat.messages.length - 1].isFileMessage === true ? (
                                    <i className="ri-file-text-fill align-middle me-1"></i>
                                  ) : null}
                                  {chat.messages && chat.messages.length > 0
                                    ? chat.messages[chat.messages.length - 1].message
                                    : null}
                                </>
                              )}
                            </p>
                          </div>
                          <div className="font-size-11">
                            {chat.messages && chat.messages.length > 0 ? time : null}
                          </div>
                          {chat.unRead === 0 ? null : (
                            <div className="unread-message" id={'unRead' + chat.id}>
                              <span className="badge badge-soft-danger rounded-pill">
                                {chat.messages && chat.messages.length > 0
                                  ? chat.unRead >= 20
                                    ? chat.unRead + '+'
                                    : chat.unRead
                                  : ''}
                              </span>
                            </div>
                          )}
                        </div>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </SimpleBar>
          </div>
          {/* End chat-message-list */}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    active_user: selectChatActiveUser(state),
  }
}

export default connect(mapStateToProps, { setconversationNameInOpenChatAction, activeUserAction })(Chats)
