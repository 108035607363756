import React from 'react'
import { Result } from 'antd'
import ErrorLayout from 'Pages/Layout/ErrorLayout'

type Props = {
  onClick?: () => void
}

const SuccessResult: React.FunctionComponent<Props> = ({ onClick }) => {
  const resultError = (): React.ReactNode => {
    return (
      <Result status="success" title="ตั้งรหัสผ่านสำเร็จ" subTitle="ไปหน้าเข้าสู่ระบบเพื่อเริ่มใช้งาน " />
    )
  }

  return (
    <ErrorLayout btnText="ไปหน้าเข้าสู่ระบบ" onClick={onClick}>
      {resultError()}
    </ErrorLayout>
  )
}

export default SuccessResult
