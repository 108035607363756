import withAPIAction from 'helpers/withAPIAction'
import { RegisterReq, GetAddressReq, VerifyTokenReq, UpdatePasswordReq, ResetPasswordReq } from './types'
import {
  registerAction,
  getAddressAction,
  getVerifyTokenAction,
  updatePasswordAction,
  resetPasswordAction,
} from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'

export const registerAPI = (data: RegisterReq) => {
  return withAPIAction<RegisterReq>({
    method: 'POST',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.register,
    data,
  })(registerAction)
}

export const getAddressAPI = (data: GetAddressReq) => {
  const endPoint = APIPath.getAddress.replace(':postcode', data.postcode.toString())
  return withAPIAction<GetAddressReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
  })(getAddressAction)
}

export const getVerifyTokenAPI = (data: VerifyTokenReq) => {
  const endPoint = APIPath.verifyToken.replace(':token', data.token.toString())
  return withAPIAction<VerifyTokenReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
  })(getVerifyTokenAction)
}

export const updatePasswordAPI = (data: UpdatePasswordReq) => {
  const endPoint = APIPath.updatePassword.replace(':token', data.token.toString())
  return withAPIAction<UpdatePasswordReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
  })(updatePasswordAction)
}

export const resetPasswordAPI = (data: ResetPasswordReq) => {
  return withAPIAction<ResetPasswordReq>({
    method: 'POST',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.resetPassword,
    data,
  })(resetPasswordAction)
}
