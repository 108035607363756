import { CreateMapping } from 'Store/common/common.types'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import { ListReq, ListRes, DetailReq, DetailRes } from './types'

export const getListAction = createAsyncAction(
  'CHAT_REPORT/GET_CHAT_REPORT_LIST_REQ',
  'CHAT_REPORT/GET_CHAT_REPORT_LIST_SUCCESS',
  'CHAT_REPORT/GET_CHAT_REPORT_LIST_FAILURE',
)<ListReq, ListRes, Error>()

export const getDetailAction = createAsyncAction(
  'CHAT_REPORT/GET_CHAT_REPORT_DETAIL_REQ',
  'CHAT_REPORT/GET_CHAT_REPORT_DETAIL_SUCCESS',
  'CHAT_REPORT/GET_CHAT_REPORT_DETAIL_FAILURE',
)<DetailReq, DetailRes, Error>()

export const clearEventAction = createStandardAction('CHAT_REPORT/CLEAR_EVENT')<void>()
