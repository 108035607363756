import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

interface Props {
  text: any
  loading?: boolean
  disabled?: boolean
  onClick?: () => void
  btnType?: 'Primary' | 'Primary2' | 'Cancel' | 'Approve' | 'Reject'
  icon?: any
  size?: SizeType
}

const PrimaryButton: React.FC<Props> = props => {
  const {
    text,
    loading = false,
    disabled = false,
    onClick,
    btnType = 'Primary',
    icon,
    size = 'large',
  } = props

  const button = (
    <Button
      type="default"
      className="btn"
      icon={icon}
      size={size}
      loading={loading}
      disabled={disabled}
      onClick={evt => {
        if (!onClick) return
        evt.preventDefault()
        onClick()
      }}
    >
      {text}
    </Button>
  )

  switch (btnType) {
    case 'Primary':
      return <DefaultButton>{button}</DefaultButton>
    case 'Cancel':
      return <CancelButton>{button}</CancelButton>
    case 'Approve':
      return <ApproveButton>{button}</ApproveButton>
    case 'Reject':
      return <RejectButton>{button}</RejectButton>
    case 'Primary2':
      return <PrimaryButton2>{button}</PrimaryButton2>
    default:
      return <DefaultButton>{button}</DefaultButton>
  }
}

const ButtonSection = styled.div`
  .btn,
  .btn:hover,
  .btn:focus {
    text-align: center;
    width: 100%;
    &.ant-btn-lg {
      height: 45px;
    }
    border-radius: 5px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.button};
    font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.primary};
    color: #fff;
    cursor: pointer;

    &.ant-btn-sm {
      font-size: 12px;
      padding: 0 12px;
    }
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus {
    color: #fff;
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.disable};
    border: none;
    cursor: not-allowed;
  }

  .ant-btn:focus,
  .ant-btn:hover {
    color: #fff;
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  }
`

const DefaultButton = styled(ButtonSection)`
  .btn,
  .btn:hover,
  .btn:focus {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
    border: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  }
  .ant-btn:focus,
  .ant-btn:hover {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  }
`

const CancelButton = styled(ButtonSection)`
  .btn,
  .btn:hover,
  .btn:focus {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
    border: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  }
  .ant-btn:focus,
  .ant-btn:hover {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  }
  .ant-btn[disabled] {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  }
`

const RejectButton = styled(ButtonSection)`
  .btn,
  .btn:hover,
  .btn:focus {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.error};
    border: ${(props: { theme: MyTheme }): string => props.theme.colors.error};
  }
  .ant-btn:focus,
  .ant-btn:hover {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.error};
  }
  .ant-btn[disabled] {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
  }
`

const ApproveButton = styled(ButtonSection)`
  .btn,
  .btn:hover,
  .btn:focus {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.success};
    border: ${(props: { theme: MyTheme }): string => props.theme.colors.success};
  }
  .ant-btn:focus,
  .ant-btn:hover {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.success};
  }
`

const PrimaryButton2 = styled(ButtonSection)`
  span {
    color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  }
  .btn,
  .btn:hover,
  .btn:focus {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary1};
    border: ${(props: { theme: MyTheme }): string => props.theme.colors.primary1};
  }
  .ant-btn:focus,
  .ant-btn:hover {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary1};
  }
  .ant-btn[disabled] {
    background-color: #999999;
    span {
      color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
    }
  }
`

export default PrimaryButton
