import React from 'react'
import 'assets/styles/App.css'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './Store'
import { hot } from 'react-hot-loader/root'
import { ThemeProvider } from 'styled-components'
import defaultTheme from 'Components/common/Theme'
import App from 'App'
import { appConfig } from 'config'
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache'
import { LoadingView } from 'Components/modal'
import { consoleLog } from 'helpers/utils'

type Props = {}

const MainApp: React.FunctionComponent<Props> = () => {
  const currentVersion = 1.2
  const autoClearCache = appConfig.version !== currentVersion
  if (autoClearCache) {
    consoleLog('autoClearCache')
  }
  return (
    <ThemeProvider theme={defaultTheme}>
      <Router>
        <Provider store={store}>
          {/* <App /> */}
          <ClearBrowserCacheBoundary auto={false} fallback={<LoadingView isShow={true} />}>
            <App />
          </ClearBrowserCacheBoundary>
        </Provider>
      </Router>
    </ThemeProvider>
  )
}

export default appConfig.env === 'development' ? hot(MainApp) : MainApp
