import React from 'react'
import FormItem from './FormItem'
import LabelItem from './LabelItem'
import Input from './Input'
import InputAutocomplete, { OptionProps } from './InputAutocomplete'

type Props = {
  name: string
  label?: string
  placeHolder?: string
  onChange: (val: any, name: string) => void
  isError?: boolean
  value?: any
  type?: string
  isRequired?: boolean
  resProps?: object
  errorMsg?: string
  labelColor?: string
  options?: OptionProps[]
  onSearch: (text: string) => void
}

const AppAutocomplete = (props: Props): any => {
  const {
    label = '',
    name,
    isError = false,
    onChange,
    placeHolder = '',
    value,
    type = 'text',
    isRequired = false,
    resProps = {},
    errorMsg = '',
    labelColor,
    options = [],
    onSearch,
  } = props

  const onChangeData = (val: string) => {
    const value = type === 'number' ? parseFloat(val) : val
    onChange(value, name)
  }

  return (
    <FormItem label="" status={isRequired && isError ? 'error' : ''} error={`กรุณาระบุ ${errorMsg || label}`}>
      {label && <LabelItem label={isRequired ? `${label}*` : label} color={labelColor} />}
      <InputAutocomplete
        placeholder={placeHolder || label}
        name={name}
        value={value}
        onSearch={onSearch}
        onChange={onChangeData}
        resProps={resProps}
        options={options}
      />
    </FormItem>
  )
}

export default AppAutocomplete
