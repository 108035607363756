import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import { TableListPage } from 'Components/common/Table'
import { getTeamCaseDashboardtAPI } from 'Store/dashboard/api'
import { TeamDashbaordReq } from 'Store/dashboard/types'
import { selectTeamDashboardInfo } from 'Store/dashboard/selector'
import { DetailDrawer } from 'Components/common/MainLayout'

type Props = {
  visible: boolean
  onClose?: () => void
} & ReduxProps

const SummaryTeam: React.FunctionComponent<Props> = props => {
  const { visible, onClose, loginData, data } = props

  const dispatch = useDispatch()

  const onGetTeamDashboard = (): void => {
    const req: TeamDashbaordReq = {}
    dispatch(getTeamCaseDashboardtAPI(req, loginData?.access_token || ''))
  }

  useEffect(() => {
    onGetTeamDashboard()
  }, [])

  const columns = [
    {
      title: 'ชื่อ Agent',
      dataIndex: 'name',
      key: 'name',
      width: '200px',
    },
    {
      title: 'เปิดเคส',
      dataIndex: 'open',
      key: 'open',
    },
    {
      title: 'ปิดเคส',
      dataIndex: 'close',
      key: 'close',
    },
    {
      title: 'ยกเลิก',
      dataIndex: 'cancel',
      key: 'cancel',
    },
  ]

  return (
    <DetailDrawer
      title="ภาพรวมรายการสนทนาทั้งหมดของทีม"
      onCloseDrawer={onClose}
      visible={visible}
      width={'60%'}
    >
      <TableListPage
        columns={columns}
        datas={data}
        isLoading={false}
        paginationContent={null}
        scrollX={500}
        rowKey="name"
        offsetHeader={-25}
      />
    </DetailDrawer>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: selectUserProfile(state),
    loginData: selectLoginData(state),
    data: selectTeamDashboardInfo(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(SummaryTeam)
