/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { RootState } from 'Store'
import { connect } from 'react-redux'
import { selectEvent, selectLoginData } from 'Store/user/selector'
import liff from '@line/liff'
import { Col, Row, Image } from 'antd'
import { appConfig } from 'config'
import styled from 'styled-components'
import { LoadingView } from 'Components/modal'
import {
  getCookieData,
  getSelfUser,
  removeLineLoginData,
  removeSelfUser,
  saveCookieData,
  saveLineLoginData,
} from 'helpers/account'
import CoverImg from '../../../src/assets/img/cover_opd.jpg'
import OPDLogo from '../../../src/assets/img/line_opd.png'
import LineBtnLoginImg from '../../../src/assets/img/btn_login_base.png'
import theme from 'Components/common/Theme'

type Props = {} & RouteComponentProps & ReduxProps

const LineLogin: React.FunctionComponent<Props> = props => {
  const { location, history } = props
  const pathName = location.pathname
  const [acceptCookie, setAcceptCookie] = useState(false)
  const [loading, setLoading] = useState(false)
  const defaultUUID = appConfig.defaultUUID
  let uuid = pathName.split('/')[1]
  if (!uuid) {
    uuid = defaultUUID
  }
  const callbackUrl = `https://${appConfig.chatHostname}/${uuid}?isLoading=true`
  const queryString = location.search
  const selfUser = getSelfUser()
  const params = new URLSearchParams(queryString)
  const isLoading = params.get('isLoading') === 'true'
  const isLogout = params.get('logout') === 'true'
  const state = params.get('liff.state')
  const liffOAuth2Error = params.get('liffOAuth2Error')
  const error = params.get('error')

  const initLine = () => {
    if (liffOAuth2Error == 'true' || error?.toUpperCase() === 'ACCESS_DENIED') return
    liff
      .init({
        liffId: appConfig?.liffId || '', // Use own liffId
        withLoginOnExternalBrowser: false, // Enable automatic login process
      })
      .then(() => {
        if (liff.isLoggedIn()) {
          if (isLogout) {
            onLogout()
            setLoading(false)
          } else {
            getProfile()
          }
        } else {
          if (isLogout) {
            onLogout()
          } else {
            //old guest
            if (selfUser && selfUser?.userID) {
              history.push(`/${uuid}/guest`)
            }
          }

          setLoading(false)
        }
      })
  }

  const onLogin = () => {
    liff.login({
      redirectUri: callbackUrl,
    })
  }

  // const onLoginGuestMode = () => {
  //   removeLineLoginData()
  //   //removeSelfUser()
  //   history.push(`/${uuid}/guest`)
  // }

  const onLogout = () => {
    removeLineLoginData()
    removeSelfUser()
    liff.logout()
  }

  const getProfile = () => {
    liff.getProfile().then(profile => {
      const email = liff.getDecodedIDToken()?.email
      if (profile && profile.userId) {
        saveLineLoginData({
          displayName: profile.displayName,
          pictureUrl: profile.pictureUrl || '',
          userId: profile.userId,
          email: email,
        })
        history.push(`/${uuid}/${profile.userId}`)
      }
    })
  }

  const onAcceptCookie = () => {
    saveCookieData(true)
    setAcceptCookie(true)
  }

  useEffect(() => {
    initLine()
    const cookie = getCookieData()
    if (cookie) {
      setAcceptCookie(true)
    }
  }, [window.location.pathname])

  useEffect(() => {
    if (isLoading === true || isLoading === false) {
      setLoading(isLoading)
    }
  }, [isLoading])

  useEffect(() => {
    setLoading(true)
  }, [])

  useEffect(() => {
    if (liffOAuth2Error == 'true' || error?.toUpperCase() === 'ACCESS_DENIED') {
      const agentId = state?.split('?')[0]
      removeLineLoginData()
      window.location.href = `${agentId}/guest`
    }
  }, [error])

  return loading ? (
    <LoadingView isShow={true} />
  ) : (
    <OuterWrapper>
      <Wrapper align="bottom">
        <Col span={24}>
          <LineInfo>
            <img src={OPDLogo} width={'90px'} />
            <LineName>
              {appConfig.opdLoginTitle1} <br />
              {appConfig.opdLoginTitle2}
            </LineName>
            <LineBtnLogin onClick={onLogin}>
              <img src={LineBtnLoginImg} width={250}></img>
              <Overlay />
            </LineBtnLogin>
            {/* <LoginLink onClick={onLoginGuestMode}>เข้าสู่ระบบแบบไม่ระบุตัวตน</LoginLink> */}
          </LineInfo>
        </Col>
        <Col span={24}>
          <Image src={appConfig.opdCover} width={'100%'} />
        </Col>
      </Wrapper>
      {!acceptCookie && (
        <AlertWrapper>
          <Content>
            <Row justify="space-between" align="middle" gutter={[8, 8]}>
              <Col span={18}>
                <Title>การใช้และการจัดการคุกกี้</Title>
                <Desc>
                  ธนาคารมีการใช้เทคโนโลยี เช่น คุกกี้ (cookies) และเทคโนโลยีที่
                  คล้ายคลึงกันบนเว็บไซต์ของธนาคารเพื่อสร้างประสบการณ์การ ใช้งานเว็บไซต์ของท่านให้ดียิ่งขึ้น
                  โปรดอ่านรายละเอียดเพิ่มเติมที่{' '}
                  <Link href="https://www.scb.co.th/th/personal-banking/cookies-policy.html" target="_blank">
                    นโยบายการใช้คุกกี้ของธนาคาร
                  </Link>
                </Desc>
              </Col>
              <Col onClick={onAcceptCookie}>
                <AcceptBtn>ยอมรับ</AcceptBtn>
              </Col>
            </Row>
          </Content>
        </AlertWrapper>
      )}
    </OuterWrapper>
  )
}

const OuterWrapper = styled(Row)`
  height: 100vh;
  width: 100%;
  background-color: #fff;
`

const Wrapper = styled(Row)`
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
`

const LineInfo = styled.div`
  width: 100%;
  text-align: center;
  margin: 35px 0;
`

const LineName = styled.div`
  margin: 10px 0 20px 0;
`

const LineBtnLogin = styled.div`
  cursor: pointer;
  width: 250px;
  margin: 0 auto;
  position: relative;
`

const Overlay = styled.div`
  width: 250px;
  height: 56.3px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  :hover {
    background-color: #000;
    opacity: 0.1;
  }

  :active {
    background-color: #000;
    opacity: 0.3;
  }
`

const AlertWrapper = styled.div`
  position: fixed;
  bottom: 10px;
  width: 100%;
  padding: 0 10px;
`

const Content = styled.div`
  max-width: 600px;
  width: 100%;
  background-color: #e5e7ec;
  padding: 12px;
  margin: 0 auto;
`

const Title = styled.div`
  font-size: ${theme.typography.body3};
  font-family: ${theme.fontFamily.bold};
  color: #462e7f;
`

const Desc = styled.div`
  margin-top: 10px;
  font-size: ${theme.typography.body5};
`

const Link = styled.a`
  font-size: ${theme.typography.body5};
  color: #6f5fb4;
`

const LoginLink = styled.div`
  font-size: ${theme.typography.body4};
  color: ${theme.colors.grey};
  text-decoration: underline !important;
  padding: 5px 0;
  cursor: pointer;
`

const AcceptBtn = styled.button`
  background: #ffffff;
  border: 1px solid #6f5fb4;
  box-sizing: border-box;
  border-radius: 3px;
  color: #6f5fb4;
  padding: 5px 10px;
`

const mapStateToProps = (state: RootState): any => {
  return {
    event: selectEvent(state),
    loginData: selectLoginData(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(LineLogin)
