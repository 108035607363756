import React from 'react'
import { Line } from 'Components/utilities/Chart'
import { Theme } from 'Components/common'
import { numberWithComma } from 'helpers/format'

type Props = {
  dataStat: any
}

const ChatChart: React.FunctionComponent<Props> = props => {
  const { dataStat } = props

  const getValue = () => {
    const values = [] as number[]
    for (const i in dataStat) {
      values.push(dataStat[i])
    }
    return values
  }

  const getLabels = () => {
    const values = [] as string[]
    for (const i in dataStat) {
      values.push(i)
    }
    return values
  }

  const data = {
    labels: getLabels(),
    datasets: [
      {
        label: 'ปริมาณงาน',
        data: getValue(),
        fill: false,
        backgroundColor: Theme.colors.primary,
        borderColor: Theme.colors.primary,
        tension: 0,
        borderWidth: 2,
      },
    ],
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0
          return `ปริมาณงาน ${numberWithComma(value)} งาน/วัน`
        },
      },
    },
  }
  return <Line data={data} options={options} />
}

export default ChatChart
