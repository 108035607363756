import React from 'react'
import { Result } from 'antd'
import ErrorLayout from 'Pages/Layout/ErrorLayout'
import { RouteComponentProps, withRouter } from 'react-router-dom'

type Props = {} & RouteComponentProps

const PageNotFound: React.FunctionComponent<Props> = props => {
  const { history } = props

  const gotoHomePage = (): void => {
    history.push('/')
  }

  return (
    <ErrorLayout btnText="กลับไปหน้าแรก" onClick={gotoHomePage}>
      <Result status="404" title="URL ไม่ถูกต้อง" subTitle="ไม่พบหน้าที่คุณต้องการเข้าถึง" />
    </ErrorLayout>
  )
}

export default withRouter(PageNotFound)
