import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import { CountData, Data, ListData, SaleStatusData, TaskLastest } from './types'

export const selectActionEvent = (state: RootState): Event => state.customerReducer.actionEvent

export const selectCustomerInfo = (state: RootState): Data | undefined =>
  state.customerReducer.customerInfo.data

export const selectCustomerEvent = (state: RootState): Event => state.customerReducer.customerInfo.event

export const selectList = (state: RootState): ListData => state.customerReducer.customerList.data

export const selectListEvent = (state: RootState): Event => state.customerReducer.customerList.event

export const selectDetail = (state: RootState): Data | undefined =>
  state.customerReducer.customerDetail.detail

export const selectDetailEvent = (state: RootState): Event => state.customerReducer.customerDetail.event

export const selectLastCreateTask = (state: RootState): TaskLastest | undefined =>
  state.customerReducer.lastCreateTask

export const selectCountCustomer = (state: RootState): CountData => state.customerReducer.countData

export const selectTaskEvent = (state: RootState): Event => state.customerReducer.taskEvent

export const selectSaleStatusList = (state: RootState): SaleStatusData[] => state.customerReducer.saleStatus

export const selectSaleTaskStatusList = (state: RootState): SaleStatusData[] => state.customerReducer.saleTaskStatus