import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import { Data, SocketCount, TeamData } from './types'

export const selectActionEvent = (state: RootState): Event => state.dashboardReducer.actionEvent

export const selectCaseDashboardInfo = (state: RootState): Data | undefined =>
  state.dashboardReducer.caseInfo.data

export const selectCaseDashboardEvent = (state: RootState): Event => state.dashboardReducer.caseInfo.event

export const selectTeamDashboardInfo = (state: RootState): TeamData[] =>
  state.dashboardReducer.teamCaseInfo.data

export const selectTeamDashboardEvent = (state: RootState): Event => state.dashboardReducer.teamCaseInfo.event

export const selectSocketDashboardEvent = (state: RootState): Event => state.dashboardReducer.socket.event

export const selectSocketDashboardCount = (state: RootState): SocketCount => state.dashboardReducer.socket
