import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import { UploadRes } from './types'

export const uploadAction = createAsyncAction(
  'UPLOAD/UPLOAD_REQ',
  'UPLOAD/UPLOAD_SUCCESS',
  'UPLOAD/UPLOADT_FAILURE',
)<FormData, UploadRes, Error>()

export const clearEventAction = createStandardAction('UPLOAD/CLEAR_EVENT')<void>()
