import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import theme, { MyTheme } from 'Components/common/Theme'
import { Checkbox, Col, Divider, message, Row, Space } from 'antd'
import { PrimaryButton } from 'Components/button'
import LogoImage from 'Components/logo/LogoImage'
import { UploadForm } from 'Components/form'
import { formValidation, isFormError, isFormRequired } from 'helpers/format'
import { agentDocs } from 'helpers/constanst'
import { ConfirmWithChild } from 'Components/modal'
import TermAndCond from './TermAndCond'
import { RegisterReq } from 'Store/register/types'

type Props = {
  onUpdateData: (key: string, value: any) => void
  formData: RegisterReq
  onNext?: () => void
  onBack?: () => void
}

const DocumentForm: React.FunctionComponent<Props> = props => {
  const { onNext = () => {}, onBack = () => {}, onUpdateData, formData } = props
  const labelColor = theme.colors.white
  const requireFeild = [
    'document_personal_id',
    'document_bank',
    'document_copy_of_house',
    'document_occupational',
    'document_personal_picture',
  ] as string[]
  const [formError, setFormError] = useState([] as string[])
  const [showTermAndCond, setShowTermAndCond] = useState(false)
  const [accept, setAccept] = useState(false)

  const warning = () => {
    message.warning('กรุณายอมรับ ข้อกำหนดและเงื่อนไขการให้บริการ')
  }
  const onClickNext = () => {
    if (!validateForm()) return
    if (!accept) {
      warning()
      return
    }
    onNext()
  }

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const onChangeAccept = (val: boolean) => {
    setAccept(val)
  }

  const onChange = (e: any) => {
    if (!accept) {
      setShowTermAndCond(true)
    } else {
      onChangeAccept(e.target.value)
    }
  }

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    setFormError([...error])
    return error.length > 0 ? false : true
  }

  const isRequired = (name: string) => {
    return isFormRequired(requireFeild, name)
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const documentPreview = (title: string, image: string = '', key: string) => (
    <Col md={12} xs={24} key={key}>
      <UploadForm
        token={''}
        onUpdateData={(file: any) => onUpdateData(file, key)}
        currentFile={image}
        folder="merchant"
        listType="picture"
        isPrivate={true}
        name="merchant-document"
        description="ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)"
        isRequired={isRequired(key) || false}
        isError={isError(key) || false}
        label={title}
        labelColor={labelColor}
      />
    </Col>
  )

  const TermAndCondModal = (
    <ConfirmWithChild
      title={''}
      isShow={showTermAndCond}
      handleOk={() => {
        setShowTermAndCond(false)
        onChangeAccept(true)
      }}
      handleCancel={() => setShowTermAndCond(false)}
      child={<TermAndCond />}
      confirmBtnTxt="ยอมรับ"
      hasCancel={false}
      width={'80%'}
    />
  )

  return (
    <MyLayout>
      {TermAndCondModal}
      <Row justify="center">
        <Col>
          <Space direction="vertical" align="center" size="small">
            <LogoImage primaryColor={false} width={200} />
            <Title>การสมัครสมาชิกตัวแทน ไชโย</Title>
          </Space>
        </Col>
      </Row>
      <Row justify="center" align="bottom">
        <Col md={20} xs={24}>
          <FormWrapper>
            <Row gutter={[32, 8]}>
              <Col span={24}>
                <TitleSecond>เอกสารสำหรับสมัครสมาชิกตัวแทน</TitleSecond>
              </Col>
              <Col span={24}>
                <Divider style={{ borderColor: '#fff' }} />
              </Col>
              {agentDocs.map((item: any) => {
                return documentPreview(item.name, formData?.[item.key], item.key)
              })}
            </Row>
          </FormWrapper>
          <CheckWrapper>
            <Checkbox onChange={onChange} checked={accept}></Checkbox> ข้าพเจ้าเข้าใจและยอมรับ
            <TextUnderline
              onClick={e => {
                e.stopPropagation()
                setShowTermAndCond(true)
              }}
            >
              ข้อกำหนดและเงื่อนไขการให้บริการ{' '}
            </TextUnderline>
          </CheckWrapper>
        </Col>
      </Row>
      <BtnWrapper>
        <Row justify="center" gutter={[16, 16]}>
          <Col lg={6} xs={12}>
            <PrimaryButton text="ย้อนกลับ" btnType="Cancel" onClick={onBack} disabled={false} />
          </Col>
          <Col lg={6} xs={12}>
            <PrimaryButton
              text="ลงทะเบียนสมัครตัวแทน"
              btnType="Primary2"
              onClick={onClickNext}
              disabled={false}
            />
          </Col>
        </Row>
      </BtnWrapper>
    </MyLayout>
  )
}

const MyLayout = styled.div``

const Title = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.h2sm};
  text-align: center;
`

const TitleSecond = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.h5};
  text-align: left;
`

const FormWrapper = styled.div`
  margin: 38px 0;
  .ant-upload-list-item-name {
    color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  }
`

const BtnWrapper = styled.div`
  margin-top: 40px;
`

const CheckWrapper = styled.div`
  .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary1};
    border: 1px solid ${(props: { theme: MyTheme }): string => props.theme.colors.primary1};
    border-radius: 2px;
    width: 20px;
    height: 20px;
  }
  span {
    color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  }

  .ant-checkbox:hover::after,
  .ant-checkbox-wrapper:hover .ant-checkbox::after {
    visibility: hidden;
  }
`

const TextUnderline = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
`

export default DocumentForm
