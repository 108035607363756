import React from 'react'
import styled from 'styled-components'
import { Col, Layout, Row } from 'antd'
import NavBar from './NavBar'
import SideMenu from './SideMenu'
import Theme, { MyTheme } from 'Components/common/Theme'
import Title from 'Components/text/Title'
import Label from 'Components/text/Label'
import theme from 'Components/common/Theme'
import { ClientNavBar } from '.'

const { Content } = Layout

type Props = {
  title?: string
  label?: any
  children?: React.ReactNode
  bgColor?: string
  rightWidget?: React.ReactNode
}

const ClientLayout: React.FunctionComponent<Props> = props => {
  const { title = '', children, label = '', bgColor = Theme.colors.whitebg, rightWidget } = props

  return (
    <MyLayout>
      <Layout>
        <ClientNavBar />
        <Layout style={{ padding: '0 24px 24px' }}>
          <ContentArea style={{ backgroundColor: bgColor }}>
            <Row justify="space-between" gutter={[16, 16]}>
              <Col>
                <Title color={theme.colors.primary}>{title}</Title> &nbsp;
                {label && <Label>| {label}</Label>}
              </Col>
              {rightWidget && <Col>{rightWidget}</Col>}
            </Row>
            {children}
          </ContentArea>
        </Layout>
      </Layout>
    </MyLayout>
  )
}

const ContentArea = styled(Content)`
  padding: 20px 32px;
  margin: 0;
  height: calc(100vh - 80px);
  overflow: scroll;
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.whitebg};
  @media only screen and (max-width: 720px) {
    padding: 10px 15px;
  }
`

const MyLayout = styled(Layout)`
  .ant-layout {
    padding: 0 !important;
    background: ${(props: { theme: MyTheme }): string => props.theme.colors.whitebg};
  }
  overflow-x: hidden;
`

export default ClientLayout
