import theme, { MyTheme } from 'Components/common/Theme'
import React from 'react'
import styled from 'styled-components'

type Props = {
  label?: string
  color?: string
}

const LabelItem = (props: Props) => {
  const { label = '', color = theme.colors.black } = props
  return <Label style={{ color: color }}>{label}</Label>
}

const Label = styled.label`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
`

export default LabelItem
