import { all, put, select, takeEvery } from 'redux-saga/effects'
import APIManager, { configWithToken } from 'services/APIManager'
import { logoutAction } from 'Store/user/actions'
//import * as loginActions from 'features/login/login.actions'

export function* genericAPICalling(action: any) {
  if (action.type === 'CALL_API') {
    let payload
    if (typeof action.payload === 'function') {
      payload = yield select(action.payload)
    } else {
      payload = action.payload
    }

    // Prepare Data
    let data
    if (typeof payload.data === 'function') {
      data = yield select(payload?.data || {})
    } else {
      data = payload.data
    }

    // Dispatch Request Action
    const requestAction = action.meta.request(data)
    yield put(requestAction)
    let resp
    try {
      // Call API
      resp = yield APIManager.call(
        payload.baseUrl,
        payload.path,
        payload.method,
        data,
        configWithToken(payload.token, payload.xmid),
      )

      // Dispatch Success Action
      const successAction = action.meta.success(resp)
      yield put(successAction)
    } catch (error) {
      const { response } = error as any
      if (response?.status === 401) {
        yield put(logoutAction())
      } else {
        const failureAction = action.meta.failure(error)
        yield put(failureAction)
      }
    }
  }
}

function* commonSaga() {
  yield all([takeEvery('CALL_API', genericAPICalling)])
}

export default commonSaga
