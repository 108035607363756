import React from 'react'
import { DatePickerItem } from '.'
import FormItem from './FormItem'
import LabelItem from './LabelItem'

type Props = {
  label?: string
  placeHolder?: string
  onChange: (startDate: string) => void
  isError?: boolean
  isRequired?: boolean
  date?: string
  showTime?: boolean
  allowClear?: boolean
  disabled?: boolean
  disableDate?: (date: any) => boolean
  labelColor?: string
  picker?: 'date' | 'year'
}

const AppDateItem = (props: Props) => {
  const {
    label = '',
    isError = false,
    onChange,
    placeHolder,
    isRequired = false,
    date,
    showTime = false,
    allowClear,
    disabled = false,
    disableDate,
    labelColor,
    picker,
  } = props

  return (
    <FormItem label="" status={isRequired && isError ? 'error' : ''} error={`กรุณาระบุ ${label}`}>
      <LabelItem label={isRequired ? `${label}*` : label} color={labelColor} /> <br />
      <DatePickerItem
        placeHolder={placeHolder}
        onChange={onChange}
        date={date}
        showTime={showTime}
        allowClear={allowClear}
        disabled={disabled}
        disableDate={disableDate}
        picker={picker}
      />
    </FormItem>
  )
}

export default AppDateItem
