import React from 'react'
import { DatePicker } from 'antd'
import { MyTheme } from 'Components/common/Theme'
import styled from 'styled-components'
import { toDateMoment } from 'helpers/format'
import locale from 'antd/es/date-picker/locale/th_TH'

interface MenuProps {
  name: string
  value: string | number
}

type Props = {
  placeHolder?: string
  onChange: (date: string) => void
  isError?: boolean
  isRequired?: boolean
  date?: string
  showTime?: boolean
  allowClear?: boolean
  disabled?: boolean
  disableDate?: (date: any) => boolean
  picker?: 'date' | 'year'
}

const DatePickerItem = (props: Props) => {
  const {
    onChange,
    placeHolder = 'วันเริ่มต้น',
    date = '',
    showTime = false,
    allowClear = true,
    disabled = false,
    disableDate = (date: any) => false,
    picker = 'date',
  } = props

  const handleChangeDate = (event: any) => {
    onChange(event)
  }

  return (
    <StyledRangePicker>
      <DatePicker
        placeholder={placeHolder}
        onChange={handleChangeDate}
        value={toDateMoment(date)}
        showTime={showTime}
        locale={locale}
        allowClear={allowClear}
        disabled={disabled}
        //format={calendarFormat}
        disabledDate={disableDate}
        picker={picker}
      />
    </StyledRangePicker>
  )
}

const StyledRangePicker = styled.div`
  .ant-picker {
    width: 100%;
    height: 45px !important;
    border-radius: 6px !important;
    padding-top: 5px !important;
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body3};
    font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.regular};
  }
  .ant-select-selection-search {
    padding-top: 5px;
  }
`

export default DatePickerItem
