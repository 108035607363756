import { Event } from 'Store/common/common.types'

export const getEvent = (list: Event[] = [], errorList: Event[] = []): Event => {
  if (list.length === 0 && errorList.length === 0) {
    return {
      IsLoading: false,
      SubmitError: undefined,
      SubmitSuccess: false,
      MessageSuccess: undefined,
    } as Event
  }
  let index = 0
  // find error first
  index = errorList.findIndex(item => {
    return item.IsLoading === true || item.SubmitError
  })

  if (index !== -1) {
    return errorList[index]
  }

  index = list.findIndex(item => {
    return item.IsLoading === true || item.SubmitError
  })

  if (index !== -1) {
    return list[index]
  }
  // find success
  index = list.findIndex(item => {
    return item.SubmitSuccess === true || item.MessageSuccess
  })

  return index !== -1 ? list[index] : list[0]
}

export default event
