import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action } from './types'
import { initEvent } from 'Store/common/common.reducer'
import parseErrorMessage from 'helpers/parseErrorMessage'

const initialState: State = {
  caseInfo: {
    event: cloneDeep(initEvent),
    data: undefined,
  },
  teamCaseInfo: {
    event: cloneDeep(initEvent),
    data: [],
  },
  actionEvent: cloneDeep(initEvent),
  socket: {
    bookLog: {
      count: 0,
    },
    bookCurrentCount: {
      all: 0,
      self: 0,
      team: 0,
    },
    event: cloneDeep(initEvent),
  },
}

export default function(state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.updateAction.request):
        draft.actionEvent = cloneDeep(initEvent)
        draft.actionEvent.IsLoading = true
        return draft
      case getType(actions.updateAction.success):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = undefined
        draft.actionEvent.SubmitSuccess = true
        draft.actionEvent.MessageSuccess = 'บันทึกข้อมูลสำเร็จ'
        return draft
      case getType(actions.updateAction.failure):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getCaseDetailAction.request):
        draft.caseInfo.event = cloneDeep(initEvent)
        draft.caseInfo.event.IsLoading = true
        draft.caseInfo.data = undefined
        return draft
      case getType(actions.getCaseDetailAction.success):
        response = action.payload
        draft.caseInfo.event.IsLoading = false
        draft.caseInfo.event.SubmitSuccess = true
        draft.caseInfo.data = response.data
        return draft
      case getType(actions.getCaseDetailAction.failure):
        draft.caseInfo.event.IsLoading = false
        draft.caseInfo.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getTeamCaseDetailAction.request):
        draft.teamCaseInfo.event = cloneDeep(initEvent)
        draft.teamCaseInfo.event.IsLoading = true
        draft.teamCaseInfo.data = []
        return draft
      case getType(actions.getTeamCaseDetailAction.success):
        response = action.payload
        draft.teamCaseInfo.event.IsLoading = false
        draft.teamCaseInfo.event.SubmitSuccess = true
        draft.teamCaseInfo.data = response.data
        return draft
      case getType(actions.getTeamCaseDetailAction.failure):
        draft.teamCaseInfo.event.IsLoading = false
        draft.teamCaseInfo.event.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.getBookLogAction.request):
        draft.socket.event = cloneDeep(initEvent)
        draft.socket.event.IsLoading = true
        draft.socket.bookLog.count = 0
        return draft
      case getType(actions.getBookLogAction.success):
        response = action.payload
        draft.socket.event.IsLoading = false
        draft.socket.event.SubmitSuccess = true
        draft.socket.bookLog.count = response.count
        return draft
      case getType(actions.getBookLogAction.failure):
        draft.socket.event.IsLoading = false
        draft.socket.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getBookCurrentAction.request):
        draft.socket.event = cloneDeep(initEvent)
        draft.socket.event.IsLoading = true
        draft.socket.bookCurrentCount = {
          all: 0,
          self: 0,
          team: 0,
        }
        return draft
      case getType(actions.getBookCurrentAction.success):
        response = action.payload
        draft.socket.event.IsLoading = false
        draft.socket.event.SubmitSuccess = true
        draft.socket.bookCurrentCount = response.count
        return draft
      case getType(actions.getBookCurrentAction.failure):
        draft.socket.event.IsLoading = false
        draft.socket.event.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.clearEventAction):
        draft.caseInfo.event = cloneDeep(initEvent)
        draft.actionEvent = cloneDeep(initEvent)
        return draft

      default:
        return state
    }
  })
}
