import React from 'react'
import { Button, Tooltip } from 'antd'
import styled from 'styled-components'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { SizeType } from 'antd/es/config-provider/SizeContext'

interface Props {
  text: string
  label?: string
  disabled?: boolean
  onClick?: (event: any) => void
  icon?: React.ReactNode
  action?: 'edit' | 'deleted' | 'view' | 'success' | 'default'
  size?: SizeType
}

const ActionButton: React.FC<Props> = ({
  text,
  disabled = false,
  onClick,
  icon,
  label = '',
  action = 'default',
  size = 'middle',
}) => {
  const breakpoint = useBreakpoint()
  const xs = breakpoint.xs || false

  const button = (
    <ButtonAction
      type="primary"
      shape="circle"
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      about={action}
      size={size}
    >
      {text}
    </ButtonAction>
  )

  return xs ? (
    button
  ) : (
    <Tooltip placement="topLeft" title={label || text}>
      {button}
    </Tooltip>
  )
}

const getColor = (props: any): string => {
  let color = ''
  switch (props.about) {
    case 'default':
      color = props.theme.colors.primary
      break
    case 'deleted':
      color = props.theme.colors.primary2
      break
    case 'success':
      color = props.theme.colors.success
      break
    case 'edit':
      color = props.theme.colors.drakGrey
      break
    case 'view':
    default:
      color = props.theme.colors.drakGrey
      break
  }

  return color
}

const ButtonAction = styled(Button)`
  width: 30px;
  background-color: ${getColor};
  border: none;
  &:hover,
  &:focus {
    background-color: ${getColor};
  }
`

export default ActionButton
