import React from 'react'
import FormItem from './FormItem'
import LabelItem from './LabelItem'
import Label from 'Components/text/Label'
import { MultiUploader } from 'Components/upload'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'

type Props = {
  name: string
  token: string
  files: string[]
  label?: string
  description?: string
  onUpdateData: (val: any, name: string) => void
  isError?: boolean
  isRequired?: boolean
  folder?: string
  listType?: any
  acceptType?: string
  withCropImage?: boolean
  aspect?: number
  isPrivate?: boolean
  readOnly?: boolean
  minFileSize?: number
  maxFileSize?: number
}

const MultiUploadForm = (props: Props): any => {
  const {
    label = '',
    name,
    isError = false,
    onUpdateData,
    isRequired = false,
    token,
    files,
    folder = 'common',
    listType,
    withCropImage,
    acceptType,
    aspect,
    description = '',
    isPrivate = false,
    readOnly = false,
    minFileSize,
    maxFileSize,
  } = props

  return (
    <FormItem label="" status={isRequired && isError ? 'error' : ''} error={`กรุณาระบุ${label}`}>
      <LabelItem label={label} />
      {description && (
        <>
          <br /> <StyledLabel>{description}</StyledLabel>{' '}
        </>
      )}

      <br />
      {readOnly && files.length <= 0 ? (
        <span>-</span>
      ) : (
        <MultiUploader
          token={token}
          onUpdateFile={(file: string[]) => onUpdateData(file, name)}
          files={files}
          folder={folder}
          listType={listType}
          acceptType={acceptType}
          withCropImage={withCropImage}
          aspect={aspect}
          isPrivate={isPrivate}
          readOnly={readOnly}
          minFileSize={minFileSize}
          maxFileSize={maxFileSize}
        />
      )}
    </FormItem>
  )
}

const StyledLabel = styled(Label)`
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.regular};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.caption};
`

export default MultiUploadForm
