import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import { Data, Message } from './types'

export const selectActionEvent = (state: RootState): Event => state.geetingMessageReducer.actionEvent

export const selectGeetingMessageInfo = (state: RootState): Data[] =>
  state.geetingMessageReducer.geetingMessageInfo.data

export const selectGeetingMessageEvent = (state: RootState): Event =>
  state.geetingMessageReducer.geetingMessageInfo.event

export const selectTemplateMessageInfo = (state: RootState): Message[] =>
  state.geetingMessageReducer.template.data

export const selectTemplateMessageEvent = (state: RootState): Event =>
  state.geetingMessageReducer.template.event
