import { Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { MyTheme } from '../Theme'

type Props = {
  title?: string
  children?: React.ReactNode
  right?: React.ReactNode
  label?: React.ReactNode
}

const BoxWrapper: React.FunctionComponent<Props> = props => {
  const { children, title = '', right, label } = props
  return (
    <Wrapper>
      <Row justify="space-between" align="stretch">
        <Col>
          {title && (
            <BoxTitle>
              {title} {label && label}
            </BoxTitle>
          )}
        </Col>
        <Col>{right && right}</Col>
      </Row>

      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  border: 1px solid ${(props: { theme: MyTheme }): string => props.theme.colors.greyBorder};
  border-radius: 8px;
  padding: 20px;
  height: 100%;
`

const BoxTitle = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body2};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.darkBlue};
  margin-bottom: 10px;
`

export default BoxWrapper
