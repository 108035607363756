import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import { ListReq, ListRes, ActionRes, UpdateReq, LogsReq, LogsRes } from './types'

export const getListAction = createAsyncAction(
  'WITHDRAW_TRANSACTION/GET_LIST_REQ',
  'WITHDRAW_TRANSACTION/GET_LIST_SUCCESS',
  'WITHDRAW_TRANSACTION/GET_LIST_FAILURE',
)<ListReq, ListRes, Error>()

export const updateAction = createAsyncAction(
  'WITHDRAW_TRANSACTION/UPDATE_REQ',
  'WITHDRAW_TRANSACTION/UPDATE_SUCCESS',
  'WITHDRAW_TRANSACTION/UPDATE_FAILURE',
)<UpdateReq, ActionRes, Error>()

export const getLogsAction = createAsyncAction(
  'WITHDRAW_TRANSACTION/GET_LOGS_REQ',
  'WITHDRAW_TRANSACTION/GET_LOGS_SUCCESS',
  'WITHDRAW_TRANSACTION/GET_LOGS_FAILURE',
)<LogsReq, LogsRes, Error>()

export const clearEventAction = createStandardAction('WITHDRAW_TRANSACTION/CLEAR_EVENT')<void>()
