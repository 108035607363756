import withAPIAction from 'helpers/withAPIAction'
import { ListReq, DetailReq, UpdateReq, UpdateStatusReq, UpdateAgentImageReq } from './types'
import {
  getListAction,
  updateAction,
  getDetailAction,
  updateAgentStatusAction,
  updateAgentImageAction,
} from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'
import { getRequestString } from 'helpers/format'

export const getAgentListAPI = (data: ListReq, token: string) => {
  return withAPIAction<ListReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.getAgentList}?${getRequestString(data)}`,
    data,
    token,
  })(getListAction)
}

export const getAgentDetailAPI = (data: DetailReq, token: string) => {
  const endPoint = APIPath.getAgentByID.replace(':id', data.id.toString())
  return withAPIAction<DetailReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getDetailAction)
}

export const updateAgentAPI = (data: UpdateReq, token: string) => {
  const endPoint = APIPath.updateAgent.replace(':id', data.id.toString())
  return withAPIAction<UpdateReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateAction)
}

export const updateAgentStatusAPI = (data: UpdateStatusReq, token: string) => {
  const endPoint = APIPath.updateStatusAgent.replace(':id', data.id.toString())
  return withAPIAction<UpdateStatusReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateAgentStatusAction)
}

export const updateAgentImageAPI = (data: UpdateAgentImageReq, token: string) => {
  return withAPIAction<UpdateAgentImageReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.updateAgentImage,
    data,
    token,
  })(updateAgentImageAction)
}
