import React from 'react'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'

type Props = {
  children: React.ReactNode
  color?: string
}

const Text = (props: Props): any => {
  const { children, color } = props
  return <StyledP style={{ color: color }}>{children}</StyledP>
}

const StyledP = styled.p`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.defaultSize};
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.regular};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`

export default Text
