import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import { ListData, Data } from './types'

export const selectListEvent = (state: RootState): Event => state.agentReducer.listEvent

export const selectList = (state: RootState): ListData => state.agentReducer.list

export const selectActionEvent = (state: RootState): Event => state.agentReducer.actionEvent

export const selectDetail = (state: RootState): Data | undefined => state.agentReducer.detail

export const selectDetailEvent = (state: RootState): Event => state.agentReducer.detailEvent
