import { getEvent } from 'helpers/event'
import React from 'react'
import { Event } from 'Store/common/common.types'
import { message } from 'antd'
import LoadingView from './LoadingView'
import { uniqeID } from 'helpers/format'

interface Props {
  event: Event[]
  err?: Event[]
  enable?: boolean
  successCalback?: () => void
  errorCalback?: () => void
  key?: string
}
const EventModal = (props: Props): any => {
  const {
    event,
    err = [],
    enable = false,
    successCalback = (): void => {},
    errorCalback = (): void => {},
    key = 'event',
  } = props
  const modalKey = key || uniqeID()

  const evt = getEvent(event, err)

  if (!!evt.SubmitError && enable) {
    if (evt.SubmitError === 'null') {
      errorCalback()
    } else {
      message.error({
        content: evt.SubmitError,
        key: modalKey + 'error',
        onClose: errorCalback,
      })
    }
  }

  if (evt.SubmitSuccess && enable) {
    if (evt.MessageSuccess !== 'null') {
      message.success({
        content: evt?.MessageSuccess || ' ',
        key: modalKey + 'success',
        // onClose: successCalback,
      })
    }
    successCalback()
  }

  return <LoadingView isShow={evt.IsLoading} key={modalKey} />
}

export default EventModal
