import RootState from 'typings/RootState'
import { Event } from 'Store/common/common.types'
import { BranData, CountData, Data, ListData, ModelData, TaskLastest, YearData, LogData } from './types'

export const selectActionEvent = (state: RootState): Event => state.chaiyoCustomerReducer.actionEvent

export const selectCustomerInfo = (state: RootState): Data | undefined =>
  state.chaiyoCustomerReducer.customerInfo.data

export const selectCustomerEvent = (state: RootState): Event => state.chaiyoCustomerReducer.customerInfo.event

export const selectList = (state: RootState): ListData => state.chaiyoCustomerReducer.customerList.data

export const selectListEvent = (state: RootState): Event => state.chaiyoCustomerReducer.customerList.event

export const selectDetail = (state: RootState): Data | undefined =>
  state.chaiyoCustomerReducer.customerDetail.detail

export const selectDetailEvent = (state: RootState): Event => state.chaiyoCustomerReducer.customerDetail.event

export const selectLastCreateTask = (state: RootState): TaskLastest | undefined =>
  state.chaiyoCustomerReducer.lastCreateTask

export const selectCountCustomer = (state: RootState): CountData => state.chaiyoCustomerReducer.countData

export const selectTaskEvent = (state: RootState): Event => state.chaiyoCustomerReducer.taskEvent

export const selectBrandList = (state: RootState): BranData[] => state.chaiyoCustomerReducer.brandData.brand

export const selectModelList = (state: RootState): ModelData[] => state.chaiyoCustomerReducer.brandData.model

export const selectYearList = (state: RootState): YearData[] => state.chaiyoCustomerReducer.brandData.year

export const selectLogsData = (state: RootState): LogData[] => state.chaiyoCustomerReducer.logData.data

export const selectLogsEvent = (state: RootState): Event => state.chaiyoCustomerReducer.logData.event

export const selectLastReply = (
  state: RootState,
): {
  success: boolean
  taskID: string
} => state.chaiyoCustomerReducer.lastReplay
