import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import { ListReq, ListRes, ActionRes, UpdateReq } from './types'

export const getListAction = createAsyncAction(
  'WITHDRAW/GET_LIST_REQ',
  'WITHDRAW/GET_LIST_SUCCESS',
  'WITHDRAW/GET_LIST_FAILURE',
)<ListReq, ListRes, Error>()

export const updateAction = createAsyncAction(
  'WITHDRAW/UPDATE_REQ',
  'WITHDRAW/UPDATE_SUCCESS',
  'WITHDRAW/UPDATE_FAILURE',
)<UpdateReq, ActionRes, Error>()

export const clearEventAction = createStandardAction('WITHDRAW/CLEAR_EVENT')<void>()
