import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'

interface Props {
  text: string
  disabled?: boolean
  onClick?: () => void
}

const SecondaryButton: React.FC<Props> = ({ text, disabled = false, onClick }) => {
  return (
    <ButtonSection>
      <Button type="link" className="btn" disabled={disabled} onClick={onClick}>
        {text}
      </Button>
    </ButtonSection>
  )
}

const ButtonSection = styled.div`
  .btn,
  .btn:hover,
  .btn:focus {
    width: 100%;
    height: 45px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
    background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.button};
    font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.primary};
    color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
    border: 1.5px solid;
    border-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
    cursor: pointer;
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:hover {
    border-color: ${(props: { theme: MyTheme }): string => props.theme.colors.disable};
    color: ${(props: { theme: MyTheme }): string => props.theme.colors.disable};
    background-color: #fff;
    cursor: not-allowed;
  }
`
export default SecondaryButton
