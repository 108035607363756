import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action } from './types'
import { initEvent, initMeta } from 'Store/common/common.reducer'
import parseErrorMessage from 'helpers/parseErrorMessage'
import { convertMetaData } from 'Store/common/common.function'

const initialState: State = {
  customerInfo: {
    event: cloneDeep(initEvent),
    data: undefined,
  },
  customerList: {
    data: {
      data: [],
      meta: cloneDeep(initMeta),
    },
    event: cloneDeep(initEvent),
  },
  actionEvent: cloneDeep(initEvent),
  customerDetail: {
    detail: undefined,
    event: cloneDeep(initEvent),
  },
  lastCreateTask: undefined,
  countData: {
    all: 0,
    open: 0,
    send_to_sale: 0,
    cancel: 0,
  },
  taskEvent: cloneDeep(initEvent),
  brandData: {
    brand: [],
    model: [],
    year: [],
    event: cloneDeep(initEvent),
  },
  logData: {
    data: [],
    event: cloneDeep(initEvent),
  },
  lastReplay: {
    success: false,
    taskID: '',
  },
}

export default function(state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.updateCustomerAction.request):
        draft.actionEvent = cloneDeep(initEvent)
        draft.actionEvent.IsLoading = true
        draft.lastCreateTask = undefined
        return draft
      case getType(actions.updateCustomerAction.success):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = undefined
        draft.actionEvent.SubmitSuccess = true
        draft.actionEvent.MessageSuccess = 'บันทึกข้อมูลสำเร็จ'
        draft.lastCreateTask = action.payload.data.task_latest
        return draft
      case getType(actions.updateCustomerAction.failure):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.getCustomerInfoAction.request):
        draft.customerInfo.event = cloneDeep(initEvent)
        draft.customerInfo.event.IsLoading = true
        return draft
      case getType(actions.getCustomerInfoAction.success):
        response = action.payload
        draft.customerInfo.event.IsLoading = false
        draft.customerInfo.event.SubmitSuccess = true
        draft.customerInfo.data = response.data
        return draft
      case getType(actions.getCustomerInfoAction.failure):
        draft.customerInfo.event.IsLoading = false
        draft.customerInfo.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getListAction.request):
        draft.customerList.event = cloneDeep(initEvent)
        draft.customerList.event.IsLoading = true
        draft.customerList.data = {
          data: [],
          meta: cloneDeep(initMeta),
        }
        return draft
      case getType(actions.getListAction.success):
        response = action.payload
        draft.customerList.event.IsLoading = false
        draft.customerList.event.SubmitSuccess = true
        draft.customerList.data.data = response.data
        if (response.meta) {
          draft.customerList.data.meta = convertMetaData(response.meta)
        }
        return draft
      case getType(actions.getListAction.failure):
        draft.customerList.event.IsLoading = false
        draft.customerList.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.getDetailAction.request):
        draft.customerDetail.event = cloneDeep(initEvent)
        draft.customerDetail.event.IsLoading = true
        draft.customerDetail.detail = undefined
        return draft
      case getType(actions.getDetailAction.success):
        response = action.payload
        draft.customerDetail.event.IsLoading = false
        draft.customerDetail.event.SubmitSuccess = true
        draft.customerDetail.detail = response.data
        return draft
      case getType(actions.getDetailAction.failure):
        draft.customerDetail.event.IsLoading = false
        draft.customerDetail.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.updateCustomerStatusAction.request):
      case getType(actions.updateAction.request):
      case getType(actions.updateToBranchAction.request):
        draft.taskEvent = cloneDeep(initEvent)
        draft.taskEvent.IsLoading = true
        return draft
      case getType(actions.updateCustomerStatusAction.success):
      case getType(actions.updateAction.success):
      case getType(actions.updateToBranchAction.success):
        draft.taskEvent.IsLoading = false
        draft.taskEvent.SubmitError = undefined
        draft.taskEvent.SubmitSuccess = true
        draft.taskEvent.MessageSuccess = 'บันทึกข้อมูลสำเร็จ'
        return draft
      case getType(actions.updateCustomerStatusAction.failure):
      case getType(actions.updateAction.failure):
      case getType(actions.updateToBranchAction.failure):
        draft.taskEvent.IsLoading = false
        draft.taskEvent.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.countCustomerTabAction.request):
        return draft
      case getType(actions.countCustomerTabAction.success):
        draft.countData = action.payload.data
        return draft
      case getType(actions.countCustomerTabAction.failure):
        return draft

      case getType(actions.getBrandListAction.request):
      case getType(actions.getBrandBySlugListAction.request):
        draft.brandData.event = cloneDeep(initEvent)
        draft.brandData.event.IsLoading = true
        return draft
      case getType(actions.getBrandListAction.success):
        response = action.payload
        draft.brandData.event.IsLoading = false
        draft.brandData.event.SubmitSuccess = true
        draft.brandData.brand = response.data || []
        return draft
      case getType(actions.getBrandBySlugListAction.success):
        response = action.payload
        draft.brandData.event.IsLoading = false
        draft.brandData.event.SubmitSuccess = true
        draft.brandData.model = response.data || []
        return draft
      case getType(actions.getBrandListAction.failure):
      case getType(actions.getBrandBySlugListAction.failure):
        draft.brandData.event.IsLoading = false
        draft.brandData.event.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.clearEventAction):
        draft.customerInfo.event = cloneDeep(initEvent)
        draft.actionEvent = cloneDeep(initEvent)
        draft.taskEvent = cloneDeep(initEvent)
        draft.brandData.event = cloneDeep(initEvent)
        return draft
      case getType(actions.resetBrandDataAction):
        draft.brandData.model = []
        draft.brandData.year = []
        return draft
      case getType(actions.getYearByModelDataAction):
        const model = (response = action.payload.model)
        const year = draft.brandData.model.find(item => item.name === model)
        draft.brandData.year = year?.datas || []
        return draft

      case getType(actions.getTaskLogsAction.request):
        draft.logData.event = cloneDeep(initEvent)
        draft.logData.event.IsLoading = true
        return draft
      case getType(actions.getTaskLogsAction.success):
        response = action.payload
        draft.logData.event.IsLoading = false
        draft.logData.event.SubmitSuccess = true
        draft.logData.data = response.data
        return draft
      case getType(actions.getTaskLogsAction.failure):
        draft.logData.event.IsLoading = false
        draft.logData.event.SubmitError = parseErrorMessage(action.payload)
        return draft

      case getType(actions.updateChatTaskReplyAction.request):
        draft.lastReplay.success = false
        draft.lastReplay.taskID = action?.payload?.taskID || ''
        return draft
      case getType(actions.updateChatTaskReplyAction.success):
        response = action.payload
        draft.lastReplay.success = true
        return draft

      case getType(actions.updateChatTaskReplyAction.failure):
        response = action.payload
        draft.lastReplay.success = false
        draft.lastReplay.taskID = ''
        return draft

      default:
        return state
    }
  })
}
