import * as React from 'react'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import colors from 'Components/common/Theme/colors'

type Props = {
  color?: string
  children: React.ReactNode
}

const Title = (props: Props): any => {
  const { children, color = colors.black } = props
  return <StyledP style={{ color: color }}>{children}</StyledP>
}

const StyledP = styled.h2`
  display: inline-block;
  box-sizing: border-box;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: ${(props: { theme: MyTheme }): string => props.theme.fontFamily.medium};
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.title1};
  @media only screen and (min-width: 1024px) {
    font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.h3};
  }
`

export default Title
