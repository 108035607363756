import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action } from './types'
import { initEvent } from 'Store/common/common.reducer'
import parseErrorMessage from 'helpers/parseErrorMessage'

const initialState: State = {
  uploadEvent: cloneDeep(initEvent),
  uploadFile: '',
}

export default function(state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.uploadAction.request):
        draft.uploadEvent = cloneDeep(initEvent)
        draft.uploadEvent.IsLoading = true
        draft.uploadFile = ''
        return draft
      case getType(actions.uploadAction.success):
        response = action.payload
        draft.uploadEvent.IsLoading = false
        draft.uploadEvent.SubmitSuccess = true
        draft.uploadFile = response.data[0]
        return draft
      case getType(actions.uploadAction.failure):
        draft.uploadEvent.IsLoading = false
        draft.uploadEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.clearEventAction):
        draft.uploadEvent = cloneDeep(initEvent)
        draft.uploadFile = ''
        return draft
      default:
        return state
    }
  })
}
