import { createStandardAction } from 'typesafe-actions'

export const setActiveTabAction = createStandardAction('LAYOUT/SET_ACTIVE_TAB')<{
  tabId: number
}>()

// export const openUserSidebarAction = createStandardAction('LAYOUT/OPEN_USER_PROFILE_SIDEBAR')<void>()

// export const closeUserSidebarAction = createStandardAction('LAYOUT/CLOSE_USER_PROFILE_SIDEBAR')<void>()

export const setconversationNameInOpenChatAction = createStandardAction(
  'LAYOUT/SET_CONVERSATION_NAME_IN_OPEN_CHAT',
)<{
  conversationName: string
}>()
