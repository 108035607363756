import React from 'react'
import { Row, Select } from 'antd'
import styled from 'styled-components'

const { Option } = Select

type Props = {
  defaultValue: number
  totalRows: number
  choices?: number[]
  onChange: (value: number) => void
  changePerPage?: boolean
}

const PageNumber: React.FunctionComponent<Props> = ({
  totalRows,
  defaultValue,
  choices = [10, 25, 50, 100, 200],
  onChange,
  changePerPage = true,
}) => {
  // No point in rendering dropdown that has no choices yet.
  if (!choices.length) {
    return null
  }

  return (
    <Row className="pagination-show-action" style={{ width: '100%' }}>
      แสดง
      {changePerPage ? (
        <Select
          defaultValue={defaultValue}
          style={{ width: 'auto', margin: '0 16px' }}
          onChange={(value: number) => onChange(value)}
        >
          {choices.map(page => (
            <Option key={page} className="show-more-option-item" value={page}>
              {page}
            </Option>
          ))}
        </Select>
      ) : (
        <DefaultValue>หน้าละ {defaultValue} </DefaultValue>
      )}
      จาก {totalRows} รายการ
    </Row>
  )
}

const DefaultValue = styled.span`
  padding-right: 5px;
`

export default PageNumber
