import React from 'react'
import { Result } from 'antd'
import ErrorLayout from 'Pages/Layout/ErrorLayout'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { logoutAction } from 'Store/user/actions'
import { useDispatch } from 'react-redux'

type Props = {} & RouteComponentProps

const NoPermission: React.FunctionComponent<Props> = () => {
  const dispatch = useDispatch()

  const gotoLoginPage = (): void => {
    dispatch(logoutAction())
  }

  return (
    <ErrorLayout btnText="กลับไปหน้าเข้าสู่ระบบ" onClick={gotoLoginPage}>
      <Result status="500" title="บัญชีนี้ไม่มีสิทธิ์ใช้งานระบบ " subTitle="กรุณาเข้าสู่ระบบด้วยบัญชีอื่น" />
    </ErrorLayout>
  )
}

export default withRouter(NoPermission)
