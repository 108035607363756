/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import LoginLayout from 'Pages/Layout/LoginLayout'
import { RootState } from 'Store'
import { connect, useDispatch } from 'react-redux'
import { Input, FormItem } from 'Components/form'
import { LoginReq } from 'Store/user/types'
import { loginAPI } from 'Store/user/api'
import { selectEvent, selectLoginData } from 'Store/user/selector'
import { clearEventAction, updateLoginDataAction } from 'Store/user/actions'
import EventModal from 'Components/modal/EventModal'
import InputPassword from 'Components/form/InputPassword'

type Props = {} & RouteComponentProps & ReduxProps

const Login: React.FunctionComponent<Props> = props => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const { event, history, loginData } = props

  const dispatch = useDispatch()

  const onLogin = (): void => {
    const req: LoginReq = {
      email: email?.trim() || '',
      password: password,
    }

    dispatch(loginAPI(req))
  }

  const validateLogin = (): void => {
    if (!email.trim()) {
      setEmailError(true)
      return
    }

    if (!password.trim()) {
      setPasswordError(true)
      return
    }

    onLogin()
  }

  const onChangeEmail = (val: string): void => {
    setEmail(val)
    if (val && emailError) {
      setEmailError(false)
    }
  }

  const onChangePassword = (val: string): void => {
    setPassword(val)
    if (val && passwordError) {
      setPasswordError(false)
    }
  }

  const errorStatus = (isError: boolean): string => {
    return isError ? 'error' : ''
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onSuccess = (): void => {
    clearEvent()
    history.push('/')
  }

  return (
    <>
      <EventModal event={[event]} enable={true} successCalback={onSuccess} errorCalback={clearEvent} />
      <LoginLayout title={'เข้าสู่ระบบ'} onSubmit={validateLogin}>
        <FormItem label="" status={errorStatus(emailError)} error="กรุณาระบุอีเมล">
          <Input placeholder="อีเมล" name="email" value={email} onChange={onChangeEmail} />
        </FormItem>
        <FormItem label="" status={errorStatus(passwordError)} error="กรุณาระบุรหัสผ่าน">
          <InputPassword
            placeholder="รหัสผ่าน"
            name="password"
            type="password"
            value={password}
            onChange={onChangePassword}
          />
        </FormItem>
      </LoginLayout>
    </>
  )
}

const mapStateToProps = (state: RootState): any => {
  return {
    event: selectEvent(state),
    loginData: selectLoginData(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(Login)
