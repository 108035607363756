import { Avatar } from 'antd'
import theme from 'Components/common/Theme'
import { appConfig } from 'config'
import React from 'react'

type Props = {
  agentImage?: string
  width?: number
  height?: number
}

const LogoAgent = (props: Props) => {
  const { agentImage = '', width = 60 } = props
  const src = appConfig.assetCdnEndpoint + agentImage

  return (
    <Avatar
      shape={'circle'}
      size={width}
      src={src}
      className="circle"
      draggable={false}
      alt="Chaiyo"
      style={{
        //border: `2px solid ${theme.colors.white}`,
        boxShadow: '2px 2px 5px 5px rgb(0 0 0 / 10%)',
      }}
    ></Avatar>
  )
}

export default LogoAgent
