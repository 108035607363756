export const appConfig = {
  env: 'development',
  apiEndpoint: 'https://api-dev.chaiyoagent.com',
  assetCdnEndpoint: 'https://api-dev.chaiyoagent.com/api/v1/cdn/file?key=',
  socketEndpoint: 'https://talkapi-dev.chaiyoagent.com',
  //socketEndpoint: 'http://192.168.1.112:3000',
  publicFrontEndpoint: 'https://ams-dev.chaiyoagent.com',
}

export default appConfig
