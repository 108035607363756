export const downloadCSVFile = (content: string, fileName: string = 'download'): void => {
  const dataURI = 'data:text/csv;charset=utf-8,\uFEFF' + encodeURIComponent(content)

  const anchor = document.createElement('a')
  anchor.href = dataURI

  anchor.download = fileName
  anchor.setAttribute('style', 'visibility:hidden')

  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}

export default downloadCSVFile
