import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action } from './types'
import { initEvent, initMeta } from 'Store/common/common.reducer'
import parseErrorMessage from 'helpers/parseErrorMessage'
import { convertMetaData } from 'Store/common/common.function'

const initialState: State = {
  addressList: {
    event: cloneDeep(initEvent),
    data: [],
  },
  verifyToken: {
    event: cloneDeep(initEvent),
    data: undefined,
  },
  actionEvent: cloneDeep(initEvent),
}

export default function(state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.registerAction.request):
        draft.actionEvent = cloneDeep(initEvent)
        draft.actionEvent.IsLoading = true
        return draft
      case getType(actions.registerAction.success):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = undefined
        draft.actionEvent.SubmitSuccess = true
        draft.actionEvent.MessageSuccess = 'สมัครตัวแทนสำเร็จ'
        return draft
      case getType(actions.registerAction.failure):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.getAddressAction.request):
        draft.addressList.event = cloneDeep(initEvent)
        draft.addressList.event.IsLoading = true
        return draft
      case getType(actions.getAddressAction.success):
        response = action.payload
        draft.addressList.event.IsLoading = false
        draft.addressList.event.SubmitSuccess = true
        draft.addressList.data = response.data
        return draft
      case getType(actions.getAddressAction.failure):
        draft.addressList.event.IsLoading = false
        draft.addressList.event.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.getVerifyTokenAction.request):
        draft.verifyToken.event = cloneDeep(initEvent)
        draft.verifyToken.event.IsLoading = true
        draft.verifyToken.data = undefined
        return draft
      case getType(actions.getVerifyTokenAction.success):
        response = action.payload
        draft.verifyToken.event.IsLoading = false
        draft.verifyToken.event.SubmitSuccess = true
        draft.verifyToken.data = response.data
        return draft
      case getType(actions.getVerifyTokenAction.failure):
        draft.verifyToken.event.IsLoading = false
        draft.verifyToken.event.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.updatePasswordAction.request):
        draft.actionEvent = cloneDeep(initEvent)
        draft.actionEvent.IsLoading = true
        return draft
      case getType(actions.updatePasswordAction.success):
        response = action.payload
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitSuccess = true
        draft.actionEvent.MessageSuccess = 'เปลี่ยนรหัสผ่านสำเร็จ!'
        return draft
      case getType(actions.resetPasswordAction.request):
        draft.actionEvent = cloneDeep(initEvent)
        draft.actionEvent.IsLoading = true
        return draft
      case getType(actions.resetPasswordAction.success):
        response = action.payload
        if (response?.status.code === 200) {
          draft.actionEvent.IsLoading = false
          draft.actionEvent.SubmitSuccess = true
          draft.actionEvent.MessageSuccess = 'ส่งอีเมลสำเร็จ!'
        } else {
          draft.actionEvent.IsLoading = false
          draft.actionEvent.SubmitError = response?.message
        }
        return draft
      case getType(actions.updatePasswordAction.failure):
      case getType(actions.resetPasswordAction.failure):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.clearEventAction):
        draft.addressList.event = cloneDeep(initEvent)
        draft.verifyToken.event = cloneDeep(initEvent)
        draft.actionEvent = cloneDeep(initEvent)
        return draft

      default:
        return state
    }
  })
}
