import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import RegisterForm from './components/Form'
import DocumentsForm from './components/Documents'
import Success from './components/Success'
import { registerAPI } from 'Store/register/api'
import { toDateQuery } from 'helpers/format'
import { selectActionEvent } from 'Store/register/selector'
import { EventModal } from 'Components/modal'
import { clearEventAction } from 'Store/register/actions'
import { RegisterReq } from 'Store/register/types'
import { AgentType } from 'helpers/constanst'

type Props = {} & RouteComponentProps & ReduxProps

const AgentRegister: React.FunctionComponent<Props> = props => {
  const { event } = props
  const dispatch = useDispatch()
  const [activeStep, setActiveStep] = useState(1)
  const [formData, setFormData] = useState({
    agent_type: AgentType.INDIVIDUAL,
    corporate_no: '',
    corporate_name: '',
    sex: '',
    prefix: '',
    first_name: '',
    last_name: '',
    birthday: '',
    address: '',
    postcode: '',
    sub_district_id: 0,
    district_id: 0,
    province_id: 0,
    email: '',
    mobile: '',
  } as RegisterReq)

  const onUpdateData = (value: any, key: string) => {
    if (key === 'mobile' && value.length > 10) return
    if (key === 'postcode' && value.length > 5) return
    if (key === 'corporate_no' && value.length > 13) return
    setFormData({
      ...formData,
      [key]: value,
    })
  }

  const formatReq = () => {
    const req = {
      ...formData,
      sub_district_id: formData.address_item?.sub_district.id || 0,
      district_id: formData.address_item?.district.id || 0,
      province_id: formData.address_item?.province.id || 0,
      birthday: toDateQuery(formData.birthday),
    } as RegisterReq
    delete req.address_item
    return req
  }

  const onRegister = () => {
    dispatch(registerAPI(formatReq()))
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onRegisterSuccess = (): void => {
    clearEvent()
    setActiveStep(3)
  }

  const getPage = () => {
    switch (activeStep) {
      case 1:
        return (
          <RegisterForm
            onNext={() => {
              setActiveStep(2)
            }}
            onUpdateData={onUpdateData}
            formData={formData}
          />
        )
      case 2:
        return (
          <DocumentsForm
            onUpdateData={onUpdateData}
            formData={formData}
            onNext={onRegister}
            onBack={() => {
              setActiveStep(1)
            }}
          />
        )
      case 3:
        return <Success />
      default:
        return null
    }
  }

  return (
    <MyLayout>
      <EventModal
        event={[event]}
        enable={true}
        successCalback={onRegisterSuccess}
        errorCalback={clearEvent}
      />
      <Content>{getPage()}</Content>
      <Footer> ©All Rights Reserved ChaiYo 2021</Footer>
    </MyLayout>
  )
}

const MyLayout = styled.div`
  background: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  // border-left: 10px solid ${(props: { theme: MyTheme }): string => props.theme.colors.primary1};
  // border-right: 10px solid ${(props: { theme: MyTheme }): string => props.theme.colors.primary1};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  position: fixed;
  overflow-y: scroll;
`

const Content = styled.div`
  padding: 70px 40px;
  min-height: calc(100vh - 70px);
`

const Footer = styled.div`
  margin-top: 20px;
  width: 100%;
  background: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  text-align: center;
  padding: 15px 0;
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectActionEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(AgentRegister)
