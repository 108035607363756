const colors = {
  primary: '#2B328C', //blue
  primary1: '#FFD200', //yellow
  primary2: '#EF3742', //red
  drakGrey: '#575756',
  darkBlue: '#0A043C',
  grey: '#979797',
  greyBorder: '#D8D8D8',
  greyBG: '#C4C4C4',
  white: '#fff',
  whiteInactive: '#C0BEFF',
  black: '#000',
  whitebg: '#F0EFF5',
  secondary: '#FDE4D2',
  transparent: 'transparent',
  success: '#56AB69',
  error: '#f5222d',
  warning: '#faad14',
  disable: '#999999',
  backgroundGradient: 'linear-gradient(180deg, #F37720 27.6%, #FFB800 100%)',
  green: '#569C30',
}

export default colors
