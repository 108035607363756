import React from 'react'
import styled from 'styled-components'
import { Modal, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

interface Props {
  isShow: boolean
  time?: number
  text?: string
  callback?: () => void
}

const css: any = {
  page: {
    textAlign: 'center',
    fontSize: '20px',
    color: 'white',
  },
  spin: {
    color: 'white',
    fontSize: '70px',
  },
}

const LoadingView = (props: Props) => {
  const { isShow = false } = props

  return (
    <Modal
      wrapClassName="error-box"
      style={css.page}
      visible={isShow}
      closable={false}
      mask={false}
      footer={null}
      centered
    >
      <Body>
        <LoadingSpin size="large" indicator={antIcon} style={css.spin} />
      </Body>
    </Modal>
  )
}

const Body = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 125px;
`

const LoadingSpin = styled(Spin)`
  span {
    font-size: 70px !important;
  }
`

export default LoadingView
