import produce from 'immer'
import { getType } from 'typesafe-actions'
import * as actions from './example.actions'
import { ExampleAction, ExampleState } from './example.types'

const initialState: ExampleState = {
  data: [],
}

export default function(state: ExampleState = initialState, action: ExampleAction) {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.sampleAsyncAction.request):
        //do something
        draft.data = []
        return draft
      default:
        return state
    }
  })
}
