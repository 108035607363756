import withAPIAction from 'helpers/withAPIAction'
import {
  DetailReq,
  GetCustomerReq,
  ListReq,
  UpdateCustomerReq,
  UpdateCustomerSocketReq,
  UpdateReq,
  UpdateStatusReq,
  CountReq,
  BrandReq,
  BrandSlugReq,
  LogReq,
  SendToBranchReq,
  ChatTaskReq,
  ChatTaskReplyReq,
} from './types'
import {
  getCustomerInfoAction,
  getDetailAction,
  getListAction,
  updateAction,
  updateCustomerAction,
  updateCustomerSocketAction,
  updateCustomerStatusAction,
  countCustomerTabAction,
  getBrandListAction,
  getBrandBySlugListAction,
  getTaskLogsAction,
  updateToBranchAction,
  updateChatTaskAction,
  updateChatTaskReplyAction,
} from './actions'
import { APIPath } from 'services/APIPath'
import { appConfig } from 'config'
import { getRequestString } from 'helpers/format'

export const getCustomerByUUIDAPI = (data: GetCustomerReq, token: string) => {
  const endPoint = APIPath.getChaiyoCustomerByUUID.replace(':uuid', data.uuid.toString())
  return withAPIAction<GetCustomerReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getCustomerInfoAction)
}

export const updateCustomerAPI = (data: UpdateCustomerReq, uuid: string, token: string) => {
  const endPoint = APIPath.updateChaiyoCustomerInfo.replace(':uuid', uuid)
  // let req = cloneDeep(data)
  // delete req.uuid
  return withAPIAction<UpdateCustomerReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateCustomerAction)
}

export const updateCustomerSocketAPI = (data: UpdateCustomerSocketReq) => {
  return withAPIAction<UpdateCustomerSocketReq>({
    method: 'PUT',
    baseUrl: appConfig.socketEndpoint,
    path: APIPath.updateCustomerSocketInfo,
    data,
  })(updateCustomerSocketAction)
}

export const getCustomerTaskListAPI = (data: ListReq, token: string) => {
  return withAPIAction<ListReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: `${APIPath.chaiyoCustomerTaskList}?${getRequestString(data)}`,
    data,
    token,
  })(getListAction)
}

export const getCustomertDetailAPI = (data: DetailReq, token: string) => {
  const endPoint = APIPath.chaiyoCustomerTaskByID.replace(':id', data.id.toString())
  return withAPIAction<DetailReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getDetailAction)
}

export const updateCustomerTaskAPI = (data: UpdateReq, token: string) => {
  const endPoint = APIPath.updateChaiyoCustomerTask.replace(':id', data.id.toString())
  return withAPIAction<UpdateReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateAction)
}

export const updateCustomerTaskStatusAPI = (data: UpdateStatusReq, token: string) => {
  const endPoint = APIPath.updateChaiyoCustomerTaskStatus.replace(':id', data.id.toString())
  return withAPIAction<UpdateStatusReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateCustomerStatusAction)
}

export const updateToBranchAPI = (data: SendToBranchReq, token: string) => {
  const endPoint = APIPath.updateChaiyoCustomerTaskStatus.replace(':id', data.id.toString())
  return withAPIAction<SendToBranchReq>({
    method: 'PUT',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(updateToBranchAction)
}

export const countCustomerAPI = (data: CountReq, token: string) => {
  return withAPIAction<CountReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.countChaiyoCustomer,
    data,
    token,
  })(countCustomerTabAction)
}

export const getBrandListAPI = (data: BrandReq, token: string) => {
  return withAPIAction<BrandReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: APIPath.getBrandList,
    data,
    token,
  })(getBrandListAction)
}

export const getBrandBySlugListAPI = (data: BrandSlugReq, token: string) => {
  const endPoint = APIPath.getBrandBySlugList.replace(':slug', data.slug)
  return withAPIAction<BrandSlugReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getBrandBySlugListAction)
}

export const getTaskLogsAPI = (data: LogReq, token: string) => {
  const endPoint = APIPath.getTaskLogs.replace(':id', data.id.toString())
  return withAPIAction<LogReq>({
    method: 'GET',
    baseUrl: appConfig.apiEndpoint,
    path: endPoint,
    data,
    token,
  })(getTaskLogsAction)
}

export const updateChatTaskAPI = (data: ChatTaskReq) => {
  return withAPIAction<ChatTaskReq>({
    method: 'POST',
    baseUrl: appConfig.socketEndpoint,
    path: APIPath.updateChatTask,
    data,
  })(updateChatTaskAction)
}

export const updateChatTaskReplyAPI = (data: ChatTaskReplyReq) => {
  return withAPIAction<ChatTaskReplyReq>({
    method: 'POST',
    baseUrl: appConfig.socketEndpoint,
    path: APIPath.updateChatTaskReply,
    data,
  })(updateChatTaskReplyAction)
}
