import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Col, Layout, Row } from 'antd'
import { connect } from 'react-redux'
import { MyTheme } from 'Components/common/Theme'
import RootState from 'typings/RootState'
import { selectUserProfile } from 'Store/user/selector'

const { Header } = Layout

type Props = ReduxProps & {}

const ClientNavBar: React.FunctionComponent<Props> = props => {
  return (
    <HeaderArea className="header">
      <Row justify="center" align="middle" gutter={[8, 8]}>
        <Col></Col>
      </Row>
    </HeaderArea>
  )
}

const HeaderArea = styled(Header)`
  height: 80px;
  line-height: 80px;
  background-color: ${(props: { theme: MyTheme }): string => props.theme.colors.primary};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.white};
  margin-bottom: 2px;
  padding: 0 0 0 30px;
  .ant-menu-horizontal {
    border: none;
    background-color: #f37b30;
  }
  .ant-menu-item {
    margin: 0;
  }
  @media only screen and (max-width: 720px) {
    padding: 0 10px;
  }
`

const mapStateToProps = (state: RootState): any => {
  return {
    user: selectUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps)(withRouter(ClientNavBar))
