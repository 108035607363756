import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import { ActionRes, DetailReq, DetailRes, UpdateReq, TempleteReq, TempleteRes } from './types'

export const getDetailAction = createAsyncAction(
  'GEETING_MESSAGE/GET_GEETING_MESSAGE_DETAIL_REQ',
  'GEETING_MESSAGE/GET_GEETING_MESSAGE_DETAIL_SUCCESS',
  'GEETING_MESSAGE/GET_GEETING_MESSAGE_DETAIL_FAILURE',
)<DetailReq, DetailRes, Error>()

export const updateAction = createAsyncAction(
  'GEETING_MESSAGE/UPDATE_GEETING_MESSAGE_REQ',
  'GEETING_MESSAGE/UPDATE_GEETING_MESSAGE_SUCCESS',
  'GEETING_MESSAGE/UPDATE_GEETING_MESSAGE_FAILURE',
)<UpdateReq, ActionRes, Error>()

export const getTemplateAction = createAsyncAction(
  'GEETING_MESSAGE/GET_TEMPLATE_MESSAGE_REQ',
  'GEETING_MESSAGE/GET_TEMPLATE_MESSAGE_SUCCESS',
  'GEETING_MESSAGE/GET_TEMPLATE_MESSAGE_FAILURE',
)<TempleteReq, TempleteRes, Error>()

export const clearEventAction = createStandardAction('GEETING_MESSAGE/CLEAR_EVENT')<void>()
