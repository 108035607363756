import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { Checkbox, Col, message, Row, Space } from 'antd'
import { PrimaryButton } from 'Components/button'
import {
  formValidation,
  formatDateTime,
  isFormRequired,
  isFormError,
  toDateQuery,
  formatDateWithoutTime,
  formatYearold,
  validateEmail,
  mergeArray,
  getMenuSelect,
  getSaleTaskStatus,
} from 'helpers/format'
import { AssignReq, DetailReq, UpdateReq } from 'Store/customer-file/types'
import {
  selectDetail,
  selectDetailEvent,
  selectIsRefresh,
  selectTaskEvent,
} from 'Store/customer-file/selector'
import { clearEventAction } from 'Store/customer-file/actions'
import EventModal from 'Components/modal/EventModal'
import LoadingView from 'Components/modal/LoadingView'
import { DetailDrawer } from 'Components/common/MainLayout'
import { ConfirmDailog } from 'Components/modal'
import { CustomerStatus, menuSex } from 'helpers/constanst'
import { selectLoginData, selectUserProfile } from 'Store/user/selector'
import { InfoItem } from 'Components/text'
import BoxWrapper from 'Components/common/Layout/BoxWrapper'
import { AppDateItem, AppInput, AppSelect } from 'Components/form'
import {
  getCustomerFileDetailAPI,
  assignCustomerFileAPI,
  updateCustomerFileInfoAPI,
} from 'Store/customer-file/api'
import { EditOutlined } from '@ant-design/icons'
import LinkText from 'Components/text/LinkText'
import { appConfig } from 'config'
import { Theme } from 'Components/common'
import { selectSaleTaskStatusList } from 'Store/customer/selector'
import { SaleStatusReq } from 'Store/customer/types'
import { getSaleTaskStatusAPI } from 'Store/customer/api'

type Props = ReduxProps & {
  visible: boolean
  onCloseDrawer: () => void
  onSuccess?: () => void
  uuid?: string
}

const customerFileTaskDetail: React.FunctionComponent<Props> = props => {
  const {
    onCloseDrawer,
    visible,
    event,
    onSuccess = () => {},
    uuid = '',
    detail,
    detailEvent,
    loginData,
    userProfile,
    isRefresh,
    saleTaskStatus,
  } = props

  console.log('saleTaskStatus-->',saleTaskStatus)

  const initForm = {} as UpdateReq
  const [formData, setFormData] = useState(cloneDeep(initForm))
  const [formError, setFormError] = useState([] as string[])
  const [openCaseDialog, setOpenCaseDialog] = useState(false)
  const [confirmAssign, setConfirmAssign] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const isNoneAssign = !detail?.user_id
  const isOwnTask = detail?.user_id === userProfile?.id
  const dispatch = useDispatch()
  const nomalRequireFeild = ['first_name', 'last_name', 'mobile'] as string[]
  const branchRequireFeild = ['branch_email']
  const requireFeild = formData.is_branch
    ? mergeArray([nomalRequireFeild, branchRequireFeild])
    : nomalRequireFeild

  useEffect(() => {
    if (!visible) {
      setFormError([])
      setEditMode(false)
    }
    if (visible && uuid) {
      onGetDetail()
      onGetMasterData()
    }
  }, [visible])

  useEffect(() => {
    if (uuid !== '') {
      const form = {
        birthday: detail?.birthday || '',
        branch_detail: detail?.insure?.branch_detail || '',
        branch_email: detail?.insure?.branch_email || '',
        car_brand: detail?.insure?.car_brand || '',
        car_model: detail?.insure?.car_model || '',
        car_year: detail?.insure?.car_year || '',
        email: detail?.email || '',
        first_name: detail?.first_name || '',
        is_branch: detail?.insure?.is_branch || false,
        last_name: detail?.last_name || '',
        mobile: detail?.mobile || '',
        note: detail?.insure?.note || '',
        qr_reference: '',
        sex: detail?.sex || '',
        status: detail?.status || CustomerStatus.DRAFT,
        task_status: detail?.insure?.task_status || '',
        task_status_id: detail?.insure?.task_status_id || '',
      } as UpdateReq
      setFormData({ ...form })
    }
  }, [detail])

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const onGetDetail = (): void => {
    const req: DetailReq = {
      uuid: uuid,
    }
    dispatch(getCustomerFileDetailAPI(req, loginData?.access_token || ''))
  }

  const onAssignTask = (): void => {
    const req: AssignReq = {
      uuid,
    }
    dispatch(assignCustomerFileAPI(req, uuid, loginData?.access_token || ''))
  }

  const onGetMasterData = (): void => {
    const req: SaleStatusReq = {}
    dispatch(getSaleTaskStatusAPI(req, loginData?.access_token || ''))
  }

  const onUpdate = (status: CustomerStatus): void => {
    const req: UpdateReq = {
      ...formData,
      birthday: formData.birthday ? toDateQuery(formData.birthday) : '',
      uuid,
      status,
      app_no: detail?.app_no || '',
    }
    dispatch(updateCustomerFileInfoAPI(req, loginData?.access_token || ''))
  }

  const onOpenCase = (): void => {
    onUpdate(CustomerStatus.OPEN)
  }

  const onSave = () => {
    if (!validateForm()) return
    onUpdate(CustomerStatus.DRAFT)
  }

  const onUpdateData = (val: any, key: string) => {
    if (key === 'mobile' && val.length > 10) return
    setFormData({
      ...formData,
      [key]: val,
    })
  }

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    if (formData.email) {
      if (!validateEmail(formData.email)) {
        error.push('email_format')
      }
    }
    if (formData.branch_email) {
      if (!validateEmail(formData.branch_email)) {
        error.push('branch_email_format')
      }
    }
    setFormError([...error])
    return error.length > 0 ? false : true
  }

  const isRequired = (name: string) => {
    return isFormRequired(requireFeild, name)
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const onClickOpenCase = () => {
    if (!validateForm()) {
      message.error('โปรดตรวจสอบข้อมูล และระบุข้อมูลให้ครบถ้วนก่อนทำการเปิดเคส')
      return
    }
    setOpenCaseDialog(true)
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onEventSuccess = (): void => {
    clearEvent()
    onGetDetail()
    setOpenCaseDialog(false)
    setConfirmAssign(false)
    onSuccess()
    if (editMode) {
      setEditMode(false)
    } else {
      onCloseDrawer()
    }
  }

  useEffect(() => {
    if (isRefresh) {
      onGetDetail()
      onSuccess()
      setConfirmAssign(false)
      onCloseDrawer()
      clearEvent()
    }
  }, [isRefresh])

  const ConfirmAssignModal = (
    <ConfirmDailog
      title={`ยืนยันการรับงาน ?`}
      description={`คุณต้องการ “รับงาน” เพื่อโอนงานไปแถบ “มอบหมายให้ฉัน” และปรับปรุงข้อมูลเพิ่มเติมใช่หรือไม่`}
      isShow={confirmAssign}
      confirmBtnTxt="รับงาน"
      handleOk={onAssignTask}
      handleCancel={() => setConfirmAssign(false)}
    />
  )

  // const ConfirmOpenCaseModal = (
  //   <ConfirmDailog
  //     title={`คุณแน่ใจหรือว่าต้องการเปิดเคสใหม่ ?`}
  //     description="กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนยืนยันการเปิดเคส"
  //     isShow={confirmModal}
  //     confirmBtnTxt="ยืนยันเปิดเคส"
  //     handleOk={() => onUpdateCustomer(CustomerStatus.OPEN)}
  //     handleCancel={() => setConfirmModal(false)}
  //   />
  // )

  const showOpenCaseButton = detail?.insure?.task_status !== 'อยู่ระหว่างดำเนินการ – ติดตามลูกค้า' && detail?.insure?.task_status !== ""
  console.log('detail',detail)
  const FooterContent = (
    <Space size="middle" align="baseline">
      {isNoneAssign && (
        <PrimaryButton text="รับงาน" btnType="Primary" onClick={() => setConfirmAssign(true)} />
      )}
      {(isOwnTask && showOpenCaseButton) && <PrimaryButton text="เปิดเคส" btnType="Primary" onClick={onClickOpenCase} />}
    </Space>
  )

  const renderInfo = (title: string, value: any, span: number = 15) => {
    return <InfoItem title={title} value={value} span={span} />
  }

  const renderInfoRow = (item1: any, item2: any) => {
    return (
      <Col span={24}>
        <Row justify="space-between">
          <Col md={!item2 ? 24 : 12} xs={24}>
            {item1}
          </Col>
          <Col md={12} xs={24}>
            {item2}
          </Col>
        </Row>
      </Col>
    )
  }

  const ConfirmOpenCaseDailog = (
    <ConfirmDailog
      title="ยืนยันเปิดเคสใหม่"
      description="คุณแน่ใจหรือว่าต้องการเปิดเคสใหม่?"
      confirmBtnTxt="ยืนยัน"
      isShow={openCaseDialog}
      handleOk={onOpenCase}
      handleCancel={() => setOpenCaseDialog(false)}
      loading={event.IsLoading}
    />
  )

  const customerFormLeft = (
    <Row justify="space-between" gutter={[16, 1]}>
      <Col md={12} xs={24}>
        <AppInput
          name="first_name"
          label="ชื่อ"
          isRequired={isRequired('first_name')}
          isError={isError('first_name')}
          value={formData.first_name}
          onChange={onUpdateData}
          resProps={{ disabled: true }}
        />
      </Col>
      <Col md={12} xs={24}>
        <AppInput
          name="last_name"
          label="นามสกุล"
          isRequired={isRequired('last_name')}
          isError={isError('last_name')}
          value={formData.last_name}
          onChange={onUpdateData}
          resProps={{ disabled: true }}
        />
      </Col>
      <Col md={12} xs={24}>
        <AppInput
          name="mobile"
          label="เบอร์โทรศัพท์"
          placeHolder="ตัวอย่าง 0888888888"
          isRequired={isRequired('mobile')}
          isError={isError('mobile')}
          value={formData.mobile}
          onChange={onUpdateData}
          resProps={{ disabled: true }}
        />
      </Col>
      <Col md={12} xs={24}>
        <AppSelect
          name="sex"
          label="เพศ"
          isRequired={isRequired('sex')}
          isError={isError('sex')}
          value={formData.sex}
          onChange={onUpdateData}
          menu={menuSex}
        />
      </Col>
      <Col md={12} xs={24}>
        <AppDateItem
          label="วันเดือนปีเกิด"
          placeHolder="วันเดือนปีเกิด"
          isRequired={isRequired('birthday')}
          isError={isError('birthday')}
          onChange={(date: string) => onUpdateData(date, 'birthday')}
          date={formData.birthday}
          allowClear={false}
          picker="date"
        />
      </Col>
      <Col md={12} xs={24}>
        <AppInput
          name="email"
          label="อีเมล"
          errorMsg={isError('email_format') ? 'รูปแบบอีเมลให้ถูกต้อง' : 'อีเมล'}
          isRequired={isRequired('email') || formData.email !== ''}
          isError={isError('email') || isError('email_format')}
          value={formData.email}
          onChange={onUpdateData}
        />
      </Col>
      <Col md={12} xs={24}>
        <AppInput
          name="car_brand"
          label="ยี่ห้อรถ"
          isRequired={isRequired('car_brand')}
          isError={isError('car_brand')}
          value={formData.car_brand}
          onChange={onUpdateData}
          resProps={{ disabled: true }}
        />
      </Col>
      <Col md={12} xs={24}>
        <AppInput
          name="car_model"
          label="รุ่นรถ"
          isRequired={isRequired('car_model')}
          isError={isError('car_model')}
          value={formData.car_model}
          onChange={onUpdateData}
          resProps={{ disabled: true }}
        />
      </Col>
      <Col md={12} xs={24}>
        <AppInput
          name="car_year"
          label="ปีรถ"
          isRequired={isRequired('car_year')}
          isError={isError('car_year')}
          value={formData.car_year}
          onChange={onUpdateData}
          resProps={{ disabled: true }}
        />
      </Col>
      <Col md={12} xs={24}>
        <AppInput
          label="หมายเหตุ"
          placeHolder="หมายเหตุ (ถ้ามี)"
          isRequired={isRequired('note')}
          isError={isError('note')}
          name="note"
          value={formData.note}
          onChange={onUpdateData}
        />
      </Col>
      <Col md={24} xs={24}>
        <CheckBoxWrapper>
          <Space size="small">
            <Checkbox
              onChange={e => onUpdateData(e.target.checked, 'is_branch')}
              checked={formData.is_branch || false}
            />
            ต้องการส่งข้อมูลไปยังสาขา
            <a href={appConfig.servicePointLink} target="_bank">
              <LinkText color={Theme.colors.primary} onClick={() => {}}>
                (Service Point)
              </LinkText>
            </a>
          </Space>
        </CheckBoxWrapper>
      </Col>
      {formData.is_branch && (
        <>
          <Col md={12} xs={24}>
            <AppInput
              name="branch_email"
              label="อีเมลสาขา"
              placeHolder="ตัวอย่าง : mail001@email.scb.co.th"
              errorMsg={isError('branch_email_format') ? 'รูปแบบอีเมลให้ถูกต้อง' : 'อีเมล'}
              isRequired={isRequired('branch_email')}
              isError={isError('branch_email') || isError('branch_email_format')}
              value={formData.branch_email}
              onChange={onUpdateData}
            />
          </Col>
          <Col md={12} xs={24}>
            <AppInput
              name="branch_detail"
              label="ข้อมูลสาขา"
              isRequired={isRequired('branch_detail')}
              isError={isError('branch_detail')}
              value={formData.branch_detail}
              onChange={onUpdateData}
            />
          </Col>
        </>
      )}
        <Col md={24} xs={24}>
        <p>ข้อมูลสถานะการขาย</p>
        <Row>
            <Col md={12} xs={24}>
              <AppSelect
                name="task_status_id"
                label="สถานะการขาย"
                isRequired={isRequired('task_status_id')}
                isError={isError('task_status_id')}
                value={formData.task_status_id}
                onChange={onUpdateData}
                menu={getMenuSelect(saleTaskStatus, 'status', 'id')}
              />
            </Col>
          </Row>
        </Col>
    </Row>
  )

  const taskInfo = (
    <BoxWrapper title="รายละเอียดข้อมูล">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(renderInfo('วันที่สร้างข้อมูล', formatDateTime(detail?.created_at || '')), null)}
        {renderInfoRow(
          renderInfo(
            'ผู้รับผิดชอบ',
            detail?.user_id
              ? `${detail?.user?.profile?.first_name} ${detail?.user?.profile?.last_name} (ทีม ${detail?.agent
                  ?.agent_name || '-'})`
              : '',
          ),
          null,
        )}
      </Row>
    </BoxWrapper>
  )

  const customerForm = (
    <BoxWrapper title="ข้อมูลลูกค้า">
      <Row gutter={[16, 16]} justify="center">
        <Col md={24} xs={24}>
          {customerFormLeft}
        </Col>
        {/* <Col md={12} xs={24}>
          {customerFormRight}
        </Col> */}
        <Col>
          <Space>
            <PrimaryButton text="ยกเลิก" btnType="Cancel" onClick={() => setEditMode(false)} />
            <PrimaryButton text="บันทึกข้อมูล" onClick={onSave} />
          </Space>
        </Col>
      </Row>
    </BoxWrapper>
  )

  const editButton = () => {
    return (
      <PrimaryButton
        text="แก้ไขข้อมูล"
        icon={<EditOutlined />}
        btnType="Primary2"
        onClick={() => setEditMode(true)}
      />
    )
  }

  const customerInfo = (
    <BoxWrapper title="ข้อมูลลูกค้า" right={isOwnTask && editButton()}>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col md={12} xs={24}>
          {renderInfoRow(renderInfo('ชื่อ*', detail?.first_name || ''), null)}
          {renderInfoRow(renderInfo('นามสกุล*', detail?.last_name || ''), null)}
          {renderInfoRow(renderInfo('เบอร์โทรศัพท์*', detail?.mobile || ''), null)}
          {renderInfoRow(renderInfo('อีเมล', detail?.email || ''), null)}
          {detail?.sex && renderInfoRow(renderInfo('เพศ', detail?.sex || ''), null)}
          {detail?.birthday &&
            renderInfoRow(
              renderInfo(
                'วันเดือนปีเกิด',
                detail?.birthday
                  ? `${formatDateWithoutTime(detail?.birthday || '')} (อายุ ${formatYearold(
                      detail?.birthday || '',
                    )} ปี)`
                  : '',
              ),
              null,
            )}
          {renderInfoRow(renderInfo('Application No.', detail?.app_no || ''), null)}
        </Col>
        <Col md={12} xs={24}>
          {renderInfoRow(renderInfo('ยี่ห้อรถ', detail?.insure?.car_brand || ''), null)}
          {renderInfoRow(renderInfo('รุ่นรถ', detail?.insure?.car_model || ''), null)}
          {renderInfoRow(renderInfo('ปีรถ', detail?.insure?.car_year || ''), null)}
          {renderInfoRow(renderInfo('หมายเหตุ', detail?.insure?.note || ''), null)}
        </Col>
        {!isNoneAssign && (
          <Col md={12} xs={24}>
          {renderInfoRow(renderInfo('สถานะการขาย', detail?.insure?.task_status || ''), null)}
        </Col>
        )}
       
      </Row>
    </BoxWrapper>
  )

  const branchInfo = (
    <BoxWrapper title="การส่งข้อมูลไปยังสาขา">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(
          renderInfo('อีเมลสาขา', detail?.insure?.branch_email || ''),
          renderInfo('ข้อมูลสาขา', detail?.insure?.branch_detail || ''),
        )}
      </Row>
    </BoxWrapper>
  )

  const title = (
    <Space align="start">
      <div>
        <Title>
          ชื่อลูกค้า : {detail?.first_name} {detail?.last_name}
        </Title>
      </div>
    </Space>
  )

  console.log('detail?.insure?.is_branch', detail?.insure?.is_branch)

  return (
    <DetailDrawer
      title={title}
      onCloseDrawer={onCloseDrawer}
      visible={visible}
      footer={!editMode && FooterContent}
      width="85%"
      maskClosable={true}
    >
      <LoadingView isShow={detailEvent.IsLoading} />
      <EventModal event={[event]} enable={true} successCalback={onEventSuccess} errorCalback={clearEvent} />
      <Row gutter={[16, 16]}>
        <Col md={24} xs={24}>
          {taskInfo}
        </Col>
        {!editMode && detail?.insure?.is_branch && (
          <Col md={24} xs={24}>
            {branchInfo}
          </Col>
        )}
        <Col span={24}>{editMode ? customerForm : customerInfo}</Col>
      </Row>
      {ConfirmOpenCaseDailog}
      {ConfirmAssignModal}
    </DetailDrawer>
  )
}

const Title = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.darkBlue};
`
const CheckBoxWrapper = styled.div`
  padding-bottom: 10px;
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectTaskEvent(state),
    detail: selectDetail(state),
    detailEvent: selectDetailEvent(state),
    loginData: selectLoginData(state),
    userProfile: selectUserProfile(state),
    isRefresh: selectIsRefresh(state),
    saleTaskStatus: selectSaleTaskStatusList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(customerFileTaskDetail)
