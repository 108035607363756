import React from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import Text from 'Components/text/Text'
import { MyTheme } from 'Components/common/Theme'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

interface Props {
  isShow: boolean
  handleOk?: () => void
  handleCancel?: () => void
  child: React.ReactNode
  width?: string
}

const ErrorModal = (props: Props) => {
  const { isShow = false, handleOk = () => {}, handleCancel = () => {}, width = '520px', child } = props

  const breakpoint = useBreakpoint()
  const xl = breakpoint.xl || false

  return (
    <ModalStyled
      visible={isShow}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered
      width={xl ? width : '100%'}
      maskClosable={false}
      closable={false}
    >
      <ContentWrapper>{child}</ContentWrapper>
    </ModalStyled>
  )
}

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 6px;
  }
  .ant-modal-footer {
    border: none;
  }
`

const ContentWrapper = styled.div`
  text-align: center;
`

const TextStyled = styled(Text)`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.black};
`

const FooterStyled = styled.div`
  margin-bottom: 15px;
  .ant-col {
    width: 150px;
  }
`

export default ErrorModal
