import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action } from './types'
import { initEvent, initMeta } from 'Store/common/common.reducer'
import parseErrorMessage from 'helpers/parseErrorMessage'
import { convertMetaData } from 'Store/common/common.function'

const initialState: State = {
  list: {
    data: [],
    meta: cloneDeep(initMeta),
  },
  listEvent: cloneDeep(initEvent),
  actionEvent: cloneDeep(initEvent),
  detail: undefined,
  detailEvent: cloneDeep(initEvent),
}

export default function(state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.getListAction.request):
        draft.listEvent = cloneDeep(initEvent)
        draft.listEvent.IsLoading = true
        draft.list = {
          data: [],
          meta: cloneDeep(initMeta),
        }
        return draft
      case getType(actions.getListAction.success):
        response = action.payload
        draft.listEvent.IsLoading = false
        draft.listEvent.SubmitSuccess = true
        draft.list.data = response.data
        if (response.meta) {
          draft.list.meta = convertMetaData(response.meta)
        }
        return draft
      case getType(actions.getListAction.failure):
        draft.listEvent.IsLoading = false
        draft.listEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.getDetailAction.request):
        draft.detailEvent = cloneDeep(initEvent)
        draft.detailEvent.IsLoading = true
        draft.detail = undefined
        return draft
      case getType(actions.getDetailAction.success):
        response = action.payload
        draft.detailEvent.IsLoading = false
        draft.detailEvent.SubmitSuccess = true
        draft.detail = response.data
        return draft
      case getType(actions.getDetailAction.failure):
        draft.detailEvent.IsLoading = false
        draft.detailEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.updateAgentStatusAction.request):
      case getType(actions.updateAction.request):
      case getType(actions.updateAgentImageAction.request):
        draft.actionEvent = cloneDeep(initEvent)
        draft.actionEvent.IsLoading = true
        return draft
      case getType(actions.updateAgentStatusAction.success):
      case getType(actions.updateAction.success):
      case getType(actions.updateAgentImageAction.success):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = undefined
        draft.actionEvent.SubmitSuccess = true
        draft.actionEvent.MessageSuccess = 'บันทึกข้อมูลสำเร็จ'
        return draft
      case getType(actions.updateAgentStatusAction.failure):
      case getType(actions.updateAction.failure):
      case getType(actions.updateAgentImageAction.failure):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.clearEventAction):
        draft.listEvent = cloneDeep(initEvent)
        draft.actionEvent = cloneDeep(initEvent)
        return draft

      default:
        return state
    }
  })
}
