import React from 'react'
import { Card } from 'reactstrap'
import { appConfig } from 'config'

function FileList(props) {
  const url = appConfig.assetCdnEndpoint + props.fileName
  return (
    <React.Fragment>
      <Card className="p-2 mb-2">
        <div className="d-flex align-items-center">
          <div className="avatar-sm me-3 ms-0">
            <div className="avatar-title bg-soft-primary text-primary rounded font-size-20">
              <i className="ri-file-text-fill"></i>
            </div>
          </div>
          <div className="flex-1">
            <div className="text-start">
              <h5 className="font-size-14 mb-1">{props.fileName}</h5>
              <p className="text-muted font-size-13 mb-0">{props.fileSize}</p>
            </div>
          </div>
          <div className="ms-4">
            <ul className="list-inline mb-0 font-size-20">
              <li className="list-inline-item">
                <a className="text-muted" href={url} download target="_blank" rel="noopener noreferrer">
                  <i className="ri-download-2-line"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default FileList
