import ChatForm from 'Pages/Chat/ChatForm'
import ChaiyoForm from 'Pages/Chat/ChaiyoForm'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'

//actions
import { closeUserSidebarAction } from 'Store/chat/actions'

import { selectChatActiveUser, selectChatUsers, selectUserSidebar } from 'Store/chat/selector'

function UserProfileSidebar(props) {
  const { onBookFriend, isAgent, isNotAssign, isChaiyo } = props
  // closes sidebar
  const closeuserSidebar = () => {
    props.closeUserSidebarAction()
  }

  return (
    <React.Fragment>
      <div
        style={{ display: props.userSidebar === true && isAgent && !isNotAssign ? 'block' : 'none' }}
        className="user-profile-sidebar"
      >
        <div className="user-chat-nav  text-end">
          <Button
            color="none"
            type="button"
            onClick={closeuserSidebar}
            className="nav-btn"
            id="user-profile-hide"
          >
            <i className="ri-close-line"></i>
          </Button>
        </div>
        {isChaiyo ? (
          <ChaiyoForm onBookFriend={onBookFriend} isAgent={isAgent} isNotAssign={isNotAssign} />
        ) : (
          <ChatForm onBookFriend={onBookFriend} isAgent={isAgent} isNotAssign={isNotAssign} />
        )}
        {/* <ChatForm onBookFriend={onBookFriend} isAgent={isAgent} isNotAssign={isNotAssign} /> */}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    active_user: selectChatActiveUser(state),
    userSidebar: selectUserSidebar(state),
    users: selectChatUsers(state),
  }
}

export default connect(mapStateToProps, { closeUserSidebarAction })(UserProfileSidebar)
