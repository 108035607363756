import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'Store'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'
import { MyTheme } from 'Components/common/Theme'
import { Col, message, Row, Space } from 'antd'
import { PrimaryButton } from 'Components/button'
import {
  formValidation,
  formatDateTime,
  formatDateWithoutTime,
  getAgentStatus,
  formatAgentype,
  isFormError,
} from 'helpers/format'
import { DetailReq, UpdateReq, UpdateStatusReq } from 'Store/agent/types'
import { getAgentDetailAPI, updateAgentAPI, updateAgentStatusAPI } from 'Store/agent/api'
import { selectActionEvent, selectDetail, selectDetailEvent } from 'Store/agent/selector'
import { clearEventAction } from 'Store/agent/actions'
import EventModal from 'Components/modal/EventModal'
import LoadingView from 'Components/modal/LoadingView'
import { DetailDrawer } from 'Components/common/MainLayout'
import { ConfirmDailog, ConfirmQuestion } from 'Components/modal'
import { agentDocs, AgentStatus, AgentType } from 'helpers/constanst'
import { selectLoginData } from 'Store/user/selector'
import { InfoItem } from 'Components/text'
import BoxWrapper from 'Components/common/Layout/BoxWrapper'
import { AppInput, UploadForm } from 'Components/form'
import { StatusTag } from 'Components/utilities'

type Props = ReduxProps & {
  visible: boolean
  onCloseDrawer: () => void
  onSuccess?: () => void
  mode: any
  id?: number
}

const CouponDetail: React.FunctionComponent<Props> = props => {
  const {
    onCloseDrawer,
    visible,
    event,
    onSuccess = () => {},
    id = 0,
    mode,
    detail,
    detailEvent,
    loginData,
  } = props

  const isViewMode = mode === 'approve' || mode === 'view'
  const isPending = detail?.status === AgentStatus.PENDING

  const requireFeild = ['agent_name', 'commission'] as string[]
  const initForm = {
    commission: 0,
    document_bank: '',
    document_copy_of_house: '',
    document_occupational: '',
    document_personal_id: '',
    document_personal_picture: '',
    agent_name: 'ตัวแทนสินเชื่อเงินไชโย',
  } as UpdateReq
  const [formData, setFormData] = useState(cloneDeep(initForm))
  const [formError, setFormError] = useState([] as string[])
  const [rejectDialog, setRejectDialog] = useState(false)
  const [approveDialog, setApproveDialog] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!visible) {
      setFormError([])
    }
    if (visible && id) {
      onGetDetail()
    }
  }, [visible])

  useEffect(() => {
    if (id > 0) {
      const form = {
        commission: detail?.commission,
        document_bank: detail?.document_bank,
        document_copy_of_house: detail?.document_copy_of_house,
        document_occupational: detail?.document_occupational,
        document_personal_id: detail?.document_personal_id,
        document_personal_picture: detail?.document_personal_picture,
        agent_name: detail?.agent_name || 'ตัวแทนสินเชื่อเงินไชโย',
      } as UpdateReq
      setFormData({ ...form })
    }
  }, [detail])

  useEffect(() => {
    if (formError.length > 0) {
      validateForm()
    }
  }, [formData])

  const onGetDetail = (): void => {
    const req: DetailReq = {
      id: id,
    }
    dispatch(getAgentDetailAPI(req, loginData?.access_token || ''))
  }

  const onUpdate = (): void => {
    if (!validateForm()) return
    const req: UpdateReq = {
      ...formData,
      id: id,
    }
    dispatch(updateAgentAPI(req, loginData?.access_token || ''))
  }

  const onUpdateStatus = (status: AgentStatus, reason: string): void => {
    const req: UpdateStatusReq = {
      id,
      status,
      reason,
    }
    dispatch(updateAgentStatusAPI(req, loginData?.access_token || ''))
  }

  const onReject = (reason: string): void => {
    onUpdateStatus(AgentStatus.REJECT, reason)
  }

  const onApprove = (): void => {
    onUpdateStatus(AgentStatus.APPROVE, '')
  }

  const onUpdateData = (val: any, key: string) => {
    let value = cloneDeep(val)
    if (key === 'commission') {
      if (parseFloat(val) > 100) {
        value = 100
      }
    }
    setFormData({
      ...formData,
      [key]: value,
    })
  }

  const validateForm = () => {
    const error = formValidation(requireFeild, formData)
    setFormError([...error])
    return error.length > 0 ? false : true
  }

  const clearEvent = (): void => {
    dispatch(clearEventAction())
  }

  const onEventSuccess = (): void => {
    clearEvent()
    onSuccess()
    if (rejectDialog) {
      setRejectDialog(false)
    }
    if (approveDialog) {
      setApproveDialog(false)
    }
  }

  const isError = (name: string) => {
    return isFormError(formError, name)
  }

  const editFooter = (
    <Space size="middle" align="baseline">
      <PrimaryButton text="ยกเลิก" btnType="Cancel" onClick={onCloseDrawer} />
      <PrimaryButton text="บันทึกข้อมูล" onClick={onUpdate} />
    </Space>
  )

  const approveFooter = (
    <Space size="middle" align="baseline">
      <PrimaryButton
        text="ไม่อนุมัติ"
        btnType="Reject"
        onClick={() => {
          setRejectDialog(true)
        }}
      />
      <PrimaryButton
        text="อนุมัติ"
        btnType="Approve"
        onClick={() => {
          if (detail?.agent_name === '' || (detail?.commission || 0) <= 0) {
            message.error(
              'กรุณาแก้ไขข้อมูลชื่อแสดงผลหน้าสนทนากับลูกค้าและเรทคอมมิชชั่น ให้ครบถ้วน ก่อนอนุมัติตัวแทน',
            )
            return
          }
          setApproveDialog(true)
        }}
      />
    </Space>
  )

  const renderInfo = (title: string, value: any, span: number = 15) => {
    return <InfoItem title={title} value={value} span={span} />
  }

  const renderStatus = (value: any) => {
    const result = getAgentStatus(value)
    return <StatusTag color={result.color} text={result.text} />
  }

  const renderInfoRow = (item1: any, item2: any) => {
    return (
      <Col span={24}>
        <Row justify="space-between">
          <Col md={12} xs={24}>
            {item1}
          </Col>
          <Col md={12} xs={24}>
            {item2}
          </Col>
        </Row>
      </Col>
    )
  }

  const FooterContent = isViewMode ? approveFooter : editFooter

  const ConfirmRejectDailog = (
    <ConfirmQuestion
      title="ไม่อนุมัติตัวแทน"
      placeholder="กรุณาระบุเหตุผลที่ไม่อนุมัติตัวแทน"
      confirmBtnTxt="ไม่อนุมัติ"
      isShow={rejectDialog}
      handleOk={(remark: string) => onReject(remark)}
      handleCancel={() => setRejectDialog(false)}
      loading={event.IsLoading}
    />
  )

  const ConfirmApproveDailog = (
    <ConfirmDailog
      title="ยืนยันอนุมัติตัวแทน"
      description="คุณแน่ใจที่จะอนุมัติตัวแทนนี้หรือไม่?"
      confirmBtnTxt="อนุมัติ"
      isShow={approveDialog}
      handleOk={onApprove}
      handleCancel={() => setApproveDialog(false)}
      loading={event.IsLoading}
    />
  )

  const documentPreview = (title: string, image: string = '', key: string) => (
    <Col md={8} xs={24} key={key}>
      <UploadForm
        token={''}
        onUpdateData={(file: any) => onUpdateData(file, key)}
        currentFile={image}
        folder="merchant"
        listType="picture"
        isPrivate={true}
        name="merchant-document"
        description={isViewMode ? '' : 'ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .pdf)'}
        isRequired={false}
        isError={false}
        label={title}
        readOnly={isViewMode}
      />
    </Col>
  )

  const agentForm = (
    <BoxWrapper title="แก้ไขข้อมูลตัวแทน">
      <Row justify="space-between" gutter={[16, 8]}>
        <Col md={12} xs={24}>
          <AppInput
            name="agent_name"
            label="ชื่อแสดงผลหน้าสนทนากับลูกค้า"
            isRequired={true}
            isError={isError('agent_name')}
            value={formData.agent_name}
            onChange={onUpdateData}
          />
        </Col>
        <Col md={12} xs={24}>
          <AppInput
            name="commission"
            label="เรทคอมมิชชั่น"
            isRequired={true}
            isError={isError('commission')}
            value={formData.commission}
            onChange={onUpdateData}
            resProps={{ suffix: '%' }}
          />
        </Col>
      </Row>
    </BoxWrapper>
  )

  const agentFormView = (
    <>
      {renderInfoRow(
        renderInfo('ชื่อแสดงผลหน้าสนทนากับลูกค้า', detail?.agent_name),
        renderInfo('เรทคอมมิชชั่น', `${detail?.commission || ''} %`),
      )}
    </>
  )

  const agentDocument = (
    <BoxWrapper title="เอกสารสำหรับสมัครสมาชิกตัวแทน">
      <Row justify="start" gutter={[16, 16]} align="bottom">
        {agentDocs.map((item: any) => {
          return documentPreview(item.name, formData?.[item.key], item.key)
        })}
      </Row>
    </BoxWrapper>
  )

  const agentRegisterInfo = (
    <BoxWrapper title="ข้อมูลตัวแทน">
      <Row justify="space-between" gutter={[8, 8]}>
        {renderInfoRow(renderInfo('ประเภท', formatAgentype(detail?.agent_type || '')), null)}
        {detail?.agent_type === AgentType.COPORATE &&
          renderInfoRow(
            renderInfo('เลขทะเบียนนิติบุคคล', detail?.corporate_no),
            renderInfo('ชื่อนิติบุคคล', detail?.corporate_name),
          )}
        {renderInfoRow(renderInfo('คำนำหน้าชื่อ', detail?.prefix), renderInfo('เพศ', detail?.sex))}
        {renderInfoRow(renderInfo('ชื่อ', detail?.first_name), renderInfo('นามสกุล', detail?.last_name))}
        {renderInfoRow(
          renderInfo('วันเดือนปีเกิด', formatDateWithoutTime(detail?.birthday || '')),
          renderInfo('เบอร์โทรศัพท์', detail?.mobile),
        )}
        {renderInfoRow(renderInfo('อีเมล', detail?.email), renderInfo('ที่อยู่', detail?.address))}
        {isViewMode && agentFormView}
        {renderInfoRow(
          renderInfo('สถานะ', renderStatus(detail?.status || '')),
          detail?.status === AgentStatus.REJECT ? renderInfo('เหตุผลที่ไม่อนุมัติ', detail?.reason) : '',
        )}
      </Row>
    </BoxWrapper>
  )

  const title = (
    <Space align="start">
      <div>
        <Title>
          ชื่อตัวแทน : {detail?.first_name} {detail?.last_name}
        </Title>
        <CreatedDate>วันที่สมัคร {formatDateTime(detail?.created_at || '')}</CreatedDate>
      </div>
      {renderStatus(detail?.status || '')}
    </Space>
  )

  return (
    <DetailDrawer
      title={title}
      onCloseDrawer={onCloseDrawer}
      visible={visible}
      footer={isPending && FooterContent}
      width="85%"
      maskClosable={true}
    >
      <LoadingView isShow={detailEvent.IsLoading} />
      <EventModal event={[event]} enable={true} successCalback={onEventSuccess} errorCalback={clearEvent} />
      <Row gutter={[16, 16]}>
        <Col md={24} xs={24}>
          {agentRegisterInfo}
        </Col>
        {!isViewMode && (
          <Col md={24} xs={24}>
            {agentForm}
          </Col>
        )}
        <Col md={24} xs={24}>
          {agentDocument}
        </Col>
      </Row>
      {ConfirmRejectDailog}
      {ConfirmApproveDailog}
    </DetailDrawer>
  )
}

const Title = styled.div`
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.darkBlue};
`

const CreatedDate = styled.div`
  font-size: ${(props: { theme: MyTheme }): string => props.theme.typography.body4};
  color: ${(props: { theme: MyTheme }): string => props.theme.colors.grey};
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectActionEvent(state),
    detail: selectDetail(state),
    detailEvent: selectDetailEvent(state),
    loginData: selectLoginData(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(CouponDetail)
