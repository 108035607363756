import { CreateMapping } from 'Store/common/common.types'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import {
  ListReq,
  ListRes,
  DetailReq,
  DetailRes,
  ActionRes,
  UpdateReq,
  UpdateStatusReq,
  UpdateAgentImageReq,
} from './types'

export const getListAction = createAsyncAction(
  'AGENT/GET_AGENT_LIST_REQ',
  'AGENT/GET_AGENT_LIST_SUCCESS',
  'AGENT/GET_AGENT_LIST_FAILURE',
)<ListReq, ListRes, Error>()

export const getDetailAction = createAsyncAction(
  'AGENT/GET_AGENT_DETAIL_REQ',
  'AGENT/GET_AGENT_DETAIL_SUCCESS',
  'AGENT/GET_AGENT_DETAIL_FAILURE',
)<DetailReq, DetailRes, Error>()

export const updateAction = createAsyncAction(
  'AGENT/UPDATE_AGENT_REQ',
  'AGENT/UPDATE_AGENT_SUCCESS',
  'AGENT/UPDATE_AGENT_FAILURE',
)<UpdateReq, ActionRes, Error>()

export const updateAgentStatusAction = createAsyncAction(
  'AGENT/UPDATE_AGENT_STATUS_REQ',
  'AGENT/UPDATE_AGENT_STATUS_SUCCESS',
  'AGENT/UPDATE_AGENT_STATUS_FAILURE',
)<UpdateStatusReq, ActionRes, Error>()

export const updateAgentImageAction = createAsyncAction(
  'AGENT/UPDATE_AGENT_IMAGE_REQ',
  'AGENT/UPDATE_AGENT_IMAGE_SUCCESS',
  'AGENT/UPDATE_AGENT_IMAGE_FAILURE',
)<UpdateAgentImageReq, ActionRes, Error>()

export const clearEventAction = createStandardAction('AGENT/CLEAR_EVENT')<void>()
