import produce from 'immer'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { State, Action } from './types'
import { initEvent, initMeta } from 'Store/common/common.reducer'
import parseErrorMessage from 'helpers/parseErrorMessage'
import { convertMetaData } from 'Store/common/common.function'

const initialState: State = {
  list: {
    data: [],
    meta: cloneDeep(initMeta),
  },
  listEvent: cloneDeep(initEvent),
  actionEvent: cloneDeep(initEvent),
  logs: [],
  logsEvent: cloneDeep(initEvent),
}

export default function(state: State = initialState, action: Action) {
  return produce(state, draft => {
    let response
    switch (action.type) {
      case getType(actions.getListAction.request):
        draft.listEvent = cloneDeep(initEvent)
        draft.listEvent.IsLoading = true
        draft.list.data = []
        return draft
      case getType(actions.getListAction.success):
        response = action.payload
        draft.listEvent.IsLoading = false
        draft.listEvent.SubmitSuccess = true
        draft.list.data = response.data
        if (response.meta) {
          draft.list.meta = convertMetaData(response.meta)
        }
        return draft
      case getType(actions.getListAction.failure):
        draft.listEvent.IsLoading = false
        draft.listEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.updateAction.request):
        draft.actionEvent = cloneDeep(initEvent)
        draft.actionEvent.IsLoading = true
        return draft
      case getType(actions.updateAction.success):
        response = action.payload
        if (response && response.status === 200) {
          draft.actionEvent.IsLoading = false
          draft.actionEvent.SubmitError = undefined
          draft.actionEvent.SubmitSuccess = true
          draft.actionEvent.MessageSuccess = 'ทำรายการสำเร็จ'
        } else {
          draft.actionEvent.IsLoading = false
          draft.actionEvent.SubmitError = response.message
        }
        return draft
      case getType(actions.updateAction.failure):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = 'เกิดข้อผิดพลาดไม่สามารถทำรายการได้ กรุณาลองใหม่อีกครั้ง'
        return draft
      case getType(actions.getLogsAction.request):
        draft.logsEvent = cloneDeep(initEvent)
        draft.logsEvent.IsLoading = true
        draft.logs = []
        return draft
      case getType(actions.getLogsAction.success):
        response = action.payload
        draft.logsEvent.IsLoading = false
        draft.logsEvent.SubmitSuccess = true
        draft.logs = response.data
        return draft
      case getType(actions.getLogsAction.failure):
        draft.logsEvent.IsLoading = false
        draft.logsEvent.SubmitError = parseErrorMessage(action.payload)
        return draft
      case getType(actions.clearEventAction):
        draft.listEvent = cloneDeep(initEvent)
        draft.actionEvent = cloneDeep(initEvent)
        draft.logsEvent = cloneDeep(initEvent)
        return draft
      default:
        return state
    }
  })
}
