import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import {
  LoginReq,
  LoginRes,
  MerchantData,
  StaffPermissionReq,
  StaffPermissionRes,
  UserProfileReq,
  UserProfileRes,
  UserProfileByUUIDReq,
} from './types'
//import { MerchantData } from 'Store/merchant/types'

export const loginAction = createAsyncAction('USER/LOGIN_REQ', 'USER/LOGIN_SUCCESS', 'USER/LOGIN_FAILURE')<
  LoginReq,
  LoginRes,
  Error
>()

export const getUserProfileAction = createAsyncAction(
  'USER/PROFILE_REQ',
  'USER/PROFILE_SUCCESS',
  'USER/PROFILE_FAILURE',
)<UserProfileReq, UserProfileRes, Error>()

export const getStaffPermissionAction = createAsyncAction(
  'USER/STAFF_PERMISSION_REQ',
  'USER/STAFF_PERMISSION_SUCCESS',
  'USER/STAFF_PERMISSION_FAILURE',
)<StaffPermissionReq, StaffPermissionRes, Error>()

export const clearEventAction = createStandardAction('USER/CLEAR_EVENT')<void>()

export const logoutAction = createStandardAction('USER/LOGOUT')<void>()

export const updateLoginDataAction = createStandardAction('USER/UPDATE_LOGIN_DATA')<{
  data: any
}>()

export const updateUserProfileAction = createStandardAction('USER/UPDATE_PROFILE_DATA')<{
  data: any
}>()

export const updateActiveMerchantAction = createStandardAction('USER/UPDATE_ACTIVE_MERCHANT')<{
  merchant: MerchantData
}>()

export const updateActiveMerchantLogoAction = createStandardAction('USER/UPDATE_ACTIVE_MERCHANT_LOGO')<{
  logo: string
}>()

export const getAgentProfileAction = createAsyncAction(
  'USER/AGENT_PROFILE_REQ',
  'USER/AGENT_PROFILE_SUCCESS',
  'USER/AGENT_PROFILE_FAILURE',
)<UserProfileByUUIDReq, UserProfileRes, Error>()
