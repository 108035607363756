import colors from './colors'
import fontFamily from './fontFamily'
import typography from './typography'

const theme = {
  colors,
  fontFamily,
  typography,
  button: {
    borderRadius: '25px',
  },
}

export type MyTheme = typeof theme

export default theme
