import React, { useState, useEffect, useRef } from 'react'
import { Upload, Button, Modal, message } from 'antd'
import { CheckCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { PlusOutlined } from '@ant-design/icons'
import { appConfig } from 'config'
import ImgCrop from 'antd-img-crop'
import { uploadAPI } from 'Store/upload/api'
import { connect, useDispatch } from 'react-redux'
import { selectUploadEvent, selectUploadFile } from 'Store/upload/selector'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import 'antd/es/slider/style'
import { Config } from 'helpers/constanst'
import { cloneDeep } from 'lodash'
import { numberWithComma } from 'helpers/format'

type Props = {
  files: string[]
  onUpdateFile: (file: string[]) => void
  token: string
  folder?: string
  acceptType?: any
  listType?: 'picture' | 'text' | 'picture-card'
  isPrivate?: boolean
  withCropImage?: boolean
  aspect?: number
  readOnly?: boolean
  showSelect?: boolean
  onSelectFile?: (file: any) => void
  minFileSize?: number //KB
  maxFileSize?: number //KB
} & ReduxProps

const MultiUploader = (props: Props) => {
  const {
    files,
    onUpdateFile,
    onSelectFile = () => {},
    token,
    folder = 'images',
    listType = 'picture',
    acceptType = 'image/png,image/jpeg ,application/pdf',
    isPrivate = false,
    uploadFile,
    event,
    aspect = 1, // for crop image w/h
    withCropImage = false,
    readOnly = false,
    showSelect = false,
    minFileSize = 0,
    maxFileSize = 0,
  } = props

  const refFile = useRef() as any
  const [file, setFile] = useState([] as any[])
  const [quality, setQuality] = useState(1)
  const dispatch = useDispatch()
  const [preview, setImagePreview] = useState({
    previewVisible: false,
    previewFile: '',
    previewTitle: '',
    previewType: '',
  })

  useEffect(() => {
    if (files) {
      const mappingFile = [] as any[]
      files.forEach(currentFile => {
        const ext = currentFile.substr(currentFile.lastIndexOf('.') + 1)
        mappingFile.push({
          uid: currentFile,
          name: currentFile.substring(currentFile.lastIndexOf('/') + 1),
          status: 'done',
          url: appConfig.assetCdnEndpoint + currentFile,
          type: ext === 'pdf' ? 'application/pdf' : `image/${ext}`,
        })
      })
      setFile(mappingFile)
    } else {
      setFile([])
    }
  }, [files])

  useEffect(() => {
    if (!uploadFile || event.IsLoading || !refFile) return
    if (event.SubmitSuccess) {
      refFile?.current?.onSuccess(uploadFile)
    }

    if (event.SubmitError) {
      refFile?.current?.onError(event.SubmitError)
    }
  }, [event, uploadFile])

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  const onPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setImagePreview({
      previewFile: file.url || file.preview,
      previewTitle: file.name,
      previewVisible: true,
      previewType: file.type,
    })
  }

  const onClosePreview = () => {
    setImagePreview({
      previewFile: '',
      previewTitle: '',
      previewVisible: false,
      previewType: '',
    })
  }

  const onAddFile = (file: any) => {
    const newFile = cloneDeep(files) as any[]
    newFile.push(file.response)
    onUpdateFile(newFile)
  }

  const onRemoveFile = (file: any) => {
    let newFile = cloneDeep(files) as any[]
    newFile = newFile.filter(item => item !== file.uid)
    onUpdateFile(newFile)
  }

  const onChange = (data: any) => {
    if (!data.file.status) return
    const newFile = [] as any
    if (data.file.status !== 'removed') {
      newFile.push({
        ...data.file,
      })
    }
    setFile([...newFile])

    if (data.file.status === 'done') {
      onAddFile(data.file)
    }

    if (data.file.status === 'removed') {
      onRemoveFile(data.file)
    }
  }

  const uploadImage = async (options: any) => {
    const { file } = options
    refFile.current = options

    const fmData = new FormData()
    fmData.append(`file`, file, file.name)

    dispatch(uploadAPI(fmData))
  }

  const validateMinFileSize = (sizeInBytes: number) => {
    if (sizeInBytes < minFileSize * 1000) {
      return false
    } else {
      return true
    }
  }

  const validateMaxFileSize = (sizeInBytes: number) => {
    if (sizeInBytes > maxFileSize * 1000) {
      return false
    } else {
      return true
    }
  }

  const onBeforeCrop = (args: File) => {
    // get the file size in bytes
    const sizeInBytes: number = args.size

    // reduce file quality
    if (sizeInBytes > Config.FIVEMB_UPLOAD_FILE_SIZE_BYTES) {
      // greather than 5MB -> reduce quality 50%
      setQuality(0.5)
    } else if (sizeInBytes > Config.ONEMB_UPLOAD_FILE_SIZE_BYTES) {
      // greather than 1MB -> reduce quality 30%
      setQuality(0.7)
    } else if (sizeInBytes > Config.FIVEKB_UPLOAD_FILE_SIZE_BYTES) {
      // greather than 500KB -> reduce quality 15%
      setQuality(0.85)
    } else {
      // not reduce quality
      setQuality(1)
    }

    if (minFileSize > 0) {
      if (!validateMinFileSize(sizeInBytes)) {
        message.error({
          content: `ไม่สามารถอัพโหลดได้ กรุณาอัพโหลดรูปภาพที่มีขนาดมากกว่า ${minFileSize}KB`,
          key: 'error',
        })
        return false
      }
    }

    if (maxFileSize > 0) {
      if (!validateMaxFileSize(sizeInBytes)) {
        message.error({
          content: `ไม่สามารถอัพโหลดได้ เนื่องจากรูปภาพมีขนาดไฟล์ใหญ่เกินไป กรุณาอัพโหลดรูปภาพที่มีขนาดน้อยกว่า ${numberWithComma(
            maxFileSize,
          )}KB`,
          key: 'error',
        })
        return false
      }
    }

    return true
  }

  const uploadCard = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8, fontSize: '12px' }}>คลิกหรือลากไฟล์ เพื่ออัพโหลด</div>
    </div>
  )

  const uploadBtn = (
    <Button icon={<UploadOutlined />} disabled={readOnly}>
      คลิกเพื่ออัพโหลด
    </Button>
  )

  const UploaderForm = (
    <StyledUpload
      accept={acceptType}
      multiple={false}
      customRequest={uploadImage}
      listType={listType}
      fileList={file}
      onChange={onChange}
      onPreview={onPreview}
      disabled={readOnly}
      showUploadList={{
        showRemoveIcon: !showSelect,
        showDownloadIcon: showSelect,
        downloadIcon: <CheckCircleOutlined style={{ color: '#fff' }} />,
      }}
      onDownload={onSelectFile}
    >
      {!readOnly && (listType === 'picture-card' ? uploadCard : uploadBtn)}
    </StyledUpload>
  )

  const CropImage = (
    <ImgCrop
      rotate
      aspect={aspect}
      quality={quality}
      modalTitle="แก้ไขรูปภาพ"
      modalOk="ตกลง"
      modalCancel="ยกเลิก"
      beforeCrop={onBeforeCrop}
      fillColor="transparent"
    >
      {UploaderForm}
    </ImgCrop>
  )

  return (
    <>
      {withCropImage ? CropImage : UploaderForm}
      <PreviewModal
        className="image-modal"
        visible={preview.previewVisible || false}
        title={null}
        footer={null}
        onCancel={onClosePreview}
        centered
        closeIcon={' '}
      >
        {preview.previewType === 'application/pdf' ? (
          <PdfViewer id="iframepdf" src={preview.previewFile}></PdfViewer>
        ) : (
          <ImageViewer alt={preview.previewTitle} src={preview.previewFile} />
        )}
      </PreviewModal>
    </>
  )
}

const PreviewModal = styled(Modal)`
  width: auto !important;
  max-width: 100% !important;
  .ant-modal-content {
    background: transparent;
    box-shadow: none;
    width: max-content;
    max-width: 100%;
  }
  .ant-modal-body {
    padding: 0;
  }
`

const StyledUpload = styled(Upload)`
  margin-top: 5px;
`

const PdfViewer = styled.iframe`
  width: 800px;
  max-width: 100%;
  height: 500px;
`

const ImageViewer = styled.img`
  max-width: 800px;
  width: 100%;
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectUploadEvent(state),
    uploadFile: selectUploadFile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(MultiUploader)
